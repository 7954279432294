import React, { Component } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import MenuOS from "../../../components/MenuOS";
import AtendimFilters from "./componentes/AtendimFilters";

class Relatorios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Relatório de Produtividade",
      nomeNivelCrumb: "Página Inicial",
      nomeNivelCrumb2: " / Painel",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <MenuOS />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item active">
                            <a href="/atendimento">
                              {this.state.nomeNivelCrumb}
                            </a>
                            <a href="/Painel">
                              {this.state.nomeNivelCrumb2}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div className="card p-2" style={{ background: "white" }}>
                      {/* <div className="card-body" style={{ textAlign: "right" }}>
                        <a
                          style={{
                            borderRadius: "5px",
                            cursor: "pointer",
                            color: "blue",
                            fontSize: 15,
                          }}
                          onClick={this.handleNewItem}
                        >
                          <i className="fa fa-file" /> Novo Registro
                        </a>
                      </div> */}

                      {/* <ProjetoGrid /> */}
                      <AtendimFilters />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Relatorios;