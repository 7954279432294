import { Tab, Tabs } from "@mui/material";
import { AvatarProjetos } from "../../../components/AvatarProjetos";


export const ChartOptionsTab = ({ value, onChange, tasks }) => {
  return (
    <>
    <Tabs
      value={value}
      onChange={(e, value) => onChange(value)}
      variant="fullWidth"
      sx={{ mb: 2 }}
    >
      <Tab label="Kanban" value="kanban" />
      <Tab label="Burndown" value="burndown" />
      <Tab label="Gantt" value="gantt" />
    </Tabs>
    
    </>
  );
};
