import { useState } from "react";
import { Checkbox, FormControl, InputLabel, IconButton, ListItemText, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from "@mui/material";

export const FilterStatus = ({selectedStatus, setSelectedStatus}) => {
 
  const [open, setOpen] = useState(false);
  const status = [
    { nome: "PENDENTE" },
    { nome: "ANDAMENTO" },
    { nome: "TESTE" },
    { nome: "CONCLUIDO" },
  ];
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedStatus(value);
  };

  const handleToggle = (nome) => {
    setSelectedStatus((prevSelectedStatus) => {
      if (prevSelectedStatus.includes(nome)) {
        return prevSelectedStatus.filter((status) => status !== nome);
      } else {
        return [...prevSelectedStatus, nome];
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: isSmUp ? 14 : 10,
          color: "#a2b8b8",
        }}
      >
        Filtrar Status
      </InputLabel>
      <Select
        id="demo-simple-select"
        label="Filtrar Status"
        placeholder="Filtrar Status"
        sx={{ background: "none" }}
        multiple
        value={selectedStatus}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        renderValue={(selected) => selected.join(", ")}
      >

        <MenuItem
          onClick={handleClose} 
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "sticky",
            top: 0,
            textOverflow: "ellipsis",
            backgroundColor: "#FFFFFF",
            textOverflow: "ellipsis",
            zIndex: 1,
            '&:hover': {
              background: '#ffffff',
            },
          }}
        >
          {/* <ListItemText primary="Fechar"/>
          <IconButton size="small">
            <CloseIcon fontSize="small" />
          </IconButton> */}
        </MenuItem>
        {status.map((item) => (
          <MenuItem key={item.nome} value={item.nome}>
            <Checkbox
              checked={selectedStatus.includes(item.nome)}
              onClick={() => handleToggle(item.nome)}
            />
            <ListItemText primary={item.nome} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
