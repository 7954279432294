import React, { useState, useEffect } from "react";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, TextField,
  Chip, Autocomplete,
  InputLabel,
  Typography} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useUserFilters } from "../../../contexts/FiltersSearchContext";

export const FilterSolicitante = ({ solicitante, setComparVar, handleCheckboxChange }) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));


  const {selectPersonName, setSelectPersonName, checked, setChecked} = useUserFilters()

  const mapSolic = solicitante
    ? solicitante.slice(5).map((item) => ({
        value: item.codUsuario,
        label: item.nome,
      }))
    : [];


  useEffect(() => {
    setComparVar([
      ...new Set([...selectPersonName.map((item) => item.value), ...checked]),
    ]);
  }, [selectPersonName, checked]);

  return (
    <Box>
      {/* <Typography variant="subtitle2" color="#585757" paddingLeft={ 2 } paddingTop={ 1 }>
        Por Solicitante
      </Typography> */}

      <FormGroup sx={{ gap: 0.5 }}>
      <Typography variant="subtitle2" color="#585757" paddingLeft={ 1 } paddingTop={ 1 }>
        Por Solicitante
      </Typography>

        {solicitante &&
          solicitante
            .slice(0, 3)
            .map((solic) => (
              <FormControlLabel
                key={solic.id}
                color="#585757"
                control={
                  <Checkbox
                    sx={{ paddingLeft: 1, paddingBottom: "0px", paddingTop: "0px" }}
                    value={solic.codUsuario}
                    checked={checked && checked.includes(solic.codUsuario)}
                    onChange={handleCheckboxChange}
                  />
                }
                sx={{'.MuiFormControlLabel-label': { fontSize: isSmUp ? 14 : 10,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: !isSmUp ? "35%" : "65%", 
                  minHeight: 20, 
                  lineHeight: "16px",
                 }, margin: 0}}

                label={solic.nome}
              />
            ))}
      </FormGroup>

      <FormControl
      
        fullWidth
        variant="outlined"
        sx={{ mt: 0, fontSize: 10 }}
        size="small"
      >
        <InputLabel
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: isSmUp ? 14 : 10,
          }}
        >
          
        </InputLabel>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={mapSolic}
          getOptionLabel={(option) => option.label}
          value={selectPersonName}
          onChange={(event, newValue) => {
            setSelectPersonName(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField 
              {...params}
              variant="outlined"
              label="Buscar Solicitante"
              placeholder="Digite para filtrar..."
              size="small"
              sx={{
                marginTop: 1,
                "& .MuiInputLabel-root": {
                  color: "#a2b8b8", fontSize: isSmUp ? 14 : 10,
                },
              }}
            />
          )}
          sx={{ 
            background: "none", 
            '.MuiAutocomplete-option': { fontSize: 10 },
            fontSize: 10 
          }}
        />
      </FormControl>
    </Box>
  );
};