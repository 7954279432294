const ganttBarColor = {
  PENDENTE: "#ff9914",
  ANDAMENTO: "#38b000",
  CONCLUIDO: "#3a0ca3",
  TESTE: "#87CEEB",
};

export const queryTaskbarInfo = (args) => {
  const color = ganttBarColor[args.data.taskData.status];

  if (color) {
    args.progressBarBgColor = color;
    args.milestoneColor = color;
  }
};
