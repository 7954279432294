import {
  Dialog,
  Modal,
  Box,
  TextField,
  Alert,
  AlertTitle,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import api from "../../../services/api";
import dayjs from "dayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getControle } from "../../../services/auth";
import { AlertDanger, AlertSuccess } from "./styles";
import Dropzone from "react-dropzone";

import "./dropZone.css";
import {
  addZeroes,
  formatarDataBaseDados,
  formatarDataBr,
} from "../../../utils/utils";
import UsuarioSolicitacoes from "./UsuarioSolicitacoes";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

import "../../../components/controles/css/file.css";

import csv from "../../../assets/icones/csv.png";
import xls from "../../../assets/icones/excel.png";
import pdf from "../../../assets/icones/pdf.png";
import word from "../../../assets/icones/word.png";
import zip from "../../../assets/icones/zip.png";
import pptx from "../../../assets/icones/pptx.png";
import rar from "../../../assets/icones/rar.png";

export const SolicitacoesEditar = () => {
  let history = useHistory();
  let msgError = [];
  const { id } = useParams();
  const usuarioControle = getControle();
  const [usuario, setUsuario] = useState([]);
  const [status, setStatus] = useState("0");
  const [nome, setNome] = useState("");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [value, setValue] = useState(null);

  const [listUsuario, setListUsuario] = useState([]);
  const [selectedUsuarioArray, setSelectedUsuarioArray] = useState([]);
  const [solUsuario, setSolUsuario] = useState([]);

  const [listEvento, setListEvento] = useState([]);
  const [selectedEvento, setSelectedEvento] = useState([]);
  const [selectedEventoString, setSelectedEventoString] = useState([]);

  const [openMsg, setOpenMsg] = useState(false);
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [content, setContent] = useState("");
  const [ordemCompra, setOrdemCompra] = useState([]);
  const [chaveNfe, setChaveNfe] = useState([]);
  const [solDescricao, setSolDescricao] = useState([]);

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [files, setFiles] = useState([]);
  const [arquivosDemanda, setArquivosDemanda] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    validateFields();
  }, [
    nome,
    content,
    status,
    ordemCompra,
    chaveNfe,
    solUsuario,
    selectedEvento,
    value,
  ]);

  const validateFields = () => {
    const requiredString = { nome, content, status };
    const requiredArray = { ordemCompra, chaveNfe, solUsuario, selectedEvento };

    const isStringFieldsEmpty = Object.values(requiredString).some(
      (value) => value === ""
    );
    const isArrayFieldsEmpty = Object.values(requiredArray).some(
      (array) => array === null || array.length === 0
    );

    setIsButtonDisabled(
      isStringFieldsEmpty || isArrayFieldsEmpty || value === null
    );
  };

  const handleContentChange = (newContent) => {
    if (newContent === "<p><br></p>") {
      newContent = "";
    }
    setContent(newContent);
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleOrdemCompraChange = (e) => {
    setOrdemCompra(e.target.value);
  };

  const handleChaveNfeChange = (e) => {
    setChaveNfe(e.target.value);
  };

  const getLista = async () => {
    await api
      .get(`/anexo_demanda/demanda/` + id + `/anexos`)
      .then((response) => {
        setArquivosDemanda(response.data.records);
      });
  };

  const handleDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const handleUpload = async (idReg) => {
    for (const [index, file] of files.entries()) {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("idDemanda", idReg);

      try {
        await api.post(`/anexo_demanda/enviar-arquivo`, formData, {
          method: "POST",
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
          timeout: 10000,
        });
        console.log(`File at index ${index} uploaded successfully`);
      } catch (error) {
        console.error(`Failed to upload file at index ${index}:`, error);
      }
    }
  };

  const themeSelect = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#9fb4f7",
      primary: "blue",
    },
  });

  const editSolicitacoes = async () => {
    const usuarioArray = [];
    solUsuario.map(function (ms) {
      usuarioArray.push(`${parseInt(ms.value)}`);
    });

    usuarioArray.push(getControle());

    let usuarioLocal = getControle();

    try {
      if (id !== "0") {
        var dadosJson = {
          titulo: nome,
          descricao: content,
          ordemCompra: parseInt(ordemCompra),
          chaveNfe: chaveNfe,
          solicitante: parseInt(usuarioLocal),
          status: status,
          eventos: parseInt(selectedEvento),
          stringEvento: selectedEventoString,
          dataFimPrazo: dayjs(value).endOf("day").format("DD/MM/YYYY HH:mm:ss"), //Data Lim
        };

        await api.put(`/demanda/` + id, dadosJson).then(async (res) => {
          if (res.status == 200) {
            await handleUpload(res.data.records[0].id);
            setOpen(false);
          } else {
            setOpen(false);
          }
        });

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });

        const redirecionar = setTimeout(() => {
          history.push({
            pathname: "/Processos",
          });
        }, 1000);

        return () => clearTimeout(redirecionar);
      } else {
        var dadosJson = {
          titulo: nome,
          usuarios: usuarioArray,
          descricao: content,
          ordemCompra: parseInt(ordemCompra),
          chaveNfe: chaveNfe,
          solicitante: parseInt(usuarioLocal),
          status: status,
          eventos: selectedEvento.idEvento,
          stringEvento: selectedEventoString, // Descrição/sigla
          dataFimPrazo: formatarDataBaseDados(value), //Data Limite
          dataAbertura: formatarDataBaseDados(Date.now()),
        };

        await api.post(`/demanda/`, dadosJson).then(async (res) => {
          await handleUpload(res.data.records[0].id);
          setOpen(false);
        });

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });

        const redirecionar = setTimeout(() => {
          history.push({
            pathname: "/Processos",
          });
        }, 1000);

        return () => clearTimeout(redirecionar);
      }
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Há campos não preenchidos.",
      });
    }
  };

  useEffect(() => {
    setEditorLoaded(true);
    const getUsuario = async () => {
      await api
        .get(`/usuarioDetalhe?codUsuario=${usuarioControle}`)
        .then((response) => {
          setListUsuario(response.data.records);
          setUsuario(response.data.records);
        });
    };
    const getEvento = async () => {
      await api.get(`/demanda_eventos/`).then((response) => {
        const arr = [];
        response.data.records[0].eventos.map(function (ev) {
          arr.push({
            id: `${parseInt(addZeroes(ev.id, 2) + "0000")}`,
            idEvento: ev.id,
            rotina: (
              <span className="ml-0">
                {" "}
                <i className="fas fa-caret-down" />{" "}
                {ev.id + "." + "00" + "." + "00" + " " + ev.descricao}{" "}
              </span>
            ),
          });
          ev.categorias.map(function (cat) {
            arr.push({
              id: `${parseInt(
                addZeroes(ev.id, 2) + addZeroes(cat.id, 2) + "00"
              )}`,
              idEvento: ev.id,
              idCategoria: cat.id,
              rotina: (
                <span className="ml-3">
                  {" "}
                  <i className="fas fa-caret-down" />{" "}
                  {addZeroes(ev.id, 2) +
                    "." +
                    addZeroes(cat.id, 2) +
                    "." +
                    "00" +
                    " " +
                    cat.descricao}{" "}
                </span>
              ),
            });

            cat.subCategorias.map(function (subCat) {
              arr.push({
                id: `${parseInt(
                  addZeroes(ev.id, 2) +
                    addZeroes(cat.id, 2) +
                    addZeroes(subCat.id, 2)
                )}`,
                idEvento: ev.id,
                idCategoria: cat.id,
                idSubcategoria: subCat.id,
                rotina: (
                  <span className="ml-5">
                    {addZeroes(ev.id, 2) +
                      "." +
                      addZeroes(cat.id, 2) +
                      "." +
                      addZeroes(subCat.id, 2) +
                      " " +
                      subCat.descricao}{" "}
                  </span>
                ),
              });
            });
          });

          setListEvento(arr);
        });
      });
    };

    const formatarDataBaseDados = (value) => {
      if (dayjs(value).isValid()) {
        return dayjs(value).toISOString();
      } else {
        console.error("Data inválida fornecida para formatarDataBaseDados");
        return null;
      }
    };

    const getSolicitacao = async () => {
      if (id !== "0") {
        await api.get(`/demanda/` + id).then((response) => {
          const arrUsuario = [];

          response.data.records[0].usuarios.map(function (ms) {
            arrUsuario.push({
              value: `${parseInt(ms.usuario.codUsuario)}`,
              label: `${ms.usuario.descricao}`,
            });
          });

          response.data.records[0].descricao;

          setNome(response.data.records[0].titulo);
          setValue(
            dayjs(response.data.records[0].dataFimPrazo.slice(0, 10)).isValid()
              ? dayjs(response.data.records[0].dataFimPrazo).toISOString()
              : null
          );
          setContent(response.data.records[0].descricao);
          setOrdemCompra(response.data.records[0].ordemCompra);
          setChaveNfe(response.data.records[0].chaveNfe);

          setSolDescricao(response.data.records[0].descricao);
          setSelectedEvento(response.data.records[0].eventos.id);
          setSelectedEventoString(response.data.records[0].stringEvento);

          setSolUsuario(arrUsuario);
        });
      }
    };
    getSolicitacao();
    getUsuario();
    getEvento();
    getLista();
  }, [id]);

  const optionsEvento = listEvento.map(function (respons) {
    return {
      value: respons.id,
      label: respons.rotina,
      idEvento: respons.idEvento,
      idCategoria: respons.idCategoria,
      idSubcategoria: respons.idSubcategoria,
    };
  });

  const handleChangeEvento = (selectedOptionEvento) => {
    setSelectedEvento(selectedOptionEvento);
    setSelectedEventoString(selectedOptionEvento.value);
  };

  const optionsUsuario = listUsuario.map(function (usuvinc) {
    return { value: usuvinc.codUsuario, label: usuvinc.descricao };
  });

  const handleChangeUsuario = (selectedOptionUsuario) => {
    setSelectedUsuarioArray([]);

    const arr = [];
    selectedOptionUsuario.map(function (usu) {
      arr.push({ value: `${parseInt(usu.value)}`, label: `${usu.label}` });
      setSelectedUsuarioArray(arr);
    });
    setSolUsuario(arr);
  };

  const handleFecharModal = async () => {
    setIsLoading(true);
    if (msgError.length == 0) {
      await editSolicitacoes();
    }

    setIsLoading(false);
  };

  const handleCancelarModal = () => {
    setOpen(false);
  };

  const handleSave = () => {
    localStorage.setItem("document", content);
  };

  var colunas;
  if (id == 0) {
    colunas = 12;
  } else {
    colunas = 9;
  }

  const handleClick = (event, id) => {
    if (id != 0) {
      history.push({
        pathname: "/processoItem/" + id,
      });
    } else {
      history.push({
        pathname: "/Processos",
      });
    }
  };

  const excluirFoto = async (img) => {
    try {
      var dadosJson = {
        id: img.id,
        type: img.type,
        caminho: img.caminho,
        posicao: img.posicao,
        nome: img.nome,
        status: img.status,
        mensagem: img.mensagem,
      };

      api.post(`/anexo_demanda/apagar-arquivo`, dadosJson);
    } catch (err) {
      console.log(err);
    }

    // });
  };

  const altData = async (event) => {
    const dataObj = dayjs(event).endOf("day").format("YYYY-MM-DD HH:mm:ss");

    console.log(dataObj);
    setValue(dataObj);
  };

  return (
    <>
      <div className="row">
        <div className={`col-md-${colunas}`}>
          <div className="card">
            <div className="card-body">
              {statusRecord.type === "erro" ? (
                <AlertDanger>{statusRecord.mensagem}</AlertDanger>
              ) : (
                ""
              )}
              {statusRecord.type === "success" ? (
                <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
              ) : (
                ""
              )}
              <form className="form-horizontal">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Classificação</label>

                    <Select
                      value={optionsEvento.filter(
                        (obj) => obj.value === selectedEventoString
                      )}
                      onChange={handleChangeEvento}
                      options={optionsEvento}
                      clearable="true"
                      searchable="true"
                      labelKey="rotina"
                      valueKey="id"
                      noOptionsMessage={() => "Nenhum registro Encontrado"}
                      required
                      theme={themeSelect}
                      classNamePrefix="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-9">
                    <div className="form-group was-validated">
                      <label
                        className="col-sm-9 col-form-label"
                        htmlFor="Inputnome"
                      >
                        Título da Solicitação
                      </label>
                      <input
                        type="nome"
                        className="form-control"
                        id="InputNomeTituloSolicitacao"
                        placeholder="Digite aqui..."
                        value={nome}
                        onChange={handleNomeChange}
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3">
                    <div className="form-group was-validated">
                      <label className="col-sm-9 col-form-label">
                        Data Limite
                      </label>
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale="pt-br"
                      >
                        <div
                          className="input-group date"
                          data-provide="datepicker"
                        >
                          <DatePicker
                            className="form-control"
                            value={value ? value.slice(0, 10) : null}
                            onChange={altData}
                            renderInput={(params) => (
                              <TextField
                                label="Size"
                                id="outlined-size-small"
                                defaultValue="Small"
                                required
                                onKeyDown={(e) => e.preventDefault()}
                                size="small"
                                {...params}
                              />
                            )}
                            dayOfWeekFormatter={(day, date) => {
                              const daysOfWeek = [
                                "Dom",
                                "Seg",
                                "Ter",
                                "Qua",
                                "Qui",
                                "Sex",
                                "Sab",
                              ];
                              return daysOfWeek[day];
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>

                {(() => {
                  if (id == 0) {
                    return (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group was-validated">
                            <label className="col-sm-12 col-form-label">
                              Vincular Usuário
                            </label>

                            <Select
                              className="selectpicker"
                              value={solUsuario}
                              isMulti
                              name="_solUsuario"
                              onChange={handleChangeUsuario}
                              options={optionsUsuario}
                              classNamePrefix="select"
                              required="required"
                            />
                            {solUsuario.length === 0 && (
                              <Typography variant="" color="red">
                                Este campo é obrigatório
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return <>{""}</>;
                  }
                })()}

                <div className="row">
                  <div className="form-group col-sm-6 col-md-6">
                    <div className="form-group was-validated">
                      <label className="col-sm-6 col-form-label">
                        Ordem Compra
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ordemCompra"
                        name="ordemCompra"
                        value={ordemCompra}
                        placeholder="Número da Ordem de Compra"
                        required="required"
                        pattern="^[0-9]*$"
                        onChange={handleOrdemCompraChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="form-group col-sm-6 col-md-6">
                    <div className="form-group was-validated">
                      <label className="col-sm-6 col-form-label">
                        Chave NFe
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="chaveNfe"
                        name="chaveNfe"
                        value={chaveNfe}
                        required="required"
                        placeholder="Chave da Nota Fiscal Eletrônica"
                        pattern="^[0-9]*$"
                        onChange={handleChaveNfeChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group was-validated">
                      <label htmlFor="Inpcard-footerutnome">Descrição</label>

                      <ReactQuill
                        value={content}
                        onChange={handleContentChange}
                      />

                      {content === "" && (
                        <Typography variant="" color="red">
                          Este campo é obrigatório
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {arquivosDemanda.map((item) => (
                    <div className="col-sm-2">
                      <button className="file-man-box">
                        <a
                          href
                          className="file-close"
                          onClick={(e) => excluirFoto(item)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>

                        <div className="file-img-box px-2 py-2">
                          {(() => {
                            if (
                              item.type ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                              return (
                                <img
                                  className="rounded mx-auto d-block  img-thumbnail"
                                  src={xls}
                                  alt=""
                                />
                              );
                            } else if (
                              item.type === "application/vnd.ms-excel"
                            ) {
                              return (
                                <img
                                  className="rounded mx-auto d-block  img-thumbnail"
                                  src={xls}
                                  alt=""
                                />
                              );
                            } else if (
                              item.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ) {
                              return (
                                <img
                                  className="rounded mx-auto d-block img-fluid img-thumbnail"
                                  src={word}
                                  alt=""
                                />
                              );
                            } else if (item.type === "image/png") {
                              return (
                                <a href={`${item.caminho}`} target="_blank">
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={`${item.caminho}`}
                                    alt={item.type === "image/png"}
                                  />
                                </a>
                              );
                            } else if (item.type === "image/jpeg") {
                              return (
                                <a href={`${item.caminho}`} target="_blank">
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={`${item.caminho}`}
                                    alt={item.type === "image/jpg"}
                                  />
                                </a>
                              );
                            } else if (
                              item.type === "application/x-zip-compressed"
                            ) {
                              return (
                                <img
                                  className="rounded mx-auto d-block  img-thumbnail "
                                  src={zip}
                                  alt=""
                                />
                              );
                            } else if (
                              item.type ===
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            ) {
                              return (
                                <img
                                  className="rounded mx-auto d-block  img-thumbnail"
                                  src={pptx}
                                  alt=""
                                />
                              );
                            } else if (
                              item.type === "application/octet-stream"
                            ) {
                              return (
                                <img
                                  className="rounded mx-auto d-block  img-thumbnail"
                                  src={rar}
                                  alt=""
                                />
                              );
                            } else if (item.type === "application/pdf") {
                              return (
                                <img
                                  className="rounded mx-auto d-block  img-thumbnail"
                                  src={pdf}
                                  alt=""
                                />
                              );
                            } else if (item.type === "text/csv") {
                              return (
                                <img
                                  className="rounded mx-auto d-block img-thumbnail"
                                  src={csv}
                                  alt=""
                                />
                              );
                            } else {
                              return "";
                            }
                          })()}
                        </div>

                        <a
                          href={item.caminho}
                          className="file-download text-info"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-download" />
                        </a>
                      </button>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="text-secondary col-12 px-2 py-2 text-center">
                      <div className="col-lg-12 mx-auto">
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                          <Dropzone onDrop={handleDrop} multiple>
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                className="dz-message dropzone"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <i className="material-icons text-muted"></i>
                                <span>{isDragActive ? "📂" : "📁"}</span>
                                <p>
                                  Arraste &amp; solte o arquivo nesta área, ou
                                  clique para selecionar
                                </p>
                                {files.length > 0 && (
                                  <div>
                                    <h2>Arquivo(s)</h2>
                                    <ul>
                                      {files.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    {(() => {
                      if (id != 0) {
                        return (
                          <button
                            onClick={(event) => {
                              handleClick(event, id);
                            }}
                            type="button"
                            className="btn bg-warning"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <i className="fa -sharp fa-address-card mr-2" /> Ver
                            Demanda {addZeroes(id, 8)}
                          </button>
                        );
                      } else {
                        return <> </>;
                      }
                    })()}
                  </div>
                  {/* {isButtonDisabled && (
                    <Box sx={{background: 'red'}} display='flex' flexDirection='row' justifyContent=''>
                      <Typography variant="subtitle1">
                      Por favor, preencha todos os campos obrigatórios.
                    </Typography>
                    </Box>
                  )} */}
                  <div className="col-sm-6">
                    <div className="float-right ml-2">
                      <Button
                        variant="outlined"
                        onClick={handleOpen}
                        disabled={isButtonDisabled || value === null}
                        size="large"
                        disableElevation
                      >
                        {"Salvar"}
                      </Button>
                    </div>

                    <div className="float-right">
                      <Button
                        variant="outlined"
                        color="error"
                        size="large"
                        disableElevation
                        onClick={(event) => {
                          handleClick(event, id);
                        }}
                      >
                        CANCELAR
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>

        {(() => {
          if (id != 0) {
            return (
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <UsuarioSolicitacoes
                      idDemanda={id}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            );
          } else {
            return <>{""}</>;
          }
        })()}

        {/* /.col */}
      </div>

      <Dialog open={openMsg} onClose={handleClick}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            "& .MuiAlert-icon": {
              color: "	#0275d8",
            },
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {(() => {
            if (id == 0) {
              return (
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <h2 className="lead">
                    <h3 className="medium text-muted"> Confirma os dados?</h3>
                    <h5 className="small text-primary mt-4">
                      Data Limite: {dayjs(value).format("DD/MM/YYYY")}
                    </h5>

                    <h6 className="small text-muted mt-2">
                      {(() => {
                        if (!nome) {
                          msgError.push("Titulo da Solicitação vazio");
                        } else if (solUsuario.length === 0) {
                          msgError.push("Nenhum usuário selecionado");
                        } else if (selectedEvento.length === 0) {
                          msgError.push("Nenhum evento selecionado");
                        } else if (!content) {
                          msgError.push("Não há descrição");
                        } else {
                          msgError = [];
                        }
                      })()}
                      <dl>
                        {(() => {
                          if (msgError.length > 0) {
                            return (
                              <h5 className="small text-danger mt-3">
                                Preencher o campo abaixo:
                              </h5>
                            );
                          }
                        })()}

                        {msgError.map((er) => (
                          <span key={er}>{er}</span>
                        ))}
                      </dl>
                    </h6>
                  </h2>
                </Typography>
              );
            } else {
              //editar demanda
              return (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <h2 className="lead">
                      <h3 className="medium text-muted">
                        {" "}
                        Confirma as alterações?
                      </h3>
                      <h5 className="small text-primary mt-4">
                        Data Limite: {dayjs(value).format("DD/MM/YYYY")}
                      </h5>

                      <h6 className="small text-muted mt-2">
                        {(() => {
                          if (!nome) {
                            msgError.push("Titulo da Solicitação vazio");
                          } else if (solUsuario.length === 0) {
                            msgError.push("Nenhum usuário selecionado");
                          } else if (selectedEvento.length === 0) {
                            msgError.push("Nenhum evento selecionado");
                          } else if (!solDescricao) {
                            msgError.push("Não há descrição");
                          } else {
                            msgError = [];
                          }
                        })()}

                        <dl>
                          {(() => {
                            if (msgError.length > 0) {
                              return (
                                <h5 className="small text-danger mt-3">
                                  Preencher o campo abaixo:
                                </h5>
                              );
                            }
                          })()}

                          {msgError.map((er) => (
                            <span key={er}>{er}</span>
                          ))}
                        </dl>
                      </h6>
                    </h2>
                  </Typography>
                </>
              );
            }
          })()}

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {isLoading ? (
              <Box m={2}>
                <CircularProgress color="success" size={30} />
              </Box>
            ) : (
              <button
                onClick={handleFecharModal}
                className="btn btn-outline-success mt-2 mr-2"
                disabled={msgError.length > 0 ? true : false}
              >
                Confirmar
              </button>
            )}
            {isLoading ? (
              ""
            ) : (
              <button
                onClick={handleCancelarModal}
                className="btn btn-outline-primary mt-2"
              >
                Fechar
              </button>
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
