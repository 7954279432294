import React, { useState } from "react";
import TabsComponent from "./TabsComponent";
import api from "../../../services/api";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SprintModal from "../../AtendimentoOS/Sprint/components/SprintModal";
import { DetalheSprintTable } from "./DetalheSprintTable";
import { DetalheSprintStatistics } from "./DetalheSprintStatistics";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DetalheSprintKanban } from "./DetalheSprintKanban";
import { AvatarSprint } from "../../../components/AvatarSprint";
import { DetalheSprintChart } from "./DetalheSprintChart";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { converterParaHorasMinutos } from "../../../utils/convertToHoursMinutes";

import { useQuery } from "react-query";
import { useUserSprint } from "../../../contexts/UserSprint";
import { SkeletonKanban } from "./SkeletonKanban";
import { KanbanMetrics } from "./KanbanMetrics/KanbanMetrics";

export const DetalheSprintsGrid = ({ sprint, isLoadingSprint }) => {
  const { sprintId } = useParams();
  const { selectedUser } = useUserSprint();

  const { data: users, isLoadingUsers } = useQuery(
    [`selected-sprint-users-${sprintId}`, sprintId],
    async () => {
      const response = await api.get(
        `controle-sprint/lista-tecnicos/${sprintId}`
      );

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSorted, setIsSorted] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const tabs = [
    {
      label: "Kanban",
      content: sprint && users && (
        <>
          <KanbanMetrics
            users={users}
            hours={{
              total: sprint.sprint.total,
              totalEstimated: sprint.sprint.totalHorasEstimadasSprint,
              totalWorked: sprint.sprint.totalHorasTrabalhadasSprint,
            }}
            onlyMetrics
          />

          <DetalheSprintKanban
            sprintData={sprint}
            isLoading={isLoadingSprint}
          />
        </>
      ),
    },
    {
      label: "Tasks",
      content: sprint && (
        <>
          <Box sx={{ display: { lg: "flex", xs: "block" } }}>
            <div className="col-lg-3 col-sm-6">
              <div className="px-2">
                <Typography
                  align="center"
                  mt={2}
                  className="text-muted"
                  sx={{ cursor: "pointer" }}
                >
                  Ordenar:{" "}
                  <strong className="text-dark">
                    {isSorted ? "Z-A" : "A-Z"}
                  </strong>
                </Typography>

                <Divider sx={{ my: 2 }} />
              </div>

              <DetalheSprintStatistics statistics={sprint.sprint} />
            </div>

            <div>
              <DetalheSprintTable sprintData={sprint} />
            </div>
            {modalIsOpen && (
              <SprintModal
                showModal={modalIsOpen}
                closeModal={handleModalIsOpen}
              />
            )}
          </Box>
        </>
      ),
    },
    {
      label: "Desempenho",
      content: sprint && (
        <>
          <DetalheSprintChart
            estimativa={
              sprint.sprint.totalHorasEstimadasSprint ?? "Sem estimativa"
            }
            total={sprint.sprint.total}
            sprintId={sprintId}
          />
        </>
      ),
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex">
          {isLoadingSprint && <SkeletonKanban />}
          {!isLoadingSprint && !isLoadingUsers && <TabsComponent tabs={tabs} />}
        </div>
      </div>
    </div>
  );
};
