import React, { lazy, Suspense } from "react";
import Header from "../../../components/Header";
import MenuOS from "../../../components/MenuOS";
import Footer from "../../../components/Footer";

import { Box, Typography, CircularProgress, Button } from "@mui/material";
import fundoPainel from "../../../assets/fundoPainel.png";
import { PainelSprints } from "./Component/PainelSprints";
import { useSprintData } from "../../../contexts/SprintDataContext";
import { grey } from "@mui/material/colors";
import { useUnidadesContext } from "../../../contexts/UnidadesContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSprintUsers } from "../../../hooks/query/useSprintUsers";
import { useSprint } from "../../../hooks/query/useSprint";
import { KanbanMetrics } from "../../DetalheSprint/Components/KanbanMetrics/KanbanMetrics";
import { PainelSprintsSkeleton } from "./Component/PainelSprintsSkeleton";
import { SkeletonKanban } from "../../DetalheSprint/Components/SkeletonKanban";
import { KanbanMetricsSkeleton } from "../../DetalheSprint/Components/KanbanMetrics/KanbanMetricsSkeleton";

const DetalheSprintKanban = lazy(() =>
  import("../../DetalheSprint/Components/DetalheSprintKanban")
);

export const Painel = () => {
  const { users } = useSprintUsers();
  const { sprints, isLoadingSprints, selectedSprint } = useSprintData();
  const { sprint: sprintData, isLoading: isLoadingSprint } = useSprint();
  const { user, resetCurrentUnidade } = useUnidadesContext();

  return (
    <div className="wrapper">
      <Header />
      <MenuOS />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <h1>Painel</h1>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {isLoadingSprints && <PainelSprintsSkeleton />}
                {!isLoadingSprints && <PainelSprints />}
              </div>
              <hr />

              {isLoadingSprint && <KanbanMetricsSkeleton />}

              {isLoadingSprint && <SkeletonKanban />}

              {!isLoadingSprint && selectedSprint && users && (
                <KanbanMetrics
                  hours={{
                    total: sprintData.sprint.total,
                    totalEstimated: sprintData.sprint.totalHorasEstimadasSprint,
                    totalWorked: sprintData.sprint.totalHorasTrabalhadasSprint,
                  }}
                  users={users}
                />
              )}

              {selectedSprint && (
                <Suspense fallback={<CircularProgress />}>
                  <DetalheSprintKanban
                    sprintData={sprintData}
                    isLoading={isLoadingSprint}
                  />
                </Suspense>
              )}

              {!isLoadingSprints && sprints?.length === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={fundoPainel} alt="Sem Sprints" />
                  <Typography
                    variant="h6"
                    color={grey[700]}
                    mt={4}
                    textTransform={"uppercase"}
                    sx={{ alignSelf: "center" }}
                  >
                    Nenhuma Sprint Planejada Ativa
                  </Typography>
                  {user.isMatriz && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => resetCurrentUnidade()}
                      sx={{ alignSelf: "center", margin: 2 }}
                      size="small"
                      startIcon={<ArrowBackIcon />}
                    >
                      Voltar
                    </Button>
                  )}
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Painel;
