import { Checkbox, FormControl, Hidden, IconButton, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const FilterCategoria = ({ classificacoes, selectedClassIds, setSelectedClassIds }) => {

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedClassIds(value);
  };

  const getSelectedClassDescriptions = () => {
    return classificacoes
      .filter((item) => selectedClassIds.includes(item.id))
      .map((item) => item.descricao);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  
  return (
    <FormControl fullWidth size="small">
      <InputLabel
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: isSmUp ? 14 : 10,
          color: "#a2b8b8",
        }}
      >
        Filtrar Categoria
      </InputLabel>
      <Select
        id="demo-simple-select"
        label="FiltrarCategoria"
        sx={{ background: "none", marginBottom: 1 }}
        multiple
        value={selectedClassIds}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        renderValue={() => getSelectedClassDescriptions().join(", ")}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        <MenuItem
          onClick={handleClose} 
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "sticky",
            top: 0,
            backgroundColor: "#FFFFFF",
            zIndex: 1,
            '&:hover': {
              background: '#ffffff',
            },
          }}
        >
          {/* <ListItemText primary="Fechar"/>
          <IconButton size="small">
            <CloseIcon fontSize="small" />
          </IconButton> */}
        </MenuItem>
        {classificacoes
        .sort((a, b) => {
          if (a.descricao > b.descricao) return 1;
          if (a.descricao < b.descricao) return -1;
          return 0;
        })
        .map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={selectedClassIds.includes(item.id)} />
            <ListItemText primary={item.descricao} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
