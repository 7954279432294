import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import csv from "../../../../assets/icones/csv.png";
import xls from "../../../../assets/icones/excel.png";
import pdf from "../../../../assets/icones/pdf.png";
import zip from "../../../../assets/icones/zip.png";
import pptx from "../../../../assets/icones/pptx.png";
import png from "../../../../assets/icones/png.png";
import rar from "../../../../assets/icones/rar.png";
import doc from "../../../../assets/icones/doc.png";
import jpg from "../../../../assets/icones/jpg.png";
import txt from "../../../../assets/icones/txt.png";
import mp4 from "../../../../assets/icones/mp4.png";
import xml from "../../../../assets/icones/xml.png";
/* import outros from "../../../../assets/icones/arquivo.png" */
import DeleteIcon from "@mui/icons-material/Delete";


const fileIcons = {
  ".xls": xls,
  ".xlsx": xls,
  ".docx": doc,
  ".doc": doc,
  ".png": png,
  ".jpeg": jpg,
  ".jpg": jpg,
  ".zip": zip,
  ".rar": rar,
  ".pptx": pptx,
  ".csv": csv,
  ".pdf": pdf,
  ".txt": txt,
  ".mp4": mp4,
  ".xml": xml,
  /* ".outros": outros, */
};

export const IconAnexos = ({ anexos, iconButtonMostrar, onDelete }) => {
  const getFileIcon = (filePath) => {
    const extension = filePath.slice(
      ((filePath.lastIndexOf(".") - 1) >>> 0) + 2
    );

    /* if ([".exe", ".bat"].includes(`.${extension.toLowerCase()}`)) {
      console.warn(`Arquivo com extensão ${extension} bloqueado.`);
      return null;
    } */

    if (['.jpg', '.jpeg', '.png'].includes(`.${extension.toLowerCase()}`)) {
      return filePath;
    }

    return fileIcons[`.${extension.toLowerCase()}`] || null;
  };



  console.log("fileIcons ---- ", fileIcons);

  return (
    <div className="d-flex">
      {anexos && anexos.map((anexo) => {

        const iconSrc = getFileIcon(anexo.caminho);

        return (
          iconSrc && (
            <div key={anexo.id} className="ml-3">
              {iconButtonMostrar && (
                <IconButton
                  style={{
                    background: "#f0f0f0",
                  }}
                  aria-label="delete"
                  size="small"
                  color="error"
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(anexo.id)
                  }}
                >
                  <Tooltip title="Excluir">
                    <DeleteIcon fontSize="inherit" />
                  </Tooltip>
                </IconButton>
              )}

              <a
                href={anexo.caminho}
                style={{ marginRight: "5px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tooltip title={anexo.nome}>
                  <img
                    className="rounded mx-auto d-block img-thumbnail"
                    style={{ width: "55px", height: "50px", objectFit: "cover" }}
                    src={iconSrc}
                    alt={anexo.nome}
                  />
                </Tooltip>
              </a>
            </div>
          )
        );
      })}
    </div>
  );
};
