import { Attachment, Download } from "@mui/icons-material";
import { useState } from "react";

import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const AttachmentButtonDropdown = ({ attachments }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "attachment-menu" : undefined;

  const handleDownload = (attachment) => {
    const link = document.createElement("a");
    link.download = `${attachment.nome}`;
    link.href = `${attachment.caminho}`;

    link.click();
  };

  return (
    <>
      <Button
        startIcon={<Attachment />}
        onClick={handleClick}
        disabled={attachments.length === 0}
      >
        Anexos
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {attachments.map((attachment, index) => (
          <MenuItem key={index} onClick={() => handleDownload(attachment)}>
            <ListItemIcon>
              <Download color="primary" />
            </ListItemIcon>
            <ListItemText primary={attachment.nome} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
