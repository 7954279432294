import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Avatar,
  Box,
  useTheme,
  useMediaQuery,
  Pagination,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SprintModal from "../../AtendimentoOS/Sprint/components/SprintModal";
import IconeUrgencia from "./IconeUrgencia";

export const DetalheSprintTable = ({ sprintData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [task, setTask] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const taskCategories = [
    {
      name: "A Fazer / Pendente",
      background: "#bbdefb",
      color: "#0d47a1",
      tasks: sprintData.fazer,
      horasEstimadas: sprintData.sprint.horasEstimadasPendente,
      total: sprintData.sprint.pendente,
    },
    {
      name: "Andamento",
      background: "#fff3e0",
      color: "#ffa000",
      tasks: sprintData.andamento,
      horasEstimadas: sprintData.sprint.horasEstimadasAndamento,
      total: sprintData.sprint.andamento,
    },
    {
      name: "Teste",
      background: "#e0e0e0",
      color: "#424242",
      tasks: sprintData.teste,
      horasEstimadas: sprintData.sprint.horasEstimadasTeste,
      total: sprintData.sprint.teste,
    },
    {
      name: "Concluido",
      background: "#aed581",
      color: "#33691e",
      tasks: sprintData.concluido,
      horasEstimadas: sprintData.sprint.horasEstimadasConcluido,
      total: sprintData.sprint.concluido,
    },
  ];

  const statusColors = {
    PENDENTE: {
      background: "#DEEBFF",
      text: "#0065FF",
    },
    ANDAMENTO: {
      background: "#FFFAE6",
      text: "#FFAB00",
    },
    TESTE: {
      background: "#EBECF0",
      text: "#6B778C",
    },
    CONCLUIDO: {
      background: "#E3FCEF",
      text: "#36B37E",
    },
  };

  const gravidadeColors = {
    Média: {
      background: "#fff3e0",
    },
    Alta: {
      background: "#ffebee",
    },
    Crítica: {
      background: "#ffebee",
    },
    Baixa: {
      background: "#e3f2fd",
    },
    Atenção: {
      background: "#fbe9e7",
    },
    Planejada: {
      background: "#ede7f6",
    },
  };

  const handleOpenModal = (task) => {
    setTask(task);

    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      {isMobile ? (
        <>
          <Typography align="center" variant="h6">
            {taskCategories[currentCategoryIndex].name}{" "}
            <Typography className="text-muted" component={"span"} ml={2}>
              {taskCategories[currentCategoryIndex].tasks.length}
            </Typography>
          </Typography>
          {taskCategories[currentCategoryIndex].tasks.map((task) => (
            <Card
              key={task.codigo}
              sx={{ marginBottom: 2, cursor: "pointer", width: "100%" }}
              onClick={() => handleOpenModal(task)}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" fontSize={16}>
                    {task.titulo}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 1,
                  }}
                >
                  <Typography
                    fontWeight={600}
                    variant="overline"
                    sx={{
                      borderRadius: "5px",
                      backgroundColor:
                        gravidadeColors[task.urgencia.descricao].background,
                      color: [task.urgencia.cor],
                      maxHeight: "70px",
                      padding: "5px",
                    }}
                  >
                    {task.urgencia.descricao}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      backgroundColor: statusColors[task.status].background,
                      color: statusColors[task.status].text,
                      borderRadius: 1,
                      padding: "0.2rem 0.5rem",
                    }}
                  >
                    {task.status}
                  </Typography>
                  <Typography variant="body2">
                    {new Date(task.datcad).toLocaleDateString()}
                  </Typography>
                  <Avatar
                    sx={{ width: 24, height: 24 }}
                    src={task.codigoControleServicoTecnico?.imagem}
                  />{" "}
                </Box>
              </CardContent>
            </Card>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            {taskCategories.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor:
                    currentCategoryIndex === index
                      ? "primary.main"
                      : "grey.400",
                  margin: "0 5px",
                  cursor: "pointer",
                }}
                onClick={() => setCurrentCategoryIndex(index)}
              ></Box>
            ))}
          </Box>

          {modalIsOpen && (
            <SprintModal
              showModal={modalIsOpen}
              closeModal={handleOpenModal}
              data={task.codigo}
            />
          )}
        </>
      ) : (
        <>
          <Table style={{ borderCollapse: "collapse", border: 0 }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "transparent", border: 0 }}>
                <TableCell
                  className="text-muted font-weight-bold"
                  sx={{
                    width: { lg: "270px", sm: "100px", xs: "100px" },
                  }}
                >
                  Nome
                </TableCell>
                <TableCell className="text-muted font-weight-bold">
                  Gravidade
                </TableCell>
                <TableCell
                  align="center"
                  className="text-muted font-weight-bold"
                >
                  Responsável
                </TableCell>
                <TableCell
                  align="center"
                  className="text-muted font-weight-bold"
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  className="text-muted font-weight-bold"
                >
                  Criado
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {taskCategories.map((category) => (
            <Accordion key={category.name}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: category.background,
                  color: category.color,
                  height: 30,
                }}
                aria-controls={`category-content-${category.name}`}
                id={`category-header-${category.name}`}
              >
                <Typography align="center">
                  {category.name}
                  {" | "}
                  {category.total}
                  {" | "}
                  {category.horasEstimadas}
                  {"h"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Table
                  size="small"
                  style={{ backgroundColor: "transparent", border: 0 }}
                >
                  <TableBody>
                    {category.tasks.map((task) => (
                      <TableRow
                        key={task.codigo}
                        style={{ backgroundColor: "transparent", border: 0 }}
                      >
                        <TableCell
                          sx={{
                            width: { lg: "270px", sm: "100px", xs: "100px" },
                          }}
                          align="left"
                        >
                          <Typography
                            component={"a"}
                            color="#000"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleOpenModal(task)}
                          >
                            {task.codigo} - {task.titulo}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              borderRadius: "5px",
                              maxHeight: "60px",
                            }}
                          >
                            <Typography fontWeight={600} variant="overline">
                              <IconeUrgencia urgency={task.urgencia} />
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: "40px",
                            }}
                          >
                            <Typography fontWeight={600} variant="overline">
                              <Avatar
                                src={task.codigoControleServicoTecnico?.imagem}
                              />
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell align="center">
                          <Box
                            sx={{
                              borderRadius: "5px",
                              backgroundColor:
                                statusColors[task.status].background,
                              color: statusColors[task.status].text,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: "40px",
                            }}
                          >
                            <Typography fontWeight={600} variant="overline">
                              {task.status}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: "40px",
                            }}
                          >
                            <Typography className="text-muted">
                              {new Date(task.datcad).toLocaleDateString()}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
          {modalIsOpen && (
            <SprintModal
              showModal={modalIsOpen}
              closeModal={handleOpenModal}
              data={task.codigo}
            />
          )}
        </>
      )}
    </div>
  );
};
