import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

export const MobileCard = ({ task }) => {
  const status = {
    PENDENTE: {
      label: "Pendente",
      background: "warning",
      color: "warning",
    },
    ANDAMENTO: {
      label: "Andamento",
      background: "primary",
      color: "#fff",
    },
    TESTE: {
      label: "Teste",
      background: "default",
      color: "#fff",
    },
    CONCLUIDO: {
      label: "Concluido",
      background: "success",
      color: "success",
    },
  };

  return (
    <Card
      key={task.codigo}
      sx={{ MarginBottom: 2, cursor: "pointer", width: "100%" }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#E8EAF6",
            borderRadius: "5px",
          }}
        >
          <Link onClick={() => handleTaskSelect(task.codigo)}>
            <Typography variant="body2" fontWeight={500}>
              Chamado: {task.codigo}
            </Typography>
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Typography variant="body2" fontWeight={500}>
            Solicitante: {task.userName}
          </Typography>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ textAlign: "left" }}
          >
            {(() => {
              const dateParts = task.datcad.split("T")[0].split("-");
              const timeParts = task.datcad.split("T")[1].split(":");
              const date = new Date(
                dateParts[0],
                dateParts[1] - 1,
                dateParts[2],
                timeParts[0],
                timeParts[1]
              );
              return (
                date.toLocaleDateString("pt-BR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }) +
                " " +
                date.toLocaleTimeString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              );
            })()}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Typography variant="body2" fontWeight={500}>
            Setor: {task.tipo.descricao}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Typography variant="body2" fontWeight={500}>
            Título: {task.titulo}
          </Typography>
        </Box>

        <Box style={{ marginTop: "10px" }}>
          <Typography variant="body2" fontWeight={500}>
            {status[task.status] ? (
              <Chip
                label={`${status[task.status].label}`}
                color={status[task.status].background || "default"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              />
            ) : null}
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "2px" }}
        >
          <Grid item xs={6}>
            <Card style={{ background: "#E8EAF6" }}>
              <CardContent>
                <Typography variant="body2" fontWeight={500} align="center">
                  Técnico
                  <Typography variant="body2" fontWeight={500} align="center">
                    {task.codigoControleServicoTecnico?.usuario ||
                      "Não atribuído"}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card style={{ background: "#FFF59D" }}>
              <CardContent>
                <Typography variant="body2" fontWeight={500} align="center">
                  Horas Estimadas
                  <Typography variant="body2" fontWeight={500} align="center">
                    {converterParaHorasMinutos(task.horasEstimadas || "00")}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card style={{ background: "#FFE082" }}>
              <CardContent>
                <Typography variant="body2" fontWeight={500} align="center">
                  Horas Trabalhadas
                  <Typography variant="body2" fontWeight={500} align="center">
                    {converterParaHorasMinutos(
                      task.totalHorasTrabalhadas || "00"
                    )}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card style={{ background: "#83e9cd" }}>
              <CardContent>
                <Typography variant="body2" fontWeight={500} align="center">
                  Horas Restantes
                  <Typography variant="body2" fontWeight={500} align="center">
                    {converterParaHorasMinutos(task.totalHorasGastas || "00")}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
