import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getCC } from "../../../../services/auth";

import { Link } from "react-router-dom";

import { AvatarSprint } from "../../../../components/AvatarSprint";

import { HoursMetrics } from "./HoursMetrics";
import { KanbanFilters } from "./KanbanFilters";
import { useState } from "react";
import { ModalDesempenho } from "../../../AtendimentoOS/Painel/Component/ModalDesempenho";
import { useSprintData } from "../../../../contexts/SprintDataContext";

export const KanbanMetrics = ({
  hours,
  users,
  onlyMetrics,
  disableOptions,
}) => {
  const { selectedSprint } = useSprintData();
  const [isModalOpen, setModalIsOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const ccusto = getCC();
  const isSpecificCC = ccusto === "0012010301";

  const handleClickOpen = () => {
    setModalIsOpen(!isModalOpen);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobile ? "1fr" : "0.45fr 1fr",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: !isMobile && "center",
        mt: 2,
        mb: !isMobile && 2,
      }}
    >
      <Box width={isMobile ? "100%" : "fit-content"}>
        {isSpecificCC && !onlyMetrics && <KanbanFilters />}

        <HoursMetrics
          total={hours?.total}
          estimativa={hours?.totalEstimated}
          trabalhadas={hours?.totalWorked}
        />
      </Box>

      <Box sx={{ mt: isMobile && 4 }}>
        <AvatarSprint users={users} />
        {!disableOptions && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              mt: 1,
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: "end",
            }}
          >
            <Typography
              component={"a"}
              variant="body2"
              sx={{ cursor: "pointer" }}
              onClick={handleClickOpen}
            >
              Desempenho do Grupo
            </Typography>
            /
            <Link to="/relatorios">
              <Typography variant="body2">Relatório</Typography>
            </Link>
          </Box>
        )}
      </Box>

      {isModalOpen && (
        <ModalDesempenho
          estimativa={hours?.totalEstimated ?? "Sem estimativa"}
          total={hours?.total}
          sprintId={selectedSprint}
          showModal={isModalOpen}
          onClose={handleClickOpen}
        />
      )}
    </Box>
  );
};
