import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
  TextField,
  Avatar,
  DialogActions,
  Tooltip,
  CircularProgress,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { Form } from "react-bootstrap";
import eyes from "../../../../assets/eye.svg";
import eyesSlash from "../../../../assets/eye-slash.svg";
import "../../../../components/controles/css/file.css";

import api from "../../../../services/api";
import { getControle } from "../../../../services/auth";
import ConfirmBox from "../../../../components/controles/ConfirmBox";
import ResponsiveDialog from "./ModalConfirmacao";
import ModalJustific from "./ModalJustific";

import SwitchP from "./SwitchPublic";
import Dropzone from "react-dropzone";
import { IconAnexos } from "./IconAnexos";
import ErrorBox from "./ErrorBox";

import { MsgUsuario } from "./MensagemUsuario";

import { useSprintData } from "../../../../contexts/SprintDataContext";
import { DescQuill } from "../../../../components/EditorToolbar";
import { makeStyles } from "@material-ui/core";

import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { HistoricoHorasEst } from "./Modal/HistoricoHorasEst";

const ContHTML = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};
const SprintModal = ({ showModal, data, closeModal }) => {
  const { selectedSprint: selectedSprintId } = useSprintData();

  const [task, setTask] = useState();
  const [mensagem, setMensagem] = useState("");
  const [mensagemEdit, setMensagemEdit] = useState("");

  const [mensagemUsuario, setMensagemUsuario] = useState([]);

  const [newHoraEstimada, setNewHoraEstimada] = useState(0);
  const [trocaTecnico, setTrocaTecnico] = useState(true);
  const [openMsg, setOpenMsg] = useState(false);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [descricao, setDescricao] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [dataUsuario, setDataUsuario] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [horaEstimada, setHoraEstimada] = useState("");
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoadingHoras, setIsLoadingHoras] = useState(false);

  const [privado, setPrivado] = useState("N");
  const [files, setFiles] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [historicoHrEstimadas, setHistoricoHrEstimadas] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Filtros
  const [statusChamado, setStatusChamado] = useState({
    previous: null,
    current: null,
  });

  const [selectedProjeto, setSelectedProjeto] = useState("");
  const [filteredProjeto, setFilteredProjeto] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState("");
  const [filteredSprint, setFilteredSprint] = useState([]);
  const [selectedClassificacao, setSelectedClassificacao] = useState("");
  const [filteredClassificacao, setFilteredClassificacao] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState("");
  const [filteredTipo, setFilteredTipo] = useState([]);
  const [responsavel, setResponsavel] = useState("");
  const [filteredResponsavel, setFilteredResponsavel] = useState([]);
  const [previous, setPrevious] = useState("");
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [openJustific, setOpenJustific] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { sprintId } = useParams();
  const queryClient = useQueryClient();

  const handlePrivateChange = () => {
    setPrivado(privado === "S" ? "N" : "S");
  };
  const [isChecked, setIsChecked] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [msgDeleted, setMsgDeleted] = useState({
    mensagem: `Anexo excluído  `,
  });

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  const usuarioControle = getControle();

  const handleCloseModal = () => {
    setStatusChamado({
      current: "ANDAMENTO",
      previous: "PENDENTE",
    });
  };

  const handleCloseModalReturn = () => {
    setStatusChamado({
      current: "TESTE",
      previous: "TESTE",
    });
  };

  const handleEditClick = (messageId, messageText) => {
    setEditingMessageId(messageId);
    setMensagemEdit(messageText);
  };

  //função botão fechar alert
  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const converterParaHorasMinutos = (totalMinutos) => {
    const sinal = totalMinutos < 0 ? "-" : " ";
    totalMinutos = Math.abs(totalMinutos);

    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;

    const horasFormatadas =
      (sinal === "-" ? "-" : " ") + horas.toString().padStart(2, "0");
    const minutosFormatados = minutos.toString().padStart(2, "0");

    const resultado = `${horasFormatadas}:${minutosFormatados}`;
    return sinal === "-" ? `(${resultado})` : `${resultado}`;
  };

  const converteParaHoras = (totalHoras) => {
    const horas = Math.floor(totalHoras);
    const minutos = 0;
    return `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;
  };

  const formatarDatacomHora = (stringData) => {
    const data = new Date(stringData);
    const dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    const horaFormatada = data.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${dataFormatada} ${horaFormatada}`;
  };

  const excluirAnexo = async (icon, it) => {
    const dataHora = new Date().toISOString();
    const dadosJson = {
      mensagem: msgDeleted.mensagem + it,
      chamadoId: data,
      userName: dataUsuario,
      publico: "N",
      tipo: 1,
      datcad: dataHora,
    };

    try {
      const response = await api.delete(`controle-anexos/${icon}`);

      if (response.status === 200 || response.status === 2001) {
        await api.post(`controle-servico-mensagens/`, dadosJson);

        setDeleted(true);
        const updatedAnexos = task.anexos.filter((anexo) => anexo.id !== icon);

        setTask({
          ...task,
          anexos: updatedAnexos,
        });

        const updatedData = await api.get(
          `controle-servico-mensagens/chamado/${data}`
        );
        setMensagemUsuario(updatedData.data.records);
      } else {
        console.log(
          "Erro ao excluir anexo. Código de resposta:",
          response.status
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleted(true);
    }
  };

  const handleDeleteUserMessage = async () => {
    try {
      let anexoId =
        (deleteData.anexos.length > 0 && deleteData.anexos[0].id) || null;

      await Promise.all([
        anexoId && api.delete(`controle-anexos/${anexoId}`),
        api.delete(`controle-servico-mensagens/` + deleteData.id),
      ]);

      const updatedAnexos = task.anexos.filter((anexo) => anexo.id !== anexoId);

      setTask({
        ...task,
        anexos: updatedAnexos,
      });

      const updatedData = await api.get(
        `controle-servico-mensagens/chamado/${data}`
      );
      setMensagemUsuario(updatedData.data.records);

      setMensagemDialog(`Operação realizada com sucesso! ${deleteData?.id}`);
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpen(false);
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    }
  };

  const handleSalvarEdit = async (e, msgId) => {
    e.preventDefault();

    try {
      var dadosJson = {
        id: msgId,
        mensagem: mensagemEdit,
      };
      await api.put(`controle-servico-mensagens/` + msgId, dadosJson);

      setMensagem("");

      const updatedData = await api.get(
        `controle-servico-mensagens/chamado/${data}`
      );
      setMensagemUsuario(updatedData.data.records);

      setEditingMessageId(null);
    } catch (err) {
      console.log(err);
    }
  };

  const getAnexos = async () => {
    await api
      .get(`controle-anexos/controle/${data}/anexos`)
      .then((response) => {
        setAnexos(response.data.records);
      });
  };

  const getMensagem = async () => {
    await api
      .get(`controle-servico-mensagens/chamado/${data}`)
      .then((response) => {
        setMensagemUsuario(response.data.records);
      });
  };
  const MensagemCont = ContHTML(mensagem);

  const handleSalvar = async (e) => {
    e.preventDefault();

    const dataHora = new Date().toISOString();

    if (MensagemCont.length < 10) {
      setMensagemDialog(
        "O campo de comentário não pode ter menos de 10 caracteres."
      );
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
      return;
    }

    const dadosJson = {
      mensagem,
      chamadoId: data,
      userName: dataUsuario,
      publico: privado,
      tipo: 1,
      datcad: dataHora,
    };

    try {
      setLoading(true);
      const res = await api.post(`controle-servico-mensagens/`, dadosJson);
      await handleUpload(res.data.records[0].id);
      setFiles([]);
      setMensagem("");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      getMensagem();
      getAnexos();
    }
  };

  const getHistoricoHoras = async () => {
    await api.get(`alteracao-horas-estimadas/${data}`).then((response) => {
      setHistoricoHrEstimadas(response.data.records);
    });
  };
  const formatarHoraEstimada = (hora) => {
    if (hora === null || hora === undefined) return "00:00";

    const [h, m] = hora.toString().split(".");
    const horas = String(h).padStart(2, "0");
    const minutos = m
      ? m.length === 1
        ? String(Number(m) * 10).padStart(2, "0")
        : m.padStart(2, "0")
      : "00";
    return `${horas}:${minutos}`;
  };

  useEffect(() => {
    const fetchTaskData = async () => {
      const response = await api.get(`controle-servico/${data}`);
      setTask(response.data.records[0]);
      setDescricao(response.data.records[0].controleServico.problema);
      setSelectedProjeto(response.data.records[0].controleServico.projeto);
      setSelectedSprint(response.data.records[0].controleServico.sprintId);
      setSelectedClassificacao(
        response.data.records[0].controleServico.classificacao.id
      );
      setSelectedTipo(response.data.records[0].controleServico.tipo);
      setPrevious(response.data.records[0].controleServico.previous);
      setStatusChamado((prevStatus) => ({
        previous: response.data.records[0].controleServico.status,
        current: response.data.records[0].controleServico.status,
      }));
      setResponsavel(
        response.data.records[0].controleServico.codigoControleServicoTecnico
      );
      const horasEstimadas =
        response.data.records[0].controleServico?.horasEstimadasTecnico;

      setHoraEstimada(formatarHoraEstimada(horasEstimadas));
    };

    const getSolicitante = async () => {
      await api.get(`/usuarioDetalhe/` + usuarioControle).then((response) => {
        setDataUsuario(response.data.records[0].nome);
      });
    };

    const getProjeto = async () => {
      await api
        .get(`cadastro-projetos?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredProjeto(response.data.records);
        });
    };

    const getSprint = async () => {
      await api
        .get(`controle-sprint?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredSprint(response.data.records);
        });
    };

    const getClassificacao = async () => {
      await api.get(`controle-classificacao`).then((response) => {
        setFilteredClassificacao(response.data.records);
      });
    };

    const getTipo = async () => {
      await api.get(`controle-tipo`).then((response) => {
        setFilteredTipo(response.data.records);
      });
    };

    const getResponsavel = async () => {
      await api
        .get(`tecnico?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredResponsavel(response.data.records);
        });
    };

    Promise.all([
      getHistoricoHoras(),
      fetchTaskData(),
      getSolicitante(),
      getTipo(),
      getClassificacao(),
      getProjeto(),
      getSprint(),
      getResponsavel(),
      getAnexos(),
      getMensagem(),
    ]);
  }, [data]);

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  const handleChangeResponsavel = async (resp) => {
    setTrocaTecnico(false);
    setResponsavel(resp);

    try {
      setIsLoadingRequest(true);

      const dadosJson = {
        codigoControleServicoTecnico: { codigo: resp.codigo },
        horasEstimadasTecnico: "00.00",
      };
      await api.put(`controle-servico/${data}`, dadosJson);

      setMensagemDialog(
        resp?.codigo == 9
          ? "Inserir um responsável"
          : "Inserir Horas Estimadas para continuar."
      );
      setTipoDialog("success");
      setCorDialog("success");
      setNewHoraEstimada("00:00");
      setHoraEstimada("00:00");
      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);
      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleChangeStatusChamado = async (event) => {
    const valor = event.target.value;
    const horasFormat = horaEstimada.replace(/:/g, ".");
    const horas = newHoraEstimada > 0 ? newHoraEstimada : horasFormat;
    const previousStatus = statusChamado.current;

    /* Estamos validando a seguinte regra: se o status atual for ANDAMENTO, e o que foi clicado (novo status) for PENDENTE
    caso verdadeiro, as horas estimadas do campo são zeradas. */
    if (previousStatus === "ANDAMENTO" && valor === "PENDENTE") {
      setNewHoraEstimada("00:00");
      setHoraEstimada("00:00");
    }

    setStatusChamado((prev) => ({
      previous: prev.current,
      current: valor,
    }));

    const shouldOpenModal =
      valor === "CONCLUIDO" ||
      valor === "TESTE" ||
      ((previousStatus === "TESTE" || previousStatus === "CONCLUIDO") &&
        valor === "PENDENTE");

    if (shouldOpenModal) {
      setConfirmationModalOpen(true);
      setPrevious(statusChamado.current);
    } else {
      try {
        const isHorasValid = horas !== "00.00" && responsavel.codigo !== 9;

        if (isHorasValid) {
          await api.put(`controle-servico/${data}`, {
            status: valor,
            horasEstimadasTecnico: horas,
            previous: previousStatus,
          });
          queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
          queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
          queryClient.invalidateQueries("projeto-chamados");

          setMensagemDialog("Status alterado com sucesso!");
          setTipoDialog("success");
          setCorDialog("success");
          setOpenMsg((prev) => !prev);
          setPrevious(statusChamado.current);

          return;
        }

        setOpenAlert(true);
      } catch (err) {
        console.error(err);
        setMensagemDialog("Houve um erro na operação deste item!");
        setTipoDialog("error");
        setCorDialog("error");
      } finally {
        getHistoricoHoras();
      }
    }
  };

  const handleCancelClick = () => {
    setMensagem("");
    setEditingMessageId(null);
  };

  const handleChangeProjeto = async (event) => {
    let projeto = event.target.value;

    setSelectedProjeto(event.target.value);

    try {
      await api.put(`controle-servico/${data}`, {
        projeto,
      });

      queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
      queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
      queryClient.invalidateQueries("projeto-chamados");

      setMensagemDialog("Projeto alterado com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleChangeSprint = async (event) => {
    let valor = event.target.value;

    try {
      setSelectedSprint({
        id: valor,
      });

      await api.put(`controle-servico/${data}`, {
        sprintId: {
          id: valor,
        },
      });

      queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
      queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
      queryClient.invalidateQueries("projeto-chamados");

      setMensagemDialog("Sprint alterada com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleChangeClassificacao = async (event) => {
    let valor = event.target.value;

    setSelectedClassificacao(valor);

    try {
      api.put(`controle-servico/${data}`, {
        classificacao: {
          id: valor,
        },
      });

      setMensagemDialog("Classificação alterada com sucesso");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleChangeTipo = async (event) => {
    let valor = event.target.value;

    try {
      setSelectedTipo({
        id: valor,
      });

      api.put(`controle-servico/${data}`, {
        tipo: {
          id: valor,
        },
      });

      setMensagemDialog("Tipo alterado com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleSubmit = async () => {
    let valor = horaEstimada.replace(/[^0-9]/g, "");

    valor = valor.padStart(4, "0");

    let horas = valor.substring(0, 2);
    let minutos = valor.substring(2, 4);

    if (parseInt(minutos, 10) > 59) {
      minutos = "59";
    }

    const formatado = `${horas}:${minutos}`;

    if (formatado !== "00:00") {
      setHoraEstimada(formatado);
    } else {
      setHoraEstimada("");
    }

    try {
      setIsLoadingHoras(true);

      let horasFormat = formatado.replace(/:/g, ".");

      const dadosJson = {
        horasEstimadasTecnico: horasFormat,
        problema: descricao,
      };

      await api.put(`controle-servico/${data}`, dadosJson);
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    } finally {
      setIsLoadingHoras(false);
    }
  };

  const handleSubmitDesc = async () => {
    try {
      const dadosJson = {
        problema: descricao,
      };

      await api.put(`controle-servico/${data}`, dadosJson);
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    }
  };

  const handleFechar = () => {
    closeModal();
    handleSubmitDesc();

    queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
    queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
    queryClient.invalidateQueries("projeto-chamados");
  };

  const handleChange = (e) => {
    let valor = e.target.value.replace(/[^0-9]/g, "");

    if (valor.length > 4) {
      valor = valor.substring(1, 5);
    }

    let horas;
    if (valor.length <= 2) {
      horas = valor.padStart(2, "0");
    } else {
      horas = valor.substring(0, 2) + ":" + valor.substring(2);
    }

    setHoraEstimada(horas);
  };

  const validateHoraEstimada = () => {
    if (
      statusChamado.current == "PENDENTE" ||
      (previous == null && horaEstimada == 0) ||
      (statusChamado.current == "PENDENTE" && previous == null)
    ) {
      return false;
    } else if (
      trocaTecnico == "" ||
      trocaTecnico == true ||
      previous == "PENDENTE" ||
      (previous == null && horaEstimada != 0) ||
      previous != null
    ) {
      return true;
    }
  };

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = async (id) => {
    setLoading(true);

    const uploadPromises = files.map(async (file) => {
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("idComentario", id);
      fileFormData.append("controle", data);

      const response = await api.post(
        `controle-servico-mensagens/enviar-arquivo`,
        fileFormData,
        {
          mode: "no-cors",
          method: "POST",
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );
      const fileWithUrl = {
        ...file,
        caminho: response.data.records[0].caminho,
      };
      setTask((prevTask) => ({
        ...prevTask,
        anexos: [...prevTask.anexos, response.data.records[0]],
      }));
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
      getMensagem();
    }
  };

  const styleSelect = {
    color: "#333333",
    fontSize: 19,
  };

  const handleChangeDescricao = (newDesc) => {
    setDescricao(newDesc);
  };

  const handleChangeMensagem = (content) => {
    setMensagem(content);
  };

  const handleMensagemEdit = (value) => {
    setMensagemEdit(value);
  };

  const clearEstimatedHours = () => {
    setHoraEstimada("00:00");
    setNewHoraEstimada("00:00");
  };

  return (
    task && (
      <Dialog
        fullScreen={fullScreen}
        variant="outlined"
        fullWidth
        maxWidth="lg"
        open={showModal}
        onClose={handleFechar}
      >
        <Card display="flex" sx={{ height: "100%", overflowY: "auto" }}>
          <CardContent
            sx={{
              display: "grid",
              fontSize: 30,
              margin: 0.01,
            }}
          >
            <Box
              display="grid"
              gridTemplateColumns={isMobile ? "1fr" : "1fr 2fr"}
              sx={{
                backgroundColor: "#304056",
                color: "#fff",
                fontSize: 10,
                px: isMobile ? 1 : 2,
                paddingTop: 1,
                borderRadius: 2,
                paddingBottom: 1,
              }}
            >
              <Box display="flex" gap={1}>
                <Typography> {task.controleServico.codigo}</Typography>

                <Tooltip title={task.controleServico.titulo}>
                  <Typography
                    overflow={!fullScreen && "hidden"}
                    textOverflow={!fullScreen && "ellipsis"}
                    whiteSpace="nowrap"
                    width={
                      fullScreen
                        ? task.controleServico.dataGerente === null
                          ? 360
                          : 180
                        : 350
                    }
                  >
                    {" "}
                    {task.controleServico.titulo}
                  </Typography>
                </Tooltip>
              </Box>

              <Box
                display="grid"
                gridTemplateColumns={
                  isMobile
                    ? "1fr 1fr"
                    : task.controleServico.dataGerente === null
                    ? "repeat(4, 0.1fr)"
                    : "0.8fr 0.5fr 2fr 1fr"
                }
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
                gap={2}
              >
                <Tooltip title={task.controleServico.userName}>
                  <Typography> {task.controleServico.userName}</Typography>
                </Tooltip>

                {task.controleServico.dataGerente !== null && (
                  <Tooltip
                    title={formatarDatacomHora(
                      task.controleServico.dataGerente
                    )}
                  >
                    <Typography whiteSpace="nowrap" textAlign={"end"}>
                      {" "}
                      {formatarDatacomHora(task.controleServico.dataGerente)}
                    </Typography>
                  </Tooltip>
                )}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 1,
                    textAlign: !isMobile && "center",
                  }}
                >
                  <Tooltip title={task.controleServico.urgencia.descricao}>
                    <Typography sx={{ textAlign: !isMobile && "center" }}>
                      {task.controleServico.urgencia.descricao}
                    </Typography>
                  </Tooltip>

                  <Tooltip
                    title={converteParaHoras(task.controleServico.urgencia.sla)}
                  >
                    <Typography
                      whiteSpace="nowrap"
                      sx={{
                        width: "100%",
                      }}
                    >
                      {converteParaHoras(task.controleServico.urgencia.sla)}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  display={
                    task.controleServico.minutosRestantes !== 0 && "flex"
                  }
                  gap={1}
                  whiteSpace="nowrap"
                  sx={{ justifyContent: "flex-end" }}
                >
                  <Typography>
                    {task.controleServico.minutosRestantes < 0 &&
                      new Date().toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </Typography>
                  <Tooltip
                    title={
                      task.controleServico.minutosRestantes < 0
                        ? "Tempo Ultrapassado"
                        : "Tempo Restante"
                    }
                  >
                    <Typography>
                      {" "}
                      {converterParaHorasMinutos(
                        task.controleServico.minutosRestantes
                      )}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <div className="row">
              <div className="col-12 mt-4">
                <div className="card card-outline card-info d-block flex-row pl-4 pt-2">
                  <div className="row">
                    <div className="col-sm-2">
                      <FormControl>
                        <label className="text-muted">
                          Atribuir Responsável
                        </label>
                        <Select
                          label="Sprint"
                          value={responsavel?.codigo}
                          sx={{
                            height: 42,
                            m: 1,
                            minWidth: 150,
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": {
                              border:
                                responsavel?.codigo === 9
                                  ? "2px solid red"
                                  : "1px solid rgba(0, 0, 0, 0.23)",
                            },
                          }}
                          labelId="responsavel"
                          id="responsavel"
                          size="small"
                        >
                          {filteredResponsavel
                            .sort((a, b) => {
                              if (a.nome > b.nome) return 1;
                              if (a.nome < b.nome) return -1;
                              return 0;
                            })
                            .map((item) => (
                              <MenuItem
                                disabled={statusChamado.current != "PENDENTE"}
                                key={item.codigo}
                                value={item.codigo}
                                onClick={() => handleChangeResponsavel(item)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    className="mr-2"
                                    src={item.imagem}
                                    width={15}
                                    height={15}
                                  />
                                  {item.nome}
                                </div>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-2">
                      <FormControl>
                        <label className="text-muted ml-2">
                          Horas Estimadas
                        </label>
                        <TextField
                          id="outlined-number"
                          onBlur={handleSubmit}
                          disabled={
                            validateHoraEstimada() ||
                            responsavel?.codigo == 9 ||
                            responsavel == null ||
                            isLoadingRequest
                          }
                          value={horaEstimada}
                          type="text"
                          size="small"
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (
                              !/^[0-9:]$/i.test(e.key) &&
                              e.key !== "Delete" &&
                              e.key !== "Backspace"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          placeholder="00:00"
                          sx={{
                            m: 1,
                            width: 115,
                            "& .MuiInputBase-input": {
                              textAlign: "right",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border:
                                horaEstimada === "00" ||
                                horaEstimada === "00:00" ||
                                horaEstimada === ""
                                  ? "2px solid red"
                                  : "1px solid rgba(0, 0, 0, 0.23)",
                            },
                          }}
                        />
                      </FormControl>
                    </div>

                    {historicoHrEstimadas.length > 0 && (
                      <div className="col-sm-4">
                        <Button onClick={handleChecked}>
                          <img
                            className="image ml-3"
                            Style={`max-height: 300px}`}
                            src={isChecked == true ? eyes : eyesSlash}
                            alt="Photo"
                          />
                        </Button>

                        <label className="text-muted ml-3">
                          Histórico Horas Estimadas
                        </label>
                        {isChecked ? (
                          <HistoricoHorasEst
                            listaHistorico={historicoHrEstimadas}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card card-outline card-info mt-4 p-2">
                  <div className="row d-flex flex-row justify-content-between">
                    <div>
                      <FormControl
                        sx={{ m: 1, minWidth: 150, flex: "1 1 auto" }}
                      >
                        <InputLabel sx={styleSelect} id="status">
                          Status
                        </InputLabel>
                        <Select
                          labelId="status"
                          id="status"
                          value={statusChamado.current}
                          onChange={handleChangeStatusChamado}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            minWidth: 150,
                          }}
                          label="Status"
                          size="small"
                          disabled={
                            statusChamado.current == "CONCLUIDO" ||
                            responsavel?.codigo == 9 ||
                            horaEstimada == "00:00" ||
                            horaEstimada == "" ||
                            horaEstimada == "00"
                          }
                        >
                          <MenuItem id="text-danger" value={"PENDENTE"}>
                            PENDENTE
                          </MenuItem>
                          <MenuItem
                            id="text-warning"
                            value={"ANDAMENTO"}
                            disabled={
                              horaEstimada + newHoraEstimada == 0 ||
                              horaEstimada === "00:00" ||
                              horaEstimada === "00:0" ||
                              responsavel == null ||
                              responsavel.codigo == 9
                            }
                          >
                            ANDAMENTO
                          </MenuItem>
                          <MenuItem
                            id="text-primary"
                            value={"TESTE"}
                            disabled={statusChamado.current == "PENDENTE"}
                          >
                            TESTE
                          </MenuItem>
                          <MenuItem
                            id="text-danger"
                            value={"CONCLUIDO"}
                            disabled={statusChamado.current !== "TESTE"}
                          >
                            CONCLUÍDO
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl
                        sx={{ m: 1, minWidth: 150, flex: "1 1 auto" }}
                      >
                        <InputLabel sx={styleSelect} id="projeto">
                          Projeto
                        </InputLabel>
                        <Select
                          label="Projeto"
                          value={selectedProjeto || "Selecionar"}
                          onChange={handleChangeProjeto}
                          disabled={statusChamado.current == "CONCLUIDO"}
                          labelId="projeto"
                          id="projeto"
                          size="small"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: 200,
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            minWidth: 150,
                          }}
                        >
                          <MenuItem value="Selecionar" disabled>
                            Selecionar
                          </MenuItem>
                          {filteredProjeto.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl
                        sx={{ m: 1, maxWidth: 200, flex: "1 1 auto" }}
                      >
                        <InputLabel sx={styleSelect} id="sprint">
                          Sprint
                        </InputLabel>
                        <Select
                          label="Sprint"
                          value={selectedSprint.id}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            minWidth: 150,
                          }}
                          onChange={handleChangeSprint}
                          labelId="sprint"
                          id="sprint"
                          size="small"
                          disabled={statusChamado.current == "CONCLUIDO"}
                        >
                          <MenuItem value="" disabled>
                            Selecionar
                          </MenuItem>
                          {filteredSprint
                            .filter((its) => {
                              return (
                                its.status == "S" && its.nome !== "BACKLOG"
                              );
                            })
                            .sort((a, b) => {
                              if (a.nome > b.nome) return 1;
                              if (a.nome < b.nome) return -1;
                              return 0;
                            })
                            .map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.nome}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl
                        sx={{ m: 1, minWidth: 180, flex: "1 1 auto" }}
                      >
                        <InputLabel sx={styleSelect} id="classificacao">
                          Classificação
                        </InputLabel>
                        <Select
                          label="Classificação"
                          value={selectedClassificacao}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          disabled={statusChamado.current == "CONCLUIDO"}
                          onChange={handleChangeClassificacao}
                          labelId="classificacao"
                          id="classificacao"
                          size="small"
                        >
                          <MenuItem value="" disabled>
                            Selecionar
                          </MenuItem>
                          {filteredClassificacao
                            .sort((a, b) => {
                              if (a.descricao > b.descricao) return 1;
                              if (a.descricao < b.descricao) return -1;
                              return 0;
                            })
                            .map((item) => (
                              <MenuItem value={item.id}>
                                {item.descricao}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl
                        sx={{ m: 1, minWidth: 150, flex: "1 1 auto" }}
                      >
                        <InputLabel sx={styleSelect} id="tipo">
                          Tipo
                        </InputLabel>
                        <Select
                          label="Tipo"
                          value={selectedTipo.id}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          onChange={handleChangeTipo}
                          disabled={statusChamado.current == "CONCLUIDO"}
                          labelId="tipo"
                          id="tipo"
                          size="small"
                        >
                          <MenuItem value="" disabled>
                            Selecionar
                          </MenuItem>
                          {filteredTipo
                            .sort((a, b) => {
                              if (a.descricao > b.descricao) return 1;
                              if (a.descricao < b.descricao) return -1;
                              return 0;
                            })
                            .map((item) => (
                              <MenuItem value={item.id}>
                                {item.descricao}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Dialog open={openMsg} onClose={handleClick}>
              <Alert
                severity={tipoDialog}
                color={corDialog}
                role="button"
                onClose={() => {
                  setOpenMsg(false);
                }}
                sx={{ "& .MuiAlert-icon": { color: "red" } }}
              >
                <AlertTitle></AlertTitle>
                {mensagemDialog}
              </Alert>
            </Dialog>
            <DialogContent>
              <Box noValidate component="form">
                <div className="mb-4">
                  <InputLabel htmlFor="descricao">Descrição</InputLabel>
                  {statusChamado.current === "PENDENTE" ? (
                    <DescQuill
                      id={1}
                      value={descricao}
                      // readOnly
                      onChange={handleChangeDescricao}
                      onBlur={handleSubmitDesc}
                    />
                  ) : (
                    <Card sx={{ padding: 2 }}>
                      <Box
                        id="descricao"
                        name="descricao"
                        dangerouslySetInnerHTML={{ __html: descricao }}
                        style={{ width: "100%", whiteSpace: "pre-wrap" }}
                      />
                    </Card>
                  )}
                </div>
                <div className="card">
                  {task && task.anexos.length > 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5%",
                          position: "static",
                        }}
                      >
                        <div>
                          <IconAnexos
                            anexos={task.anexos}
                            iconButtonMostrar={true}
                            onDelete={excluirAnexo}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-md-12 mb-4">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                      <InputLabel>Mensagem</InputLabel>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                      <SwitchP
                        privado={privado}
                        handlePrivate={handlePrivateChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-11">
                      <Form.Group>
                        <DescQuill
                          id={2}
                          value={mensagem}
                          onChange={handleChangeMensagem}
                        />
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mt-2">
                          <Dropzone
                            onDrop={handleDrop}
                            multiple={true}
                            maxFiles={0}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="dz-message dropzone"
                                {...getRootProps()}
                              >
                                {loading ? (
                                  <CircularProgress size={40} />
                                ) : (
                                  <>
                                    <input {...getInputProps()} />
                                    <i class="material-icons text-muted"></i>
                                    Arraste &amp; solte o arquivo nesta área, ou
                                    clique para selecionar .
                                    {files.length > 0 && (
                                      <div>
                                        <h5>Arquivo(s)</h5>
                                        <ul>
                                          {files.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}{" "}
                                  </>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-1 d-flex justify-content-end">
                      <Button
                        variant="contained"
                        onClick={handleSalvar}
                        disabled={MensagemCont.length < 10}
                        sx={{ background: "#primary", maxHeight: "40px" }}
                      >
                        {loading ? <CircularProgress size={20} /> : "Inserir"}
                      </Button>
                    </div>
                  </div>
                </div>

                {isConfirmationModalOpen && (
                  <ResponsiveDialog
                    open={isConfirmationModalOpen}
                    onClose={() => setConfirmationModalOpen(false)}
                    responsavel={responsavel}
                    sprintId={selectedSprint.id}
                    refreshComentarios={() => getMensagem()}
                    statusChamado={statusChamado.current}
                    statusChamadoObject={statusChamado}
                    anterior={statusChamado.previous}
                    closeModal={
                      statusChamado.previous == "TESTE"
                        ? handleCloseModalReturn
                        : handleCloseModal
                    }
                    data={data}
                    idDoTecnico={
                      task?.controleServico?.codigoControleServicoTecnico
                        ?.usuarioCompleto?.codUsuario
                    }
                    dataUsuario={dataUsuario}
                    limparHoras={clearEstimatedHours}
                  />
                )}

                <div className="col-lg-12 mb-1">
                  <div className="column">
                    <MsgUsuario
                      deleted={msgDeleted}
                      mensagemUsuario={mensagemUsuario}
                      editingMessageId={editingMessageId}
                      dataUsuario={dataUsuario}
                      handleSalvarEdit={handleSalvarEdit}
                      handleEditClick={handleEditClick}
                      openDelete={openDelete}
                      mensagem={mensagemEdit}
                      handleChangeMensagem={handleMensagemEdit}
                      handleCancelClick={handleCancelClick}
                    />
                  </div>
                </div>

                {openJustific && (
                  <ModalJustific
                    open={openJustific}
                    onClose={() => setOpenJustific(false)}
                    responsavel={responsavel}
                    statusAnt={statusChamado.previous}
                    statusChamado={statusChamado.current}
                    data={data}
                  />
                )}

                <ErrorBox
                  open={openAlert}
                  onClose={() => setOpenAlert(false)}
                  responsavel={responsavel?.codigo}
                  horas={horaEstimada}
                />
              </Box>
            </DialogContent>

            <div>
              <Dialog open={openMsg} onClose={handleFecharAlert}>
                <Alert
                  severity={tipoDialog}
                  color={corDialog}
                  role="button"
                  onClose={() => {
                    setOpenMsg(false);
                  }}
                  sx={{
                    "& .MuiAlert-icon": {
                      color: "blue",
                    },
                  }}
                >
                  <AlertTitle />
                  {mensagemDialog}
                </Alert>
              </Dialog>

              {open && (
                <ConfirmBox
                  open={open}
                  closeDialog={() => setOpen(false)}
                  title={deleteData?.mensagem}
                  deleteFunction={handleDeleteUserMessage}
                />
              )}
            </div>
          </CardContent>
        </Card>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ marginBottom: "10px" }}
            autoFocus
            onClick={handleFechar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default SprintModal;
