import React, { useState } from "react";
import { ProjetosKanbanList } from "./ProjetosKanbanList";
import { GanttProjectProvider } from "../../../contexts/GanttProjectContext";
import { Box } from "@mui/material";
import { BurndownProjeto } from "../ProjetoBurndown/BurndownProjeto";
import { GanttProjeto } from "../ProjetoGantt/GanttProjeto";
import { ChartOptionsTab } from "./ChartOptionsTab";
import api from "../../../services/api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatStringToCapitalize } from "../../../utils/formatStringToCapitalize";
import { useUserSprint } from "../../../contexts/UserSprint";
import moment from "moment";
import { ProjectKanbanFilters } from "./ProjectKanbanFilters";
import { ProjectKanbanMetrics } from "./ProjectKanbanMetrics";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import { KanbanMetrics } from "../../DetalheSprint/Components/KanbanMetrics/KanbanMetrics";
import { converterParaHorasMinutos } from "../../../utils/convertToHoursMinutes";

const ProjetosKanban = () => {
  const [currentDataView, setCurrentDataView] = useState("kanban");

  const { selectedUser } = useUserSprint();

  const [filters, setFilters] = useState({
    status: null,
    initialDate: null,
    endDate: null,
  });

  const handleChangeCurrentDataView = (view, pageTitle) => {
    setCurrentDataView(view);
  };

  const { id } = useParams();

  const { data: users, isLoading: isLoadingUsers } = useQuery(
    ["users-project-kanban", id],
    async () => {
      const response = await api.get(
        `/controle-servico/busca-tecnicos?projeto=${id}`
      );

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  const { data: projectTotals, isLoading: isLoadingTotals } = useQuery(
    ["projeto-totais", id, selectedUser, filters],
    async () => {
      const formattedDates = {
        initialDate: moment(filters.initialDate).format("YYYY-MM-DD"),
        endDate: moment(filters.endDate).format("YYYY-MM-DD"),
      };

      const params = {};

      if (selectedUser) {
        params.codTecnico = selectedUser.codigo;
      }

      if (filters.initialDate && filters.endDate) {
        params.dtInicial = formattedDates.initialDate;
        params.dtFinal = formattedDates.endDate;
      }

      if (filters.status) {
        params.status = filters.status;
      }

      const response = await api.get(`/cadastro-projetos/projetoTotais/${id}`, {
        params,
      });

      return {
        records: response.data.records,
        meta: response.data.meta,
      };
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const breadcrumbs = [
    { label: "Listar Registros", href: "/projeto" },
    { label: "Criar Projeto", href: "/projetoItem/0" },
    { label: "Página Inicial", href: "/atendimento" },
  ];

  return (
    <DefaultLayout
      pageTitle={
        currentDataView === "gantt"
          ? "Análise de Projeto"
          : currentDataView === "burndown"
          ? "Gráfico Burndown"
          : !isLoadingTotals && currentDataView === "kanban"
          ? formatStringToCapitalize(projectTotals.records[0].descricao)
          : ""
      }
      breadcrumbs={breadcrumbs}
    >
      <GanttProjectProvider>
        <Box px={2} width="100%">
          {currentDataView === "kanban" && (
            <>
              <ProjectKanbanFilters
                tabs={
                  <ChartOptionsTab
                    value={currentDataView}
                    onChange={handleChangeCurrentDataView}
                  />
                }
                projectTotals={!isLoadingTotals && projectTotals}
                filters={filters}
                setFilters={setFilters}
              />

              {!isLoadingTotals && !isLoadingUsers && (
                <KanbanMetrics
                  users={users}
                  onlyMetrics
                  disableOptions
                  hours={{
                    total: projectTotals?.records[0]?.total,
                    totalEstimated:
                      projectTotals?.records[0]?.totalHorasEstimadasProjeto,
                    totalWorked:
                      projectTotals?.records[0]?.totalHorasTrabalhadasProjeto,
                  }}
                />
              )}

              {!isLoadingTotals && projectTotals && (
                <ProjetosKanbanList
                  totals={{
                    projectTotals,
                    isLoadingTotals,
                  }}
                  filters={filters}
                  tabs={
                    <ChartOptionsTab
                      value={currentDataView}
                      onChange={handleChangeCurrentDataView}
                    />
                  }
                />
              )}
            </>
          )}

          {currentDataView === "gantt" && (
            <GanttProjeto
              tabs={
                <ChartOptionsTab
                  value={currentDataView}
                  onChange={handleChangeCurrentDataView}
                />
              }
            />
          )}

          {currentDataView === "burndown" && (
            <BurndownProjeto
              tabs={
                <ChartOptionsTab
                  value={currentDataView}
                  onChange={handleChangeCurrentDataView}
                />
              }
            />
          )}
        </Box>
      </GanttProjectProvider>
    </DefaultLayout>
  );
};

export default ProjetosKanban;
