import React, { useEffect, useState } from "react";
import "dayjs/locale/pt-br";
import api from "../../../services/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatarDataBr } from "../../../utils/utils";
import {
  Alert,
  AlertTitle,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Label } from "@mui/icons-material";

const Solicitante = () => {
  const { id } = useParams();
  const [idViagem, setIdViagem] = useState();
  const [solicitante, setSolicitante] = useState([]);
  const [destino, setDestino] = useState([]);
  const [nomeCompleto, setNomeCompleto] = useState([]);
  const [cnpjCPF, setCnpjCPF] = useState([]);
  const [compromisso, setCompromisso] = useState([]);
  const [centroCusto, setCentroCusto] = useState([]);
  const [descricaoCentroCusto, setDescricaoCentroCusto] = useState([]);
  const [motivo, setMotivo] = useState([]);

  const [unidade, setUnidade] = useState([]);
  const [descUnidade, setDescUnidade] = useState([]);

  const [gravidade, setGravidade] = useState([]);
  const [observacao, setObservacao] = useState([]);
  const [status, setStatus] = useState([]);

  const [openMsg, setOpenMsg] = useState(false);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState("");

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  const handleChangeStatusViagem = (event) => {
    let tipoStatus = event.target.value;

    setStatus(tipoStatus)

  };

  const handleSalvar = () => {

    try {
      api.put(`/viagem/` + idViagem, {
        status: status,
        observacao: observacao,
      });

      setMensagemDialog("Status alterado com sucesso! ");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item! ");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  useEffect(() => {
    const getDemanda = async () => {
      await api.get(`/demanda/` + id).then((response) => {
        setSolicitante(response.data.records[0].solicitante.nome);
        setNomeCompleto(response.data.records[0].solicitante.descricao);
        setCnpjCPF(response.data.records[0].solicitante.cpfCnpj);
        setCentroCusto(response.data.records[0].solicitante.ccusto);
        setDescricaoCentroCusto(
          response.data.records[0].solicitante.centroCustoDescricao.descricao
        );
        setDescUnidade(
          response.data.records[0].solicitante.unidadeDescricao.fantasia
        );
        setUnidade(response.data.records[0].solicitante.unidade);
        setMotivo(response.data.records[0].titulo);
      });
    };

    const getViagem = async () => {
      await api.get(`/viagem/demanda/` + id).then((response) => {
        setCompromisso(response.data.records[0].datahoracompromisso);
        setDestino(response.data.records[0].destino);
        setObservacao(response.data.records[0].observacao);
        setGravidade(response.data.records[0].gravidade);
        setStatus(response.data.records[0].status);

        setIdViagem(response.data.records[0].id);

      });
    };

    getDemanda();
    getViagem();
  }, [id]);

  return (
    <>
      <div className="form-group col-12 was-validated">
        <div className="row ">
          <div className="col-md-4 col-sm-4">
            <InputLabel>Nome Solicitante</InputLabel>
            <span >{solicitante}</span>
          </div>

          <div className="col-md-4 col-sm-4">
            <InputLabel>Destino</InputLabel>
            <span>{destino}</span>
          </div>

          <div className="col-md-4 col-sm-4">
            <InputLabel>Data Compromisso</InputLabel>
            <span>{formatarDataBr(compromisso, "DataHora")}</span>
          </div>
        </div>
      </div>

      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title text-primary"><b>Dados do Solicitante</b></h3>
        </div>
        <div className="card-body">
          <div className="row ">
            <div className="col-md-6 col-sm-6 mb-2 text-muted">
              <label className="ml-2">Nome Completo </label>
              <div className="col-9 ">
                <span>{nomeCompleto}</span>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 mb-2 text-muted">
              <label className="ml-2">CNPJ</label>
              <div className="col-6">
                <span>{cnpjCPF}</span>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6 col-sm-6 mb-2 text-muted">
              <label className="ml-2">Centro de Custo</label>
              <div className="col-6">
                <span>{centroCusto}</span>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 mb-2 text-muted">
              <label className="ml-2">Descrição Centro Custo</label>
              <div className="col-9">
                <span>{descricaoCentroCusto}</span>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6 col-sm-6 mb-2 text-muted">
              <label className="ml-2">Unidade</label>
              <div className="col-6">
                <span>{unidade}</span>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 mb-2 text-muted">
              <label className="ml-2">Unidade Descrição </label>
              <div className="col-6">
                <span>{descUnidade}</span>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12 col-sm-12 mb-2 text-muted">
              <label className="ml-2">Motivo Viagem</label>
              <div className="col-12 ">
                <span>{motivo}</span>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-12 col-sm-12 ">
              <label className="ml-2 text-muted">Observação</label>
              <div className="col-12">
                <textarea
                  className="form-control"
                  id="observacao"
                  rows="6"
                  name="observacao"
                  onChange={(e) => setObservacao(e.target.value)}
                  placeholder="Observação"
                  defaultValue={observacao}
                />
              </div>
            </div>
          </div>
          <div className="row p-2">
            <div className="col-md-12">
              <div className="row">
                <div className=" col-md-10">
                  <FormControl spacing={3}>
                    <RadioGroup
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 16,
                        },
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={status}
                      onChange={handleChangeStatusViagem}
                    >
                      <FormControlLabel
                      className="text-secondary"
                        value="AGUARDANDO"
                        control={<Radio />}
                        label="Aguardar"
                      />
                      <FormControlLabel
                      className="text-primary"
                        value="ANDAMENTO"
                        control={<Radio />}
                        label="Andamento"
                      />
                      <FormControlLabel
                      className="text-warning"
                        value="ESPERA"
                        control={<Radio />}
                        label="Em espera"
                      />
                      <FormControlLabel
                      className="text-success"
                        value="APROVADO"
                        control={<Radio />}
                        label="Aprovar"
                      />
                      <FormControlLabel
                      className="text-danger"
                        value="RECUSADO"
                        control={<Radio />}
                        label="Rejeitar"
                      />

                      
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="col-md-2">
                  <button
                    type="submit"
                    onClick={handleSalvar}
                    className="btn btn-md btn-outline-success"
                    style={{ float: "right" }}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={openMsg} onClose={handleClick}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            //width: '80%',
            //margin: 'auto',
            "& .MuiAlert-icon": {
              color: "blue",
            },
            //backgroundColor: "green"
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
    </>
  );
};

export default Solicitante;
