import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Fragment } from "react";
import { addZeroes } from "../../../../utils/utils";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AccountTree, Edit, TableChart } from "@mui/icons-material";
import { statusConfig } from "../utils/statusConfig";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";


export const ProjectsDataGrid = ({ projects }) => {
  const history = useHistory();
  const columns = [
    {
      field: "descricao",
      width: 280,
      headerName: "Descricao",
      pinned: "left",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip
            title={`${addZeroes(params.row.id, 6)} - ${params.row.descricao}`}
            arrow
          >
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
                flexGrow: 1,
                ml: 1,
              }}
            >
              {params.row.descricao.toUpperCase()}
            </Typography>
          </Tooltip>
          {params.row.diasConclusao < 0 &&
            params.row.status === "ANDAMENTO" && (
              <Chip
                label={`Em atraso`}
                size="small"
                variant="outlined"
                color="error"
                sx={{ ml: 1 }}
              />
            )}

          {params.row.diasConclusao > 0 &&
            params.row.diasConclusao < 7 &&
            params.row.status === "ANDAMENTO" && (
              <Chip
                label={`Faltam ${params.row.diasConclusao} dias`}
                variant="outlined"
                size="small"
                color="warning"
                sx={{ px: 1 }}
              />
            )}
        </Box>
      ),
    },
    {
      field: null,
      headerName: "Ações",
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <Box sx={{ display: "flex" }}>
          <Link to={`projetoItem/${params.row.id}`}>
            <IconButton color="warning">
              <Edit />
            </IconButton>
          </Link>

          <Tooltip title={`Painel`} placement="top" arrow>
            <IconButton
              color="primary"
              onClick={() => history.push(`/projeto-kanban/${params.row.id}`)}
            >
              <TableChart />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "dataInicio",
      headerName: "Início",
      headerAlign: "center",
      width: 100,
      valueGetter: (params) => {
        return params.row.dataInicio.slice(0, 10);
      },
    },
    {
      field: "dataConclusao",
      headerName: "Conclusão",
      headerAlign: "center",
      type: "number",
      width: 100,
      valueGetter: (params) => {
        return params.row.dataInicio.slice(0, 10);
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => {
        return params.row.status;
      },
      renderCell: (params) => (
        <Chip
          label={statusConfig[params.row.status].label}
          size="small"
          color={statusConfig[params.row.status].background}
        />
      ),
    },
    {
      field: "pendente",
      headerName: "A fazer",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: "#E8EAF6",
            borderRadius: 1,
            boxShadow: 1,
            width: "100%",
            color: "#283593",
          }}
        >
          <Typography textAlign="center" fontWeight={500}>
            {params.row.pendente}
          </Typography>
        </Box>
      ),
    },
    {
      field: "totalHorasEstimadasProjeto",
      headerName: "Estimada",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: "#C8E6C9",
            borderRadius: 1,
            boxShadow: 1,
            color: "#1B5E20",
            width: "100%",
          }}
        >
          <Typography textAlign="center" fontWeight={500}>
            {converterParaHorasMinutos(params.row.totalHorasEstimadasProjeto)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "andamento",
      headerName: "Andamento",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: "#FFF59D",
            borderRadius: 1,
            boxShadow: 1,
            width: "100%",
            color: "#F57F17",
          }}
        >
          <Typography textAlign="center" fontWeight={500}>
            {params.row.andamento}
          </Typography>
        </Box>
      ),
    },
    {
      field: "teste",
      headerName: "Teste",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: "#FFE082",
            borderRadius: 1,
            boxShadow: 1,
            color: "#FF6F00",
            width: "100%",
          }}
        >
          <Typography textAlign="center" fontWeight={500}>
            {params.row.teste}
          </Typography>
        </Box>
      ),
    },
    {
      field: "concluido",
      headerName: "Concluído",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: "#83e9cd",
            borderRadius: 1,
            boxShadow: 1,
            width: "100%",
            color: "#00695C",
          }}
        >
          <Typography textAlign="center" fontWeight={500}>
            {params.row.concluido}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={projects}
          columns={columns}
          initialState={{
            pinnedColumns: {
              left: ["descricao"],
            },
          }}
          sx={{ overflowX: "scroll" }}
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          autoHeight
          hideFooter
        />
      </div>
    </Box>
  );
};
