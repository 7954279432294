import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useUserFilters } from "../../../contexts/FiltersSearchContext";

export const FilterSprint = ({
  sprints = [],
  setComparSprint,
  handleCheckboxChange,
  sprintAtivas,
  handleSprintsAtivas,
}) => {
  const [open, setOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    checkedSprints,
    setCheckedSprints,
    selectedSprints,
    setSelectedSprints,
  } = useUserFilters();

  const firstSprintList = useMemo(() => sprints.slice(0, 3), [sprints]);

  const finalSprintList = useMemo(() => {
    if (sprintAtivas) {
      return sprints
        .filter((sprint) => sprint.status === "S")
        .filter(
          (sprint) => !firstSprintList.some((first) => first.id === sprint.id)
        );
    }
    return sprints.slice(3);
  }, [sprintAtivas, sprints, firstSprintList]);

  const allSelectedSprints = useMemo(() => {
    return [...new Set([...selectedSprints, ...checkedSprints])];
  }, [selectedSprints, checkedSprints]);

  useEffect(() => {
    setComparSprint(allSelectedSprints);
  }, [selectedSprints, checkedSprints]);

  useEffect(() => {
    const updatedSprintIds = sprintAtivas
      ? [
          ...firstSprintList.filter((sprint) => sprint.status === "S"),
          ...finalSprintList,
        ].map((sprint) => sprint.id)
      : sprints.map((sprint) => sprint.id);

    if (allSelected) {
      setSelectedSprints(updatedSprintIds);
      setCheckedSprints(updatedSprintIds);
    }
  }, [allSelected, sprintAtivas, sprints, firstSprintList, finalSprintList]);

  useEffect(() => {
    if (sprintAtivas) {
      const activeSprintIds = [...firstSprintList, ...finalSprintList].map(
        (sprint) => sprint.id
      );
      setSelectedSprints((prevSelected) =>
        prevSelected.filter((id) => activeSprintIds.includes(id))
      );
      setCheckedSprints((prevChecked) =>
        prevChecked.filter((id) => activeSprintIds.includes(id))
      );
    }
  }, [sprintAtivas, finalSprintList]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setAllSelected(isChecked);

    let allSprintIds = [];

    if (isChecked && sprintAtivas) {
      const allActiveSprints = [
        ...firstSprintList.filter((sprint) => sprint.status === "S"),
        ...finalSprintList,
      ].map((sprint) => sprint.id);

      setSelectedSprints(allActiveSprints);
      setCheckedSprints(allActiveSprints);
    } else if (isChecked) {
      allSprintIds = sprints.map((sprint) => sprint.id);
      setSelectedSprints(allSprintIds);
      setCheckedSprints(allSprintIds);
    } else {
      setSelectedSprints([]);
      setCheckedSprints([]);
    }
  };

  return (
    <FormGroup sx={{ gap: 0.5 }}>
      <Typography
        variant="subtitle2"
        color="#585757"
        paddingLeft={1}
        paddingTop={1}
      >
        Por Sprint
      </Typography>

      {firstSprintList.map((sprint) => (
        <FormGroup key={sprint.id}>
          <Tooltip
            title={sprint.status !== "S" && "Sprint Inativa"}
            arrow
            placement="right"
          >
            <FormControlLabel
              key={sprint.id}
              color="#585757"
              control={
                <Checkbox
                  sx={{
                    paddingLeft: 1,
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                  checked={allSelectedSprints.includes(sprint.id)}
                  onChange={() => handleCheckboxChange(sprint.id)}
                  value={sprint.id}
                />
              }
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: isSmUp ? 14 : 10,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: !isSmUp ? "35%" : "45%",
                  minHeight: 20,
                  lineHeight: "16px",
                },
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
              label={sprint.nome}
            />
          </Tooltip>
        </FormGroup>
      ))}

      <FormControl fullWidth size="small" sx={{ marginTop: 1 }}>
        <InputLabel
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: isSmUp ? 14 : 10,
            color: "#a2b8b8",
          }}
        >
          Buscar Sprint
        </InputLabel>

        <Select
          id="demo-simple-select"
          multiple
          value={selectedSprints}
          onOpen={handleOpen}
          onClose={handleClose}
          open={open}
          label="Sprint"
          input={<OutlinedInput label="Tag" />}
          renderValue={() =>
            finalSprintList
              .filter((sprint) => selectedSprints.includes(sprint.id))
              .map((sprint) => sprint.nome)
              .join(", ")
          }
          sx={{ background: "none" }}
        >
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                  indeterminate={
                    selectedSprints.length > 0 &&
                    selectedSprints.length <
                      (sprintAtivas ? finalSprintList.length : sprints.length)
                  }
                />
              }
              label="Todos"
              sx={{ marginLeft: 0.1 }}
            />
            <FormControlLabel
              control={
                <Switch checked={sprintAtivas} onChange={handleSprintsAtivas} />
              }
              label="Sprints ativas"
              sx={{ display: "flex", justifyContent: "end" }}
            />
          </MenuItem>
          {finalSprintList
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((sprint) => (
              <MenuItem
                key={sprint.id}
                value={sprint.id}
                onClick={() => handleCheckboxChange(sprint.id)}
              >
                <Checkbox checked={allSelectedSprints.includes(sprint.id)} />
                <ListItemText primary={sprint.nome} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </FormGroup>
  );
};
