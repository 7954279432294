import { Box, Card, Divider } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

const TempoTrab = ({ dataInicio, dataFim, temp }) => {
  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    title: {
      text: "Tempo Trabalhado",
      style: {
        fontSize: "20px",
      },
    },
    subtitle: {
      text: `${dayjs(dataInicio).format("DD/MM/YYYY")} á ${dayjs(
        dataFim
      ).format("DD/MM/YYYY")}`,
      style: {
        fontSize: "14px",
        color: "grey",
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#223ff9"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories:
        temp && temp.tempoTrabalhado
          ? temp.tempoTrabalhado.map((tec) => tec.tecnico.nome)
          : [],

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return converterParaHorasMinutos(val);
        },
      },
    },
  };

  const series =
    temp && temp.tempoTrabalhado
      ? [
          {
            name: "Tempo Trabalhado",
            data: temp.tempoTrabalhado.map((tec) => tec.tempoTrabalhado),
          },
        ]
      : [];

  return (
    <Card sx={{ width: "100%", height: 280 }}>
      <Box id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={280}
        />
      </Box>
    </Card>
  );
};

export default TempoTrab;
