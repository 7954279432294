import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSprintData } from "../../../../contexts/SprintDataContext";
import SprintModal from "../../Sprint/components/SprintModal";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

const AtendimTable = ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);

    setModalIsOpen(!modalIsOpen);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1350"));

  const status = {
    PENDENTE: {
      label: "Pendente",
      background: "warning",
      color: "warning",
    },
    ANDAMENTO: {
      label: "Andamento",
      background: "primary",
      color: "#fff",
    },
    TESTE: {
      label: "Teste",
      background: "default",
      color: "#fff",
    },
    CONCLUIDO: {
      label: "Concluido",
      background: "success",
      color: "success",
    },
  };

  return (
    <>
      <Box sx={{ display: "flex", p: 1, mb: 1 }}>
        <Table
          sx={{
            tableLayout: "auto",
            boxShadow: 1,
            border: "1px solid #f8f8f8",
          }}
          component={Paper}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Chamado
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Título
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Solicitante
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Data Abertura
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Setor
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Técnico
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0.5, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Horas Estimadas
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0.2, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Horas Trabalhadas
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0.1, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Horas Restantes
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, margin: 0 }}>
                <Typography variant="body2" align="center" fontWeight={500}>
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data != undefined &&
              data.length > 0 &&
              data.map((controleServicos) => (
                <TableRow key={controleServicos.id}>
                  <TableCell align="center" component="th" scope="row">
                    <Link
                      onClick={() => handleTaskSelect(controleServicos.codigo)}
                    >
                      {controleServicos.codigo}
                    </Link>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ overflow: "auto", wordWrap: "break-word" }}
                  >
                    {controleServicos.titulo}
                  </TableCell>
                  <TableCell align="center">
                    {controleServicos.userName}
                  </TableCell>
                  <TableCell align="center">
                    {(() => {
                      const dateParts = controleServicos.datcad
                        .split("T")[0]
                        .split("-");
                      const timeParts = controleServicos.datcad
                        .split("T")[1]
                        .split(":");
                      const date = new Date(
                        dateParts[0],
                        dateParts[1] - 1,
                        dateParts[2],
                        timeParts[0],
                        timeParts[1]
                      );
                      return (
                        date.toLocaleDateString("pt-BR", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }) +
                        " " +
                        date.toLocaleTimeString("pt-BR", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })
                      );
                    })()}
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                    {controleServicos.tipo.descricao}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "0.5px" }}>
                    <Box
                      sx={{
                        backgroundColor: "#E8EAF6",
                        borderRadius: 1,
                        boxShadow: 1,
                        color: "#283593",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      {controleServicos.codigoControleServicoTecnico?.usuario ||
                        "Não atribuído"}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        backgroundColor: "#C8E6C9",
                        borderRadius: 1,
                        boxShadow: 1,
                        color: "#1B5E20",
                        width: "120%",
                        padding: "10px",
                      }}
                    >
                      {converterParaHorasMinutos(
                        controleServicos.horasEstimadas || "00"
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        backgroundColor: "#FFE082",
                        borderRadius: 1,
                        boxShadow: 1,
                        color: "#FF6F00",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      {converterParaHorasMinutos(
                        controleServicos.totalHorasTrabalhadas || "00"
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        backgroundColor: "#83e9cd",
                        borderRadius: 1,
                        boxShadow: 1,
                        color: "#00695C",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      {converterParaHorasMinutos(
                        controleServicos.totalHorasGastas || "00"
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {status[controleServicos.status] ? (
                        <Chip
                          label={status[controleServicos.status].label}
                          color={
                            status[controleServicos.status].background ||
                            "default"
                          }
                        />
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>

      {modalIsOpen && (
        <SprintModal
          showModal={modalIsOpen}
          closeModal={handleTaskSelect}
          data={selectedTask}
        />
      )}
    </>
  );
};

export default AtendimTable;
