import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { getControle } from "../../../services/auth";
import { addZeroes, delay, formatarDataBr } from "../../../utils/utils";
import TotaisComentarios from "./TotaisComentarios";
import { MsgSemSolic } from "./MsgSemSolic";
import { Alert, AlertTitle, Dialog, Link } from "@mui/material";
import ConfirmBox from "../../../components/controles/ConfirmBox";
import TotaisComentariosNaoLidas from "./TotaisComentariosNaoLidas";
import LoadingSpinner from "../../../components/controles/LoadingSpinner.js";
import ProcessosAlerta from "./ProcessosAlerta";
import dayjs from "dayjs";

const GridSolicitacaiAndamento = (corTipo) => {
  let history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [novoData, setNovoData] = useState([]);
  const [nome, setNome] = useState([]);
  const [blobImage, setBlobImage] = useState([]);

  const [mensagemDialog, setMensagemDialog] = useState([]);
  const [tipoDialog, setTipoDialog] = useState([]);
  const [corDialog, setCorDialog] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [openMsg, setOpenMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClick = (event, id) => {
    history.push({
      pathname: "/processoItem/" + id,
    });
  };

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  const getDados = async () => {
    try {
      setIsLoading(true);

      await api
        .get("/demanda/solicitante/" + getControle())
        .then((response) => {
          setData(response.data.records);
        });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  async function deleteUser() {
    try {
      setIsLoadingRequest(true);
      await api.delete(`demanda/` + deleteData?.id);

      await getDados();

      setMensagemDialog(`Operação realizada com sucesso!`);
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpen(false);
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    } finally {
      setIsLoadingRequest(false);
    }
  }

  const Status = (status) => {
    if (status === 0) {
      return "ANDAMENTO";
    } else if (status === 1) {
      return "CONCLUÍDO";
    }
  };

  useEffect(() => {
    getDados();
  }, []);

  const GridDados = () => {
    return (
      <div className="card text-center">
        <div className="card-header border-0">
          <h3 className="card-title text-muted">Solicitações em Andamento </h3>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
            >
              <i className="fas fa-minus" />
            </button>
          </div>
        </div>

        <div className="card-body table-responsive p-0">
          <table className="table table-striped table-valign-middle">
            <thead>
              <tr>
                <th style={{ width: "10%", textAlign: "center" }} scope="col">
                  Reg{" "}
                </th>
                <th style={{ width: "11%", textAlign: "center" }} scope="col">
                  Dt.Aber{" "}
                </th>
                <th style={{ width: "11%", textAlign: "center" }} scope="col">
                  Prazo{" "}
                </th>
                <th style={{ width: "18%", textAlign: "center" }} scope="col">
                  Titulo{" "}
                </th>
                <th style={{ width: "9%", textAlign: "center" }} scope="col">
                  Sigla{" "}
                </th>
                <th style={{ width: "20%", textAlign: "center" }} scope="col">
                  Processos{" "}
                </th>
                <th style={{ width: "12%", textAlign: "center" }} scope="col">
                  Status
                </th>
                <th style={{ width: "9%", textAlign: "center" }} scope="col">
                  Del.
                </th>
              </tr>
            </thead>

            <tbody>
              {data
                .filter((demanda) => demanda.status != 1 && demanda)
                .sort((a, b) => b.id - a.id)
                .map(
                  (
                    item //
                  ) => (
                    <tr style={{ width: "100%", fontSize: 12 }} key={item.id}>
                      <td className="text-primary text-center">
                        <b
                          type="button"
                          id={item.id}
                          onClick={(event) => {
                            handleClick(event, item.id);
                          }}
                        >
                          {addZeroes(item.id, 8)}
                        </b>
                      </td>

                      <td className="text-center">
                        {formatarDataBr(item.dataAbertura)}
                      </td>
                      <td className="text-center">
                        {formatarDataBr(item.dataFimPrazo)}
                      </td>

                      <td className="text-center">{item.titulo}</td>
                      <td className="text-secondary text-center">
                        <b>{item.eventos.sigla} </b>
                      </td>

                      <td className="text-center">
                        <ProcessosAlerta demanda={item.id} />
                      </td>

                      <td className="text-center">{Status(item.status)}</td>

                      <td className="text-center">
                        <div className="btn btn-md">
                          <Link
                            onClick={() => openDelete(item)}
                            to={"#"}
                            className="bg-red-600 text-red  rounded-lg"
                          >
                            <i className="fa fa-solid fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      {data.length == 0 ? <MsgSemSolic /> : <GridDados />}
      <Dialog open={openMsg} onClose={handleFecharAlert}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            //width: '80%',
            //margin: 'auto',
            "& .MuiAlert-icon": {
              color: "blue",
            },
            //backgroundColor: "green"
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={deleteData?.descricao}
        deleteFunction={deleteUser}
        isLoadingRequest={isLoadingRequest}
      />
    </>
  );
};

export default GridSolicitacaiAndamento;
