import { Download } from "@mui/icons-material";
import csvIcon from "../../../assets/icones/csv.png";
import docIcon from "../../../assets/icones/doc.png";
import excelIcon from "../../../assets/icones/excel.png";
import jpgIcon from "../../../assets/icones/jpg.png";
import pdfIcon from "../../../assets/icones/pdf.png";
import pngIcon from "../../../assets/icones/png.png";
import pptxIcon from "../../../assets/icones/pptx.png";
import rarIcon from "../../../assets/icones/rar.png";
import txtIcon from "../../../assets/icones/txt.png";
import wordIcon from "../../../assets/icones/word.png";
import xlsIcon from "../../../assets/icones/xls.png";
import zipIcon from "../../../assets/icones/zip.png";

import { Badge, IconButton, Tooltip } from "@mui/material";

const icons = {
  "text/html": txtIcon,
  "image/png": pngIcon,
  "image/jpeg": jpgIcon,
  "application/pdf": pdfIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    wordIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    excelIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    pptxIcon,
  "application/zip": zipIcon,
  "text/plain": txtIcon,

  "application/vnd.ms-excel": xlsIcon,
  "application/vnd.ms-powerpoint": pptxIcon,
  "application/msword": docIcon,
  "application/vnd.oasis.opendocument.text": txtIcon,
  "application/vnd.oasis.opendocument.spreadsheet": xlsIcon,
};

export const AttachmentIcon = ({ attachment, size }) => {
  function formatAttachmentName(name) {
    let formattedName = name.replace(/\.html$/, "");

    return formattedName;
  }

  const icon = icons[attachment.type] || txtIcon;
  const altText = `${attachment.type} icon`;

  return (
    <Tooltip title={formatAttachmentName(attachment.nome)} arrow>
      <Badge
        badgeContent={
          <Download
            color="primary"
            sx={{ backgroundColor: "#f8f8f8", borderRadius: "10px" }}
          />
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <IconButton style={{ width: size, height: size }}>
          <img
            src={icon}
            alt={altText}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </IconButton>
      </Badge>
    </Tooltip>
  );
};
