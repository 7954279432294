import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

export const HoursMetrics = ({ total, estimativa, trabalhadas }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
      fontWeight={500}
      mt={1}
      color="text.secondary"
    >
      <Typography className="mr-2" variant="body2">
        Total: {total || 0}
      </Typography>
      <Typography className="mr-2" variant="body2">
        Estimativa: {converterParaHorasMinutos(estimativa || "00")}
      </Typography>
      <Typography variant="body2">
        Trabalhadas: {converterParaHorasMinutos(trabalhadas || "00")}
      </Typography>
    </Box>
  );
};
