import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useUserFilters } from "../../../contexts/FiltersSearchContext";

export const FilterTecnico = ({
  listTecnicos,
  handleCheckboxChange,
  setCombinedTecnicos,
  handleChangeTecnico,
}) => {
  const [open, setOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    selectedTecnicos,
    setSelectedTecnicos,
    checkedTecnicos,
    setCheckedTecnicos,
  } = useUserFilters();

  useEffect(() => {
    setCombinedTecnicos([
      ...new Set([...selectedTecnicos, ...checkedTecnicos]),
    ]);
  }, [selectedTecnicos, checkedTecnicos, setCombinedTecnicos]);

  const allSelectedTecnicos = [
    ...new Set([...selectedTecnicos, ...checkedTecnicos]),
  ];

  const firstTecnicosList = listTecnicos.slice(0, 3);
  const finalTecnicosList = listTecnicos.slice(3);

  const getSelectedTecnico = () => {
    return listTecnicos
      .filter((item) => selectedTecnicos.includes(item.codigo))
      .map((item) => item.nome);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setAllSelected(isChecked);

    if (isChecked) {
      const allTecnicosIds = listTecnicos.map((tec) => tec.codigo);
      setSelectedTecnicos(allTecnicosIds);
      setCheckedTecnicos(firstTecnicosList.map((tec) => tec.codigo));
    } else {
      setSelectedTecnicos([]);
      setCheckedTecnicos([]);
    }
  };

  return (
    <FormGroup sx={{ gap: 0.5 }}>
      <Typography
        variant="subtitle2"
        color="#585757"
        paddingLeft={1}
        paddingTop={1}
      >
        Por Técnico
      </Typography>
      <FormGroup>
        {firstTecnicosList
          .sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          })
          .map((tec) => (
            <FormControlLabel
              key={tec.codigo}
              value={tec.codigo}
              control={
                <Checkbox
                  sx={{
                    paddingLeft: 1,
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                  value={tec.codigo}
                  checked={allSelectedTecnicos.includes(tec.codigo)}
                  onChange={handleCheckboxChange}
                />
              }
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: isSmUp ? 14 : 10,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: !isSmUp ? "35%" : "65%",
                  minHeight: 20,
                  lineHeight: "16px",
                },
                margin: 0,
                marginBottom: "5px",
              }}
              label={tec.nome}
            />
          ))}
      </FormGroup>

      <FormControl fullWidth size="small">
        <InputLabel
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: isSmUp ? 14 : 10,
            color: "#a2b8b8",
          }}
        >
          Buscar Técnico
        </InputLabel>

        <Select
          id="demo-simple-select"
          label="FiltroTécnico"
          value={selectedTecnicos}
          onChange={handleChangeTecnico}
          onOpen={handleOpen}
          onClose={handleClose}
          open={open}
          input={<OutlinedInput label="Tag" />}
          renderValue={() =>
            finalTecnicosList
              .filter((tec) => selectedTecnicos.includes(tec.codigo))
              .map((tec) => tec.nome)
              .join(", ")
          }
          sx={{ background: "none" }}
          multiple
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "sticky",
              top: 0,
              backgroundColor: "#FFFFFF",
              zIndex: 1,
              "&:hover": {
                background: "#ffffff",
              },
            }}
          />
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAllChange}
                  indeterminate={
                    selectedTecnicos.length > 0 &&
                    selectedTecnicos.length < finalTecnicosList.length
                  }
                />
              }
              label="Todos"
              sx={{ marginLeft: 0.1 }}
            />
          </MenuItem>
          {finalTecnicosList
            .sort((a, b) => {
              if (a.nome > b.nome) return 1;
              if (a.nome < b.nome) return -1;
              return 0;
            })
            .map((tec) => (
              <MenuItem key={tec.codigo} value={tec.codigo}>
                <Checkbox checked={allSelectedTecnicos.includes(tec.codigo)} />
                <ListItemText primary={tec.nome} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </FormGroup>
  );
};
