import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  InputLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import IconeUrgencia from "./IconeUrgencia";
import { CollapsedTaskCard } from "./CollapsedTaskCard";
import { HorizontalRule } from "@mui/icons-material";
import { converterParaHorasMinutos } from "../../../utils/convertToHoursMinutes";
import FlagIcon from "@mui/icons-material/Flag";
import HistoryIcon from "@mui/icons-material/History";
import { useSpring, animated } from "react-spring";

export const TaskCard = ({ taskData, openTask, isClicked, handleChange }) => {
  const [expanded, setExpanded] = useState(false);

  if (!taskData) return null;

  const handleToggleExpand = (e) => {
    e.stopPropagation();

    setExpanded(!expanded);
  };

  const handleOpenTask = (e) => {
    e.stopPropagation();
    openTask(taskData.codigo);
  };

  const convertParaHorasMinutos = (totalMinutos) => {
    const sinal = totalMinutos < 0 ? "-" : " ";
    totalMinutos = Math.abs(totalMinutos);

    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;

    const horasFormatadas =
      (sinal === "-" ? "-" : " ") + horas.toString().padStart(2, "0");
    const minutosFormatados = minutos.toString().padStart(2, "0");

    const resultado = `${horasFormatadas}:${minutosFormatados}`;
    return sinal === "-" ? `(${resultado})` : `${resultado}`;
  };

  const currentHoursView = (status) => {
    if (status === "TESTE" || status === "CONCLUIDO") {
      return converterParaHorasMinutos(taskData.horasEstimadas);
    }

    return converterParaHorasMinutos(taskData.horasEstimadasTecnico);
  };

  const flagAnimationProps = useSpring({
    opacity: taskData.sinalizar === "S" ? 1 : 0.5,
    from: { opacity: 0 },
    reset: taskData.sinalizar === "S",
    config: { duration: 300 },
    loop: taskData.sinalizar === "S" ? { reverse: true } : false,
  });

  return (
    <Fragment>
      {expanded ? (
        <Box
          onClick={(e) => handleOpenTask(e)}
          sx={{
            border: `2px solid ${taskData.urgencia.cor}`,
            borderRadius: "5px",
            p: 2,
            width: "100%",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            mb: 2,
            cursor: "pointer",
            "&:hover": {
              outline: `2px solid ${taskData.urgencia.cor}`,
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <Typography variant="body2" sx={{ justifyContent: "start" }}>
                <InputLabel sx={{ fontSize: "10px", color: "gray" }}>
                  Solicitante
                </InputLabel>
                {taskData.userName}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: taskData.status !== "CONCLUIDO" ? 0 : 1,
                }}
              >
                {taskData.sinalizar === "S" ? (
                  <Tooltip title="Prioridade">
                    <animated.div style={flagAnimationProps}>
                      <FlagIcon
                        sx={{ color: "red", fontSize: 17 }}
                        onClick={handleChange}
                      />
                    </animated.div>
                  </Tooltip>
                ) : (
                  ""
                )}

                {taskData.status !== "CONCLUIDO" && (
                  <Tooltip
                    arrow
                    title={
                      taskData.minutosRestantes < 0
                        ? "Tempo Ultrapassado"
                        : "Tempo Restante"
                    }
                  >
                    <Box // Tempo restante
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        cursor: "default",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          color:
                            taskData?.minutosRestantes < 0 ? "red" : "#6C6767",
                          marginRight: taskData?.minutosRestantes < 0 ? 4 : 0,
                        }}
                      >
                        {convertParaHorasMinutos(taskData.minutosRestantes)}
                      </span>

                      <HistoryIcon
                        className={
                          taskData?.minutosRestantes < 0 ? "fa-blink" : ""
                        }
                        style={{
                          fontSize: "17px",
                          color:
                            taskData?.minutosRestantes < 0 ? "red" : "#6C6767",
                          marginLeft: taskData?.minutosRestantes >= 0 ? 13 : 0,
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}

                <IconeUrgencia urgency={taskData.urgencia} />
                <IconButton onClick={(e) => handleToggleExpand(e)} size="small">
                  <HorizontalRule sx={{ width: 14, height: 14 }} />
                </IconButton>
              </Box>
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {new Date(taskData.datcad).toLocaleDateString()}
              </Typography>

              <Typography variant="body1" color="textSecondary">
                {taskData.codigo}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="body2"
                gutterBottom
                mt={1}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "60%",
                }}
              >
                {taskData.titulo?.toUpperCase()}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  <InputLabel sx={{ fontSize: "12px", color: "gray" }}>
                    <i className="fa fa-clock text-warning mr-1"></i>Est
                  </InputLabel>
                  <div className="text-center">
                    {currentHoursView(taskData.status) || "N/D"}
                  </div>
                </Typography>

                <Typography variant="caption" color="textSecondary">
                  <InputLabel sx={{ fontSize: "12px", color: "gray" }}>
                    <i className="fa fa-clock text-success mr-1"></i>Tot.Trab
                  </InputLabel>
                  <div className="text-center">
                    {taskData.totalHorasTrabalhadas
                      ? `${converterParaHorasMinutos(
                          taskData.totalHorasTrabalhadas
                        )}`
                      : "N/D"}
                  </div>
                </Typography>

                <Typography variant="caption" color="textSecondary">
                  <InputLabel sx={{ fontSize: "12px", color: "gray" }}>
                    <i className="fa fa-clock text-info mr-1"></i>Excedente
                  </InputLabel>
                  <div
                    className="text-center"
                    style={{
                      color:
                        taskData.totalHorasGastas == null
                          ? "gray"
                          : taskData.totalHorasGastas <= 0
                          ? "blue"
                          : "red",
                    }}
                  >
                    {taskData.totalHorasGastas != null
                      ? `${converterParaHorasMinutos(
                          taskData.totalHorasGastas || "00"
                        )}`
                      : "N/D"}
                  </div>
                </Typography>
                <Tooltip
                  title={taskData.codigoControleServicoTecnico?.nome}
                  arrow
                >
                  <Avatar
                    src={taskData.codigoControleServicoTecnico?.imagem}
                    alt={taskData.codigoControleServicoTecnico?.nome}
                    sx={{
                      width: 30,
                      height: 30,
                      outline: "0.1px solid darkgray",
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <CollapsedTaskCard
          taskData={taskData}
          handleToggleExpand={handleToggleExpand}
        />
      )}
    </Fragment>
  );
};
