import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AlertSuccess, AlertDanger } from "./styles";
import {
  Box,
  FormLabel,
  InputLabel,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ColorPicker } from "@wellbees/color-picker-input";
import api from "../../../../services/api";

export const CadastrosEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [descricao, setDescricao] = useState([]);
  const [status, setStatus] = useState("1");
  const [sla, setSla] = useState("");
  const [colorValue, setColorValue] = useState();
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  /*Editar Registros*/
  const editCadastros = async (e) => {
    e.preventDefault();
    try {
      if (id !== "N") {
        var dadosJson = {
          id: id,
          descricao: descricao,
          cor: colorValue,
          status: status,
          sla: sla,
        };

        await api.put(`controle-prioridade/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          descricao: descricao,
          cor: colorValue,
          status: status,
          sla: sla,
        };

        await api.post(`controle-prioridade/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/cadastros",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getGravidade = async () => {
      await api.get(`/controle-prioridade/${id}`).then((response) => {
        setDescricao(response.data.records[0].descricao);
        setStatus(response.data.records[0].status);
        setColorValue(response.data.records[0].cor);
        setSla(response.data.records[0].sla);
      });
    };

    if (id !== "N") {
      getGravidade();
    }
  }, [id]);

  const handleSlaChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && Number(value) >= 0) {
      setSla(value);
    }
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editCadastros}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: isMobile
                        ? "1fr"
                        : "1.1fr 0.5fr minmax(50px, 0.4fr)",
                      flexDirection: isMobile ? "column" : "row",
                      gap: 2,
                      alignItems: "left",
                      height: "100%",
                      width: isMobile ? "100%" : "80%",
                    }}
                  >
                    <TextField
                      label="Descrição"
                      type="text"
                      className="form-control"
                      id="descricao"
                      name="descricao"
                      value={descricao}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setDescricao(e.target.value)}
                      required="required"
                      placeholder="Descrição..."
                      inputProps={{
                        style: {
                          height: 17,
                        },
                      }}
                    />

                    <Box mt={isMobile && 2}>
                   
                      <ColorPicker
                        fullWidth={isMobile}
                        name="color"
                        inputType="mui"
                        size={"small"}
                        specialColors={"#ffff"}
                        required={true}
                        onChange={(color) => setColorValue(color)}
                        value={colorValue !== null ? colorValue : "#ffffff"}
                      />
                    </Box>

                    <Box sx={{ mt: isMobile ? 1 : 0, width: isMobile ? '100%' : '90px' }}>
                      <TextField
                        label="SLA"
                        type="number"
                        className="form-control"
                        size="small"
                        inputProps={{
                          style: { height: 33},
                          onInput: handleInput,
                          min: 1,
                        }}
                        id="sla"
                        name="sla"
                        value={sla}
                        onChange={handleSlaChange}
                        placeholder="Horas Sla..."
                        required={true}
                        
                      />
                    </Box>
                  </Box>

                  <br />

                  <div className="row">
                    <label
                      className="col-sm-2 col-form-label"
                      style={{ fontSize: 14 }}
                    >
                      Status{" "}
                    </label>
                    <div className="col-sm-12">
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Ativo
                      </FormLabel>
                      <Radio
                        checked={status == "1"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "1" }}
                      />
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Inativo
                      </FormLabel>
                      <Radio
                        checked={status == "0"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="0"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "0" }}
                      />
                    </div>
                  </div>

                  <div className="form-group row float-right">
                    <div className="col-sm-10">
                      <button
                       type="submit"
                       className="btn btn-primary"
                       disabled={sla <= 0 || !colorValue || !descricao }>
                        {" "}
                        Salvar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
