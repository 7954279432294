import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { AlertSuccess, AlertDanger } from "./styles";
import { FormLabel, Radio } from "@mui/material";

export const ClassificacaoEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [descricao, setDescricao] = useState([]);
  const [status, setStatus] = useState("");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  useState();

  /*Editar Registros*/
  const editCadastros = async (e) => {
    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          descricao: descricao,
          status: status,
        };

        //console.log("PUUUUUT ::::::", JSON.stringify(dadosJson));
        await api.put(`controle-classificacao/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          descricao: descricao,
          status: status,
        };

        // console.log("Caiu no POST ::::::", JSON.stringify(dadosJson));
        await api.post(`controle-classificacao/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/classificacao",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getClassificacao = async () => {
      await api.get(`/controle-classificacao/${id}`).then((response) => {
        setDescricao(response.data.records[0].descricao);
        setStatus(response.data.records[0].status);
      });
    };

    if (id !== "0") {
      getClassificacao();
    } 
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editCadastros}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <label className="col-sm-6 col-form-label">
                        Descrição
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="descricao"
                        name="descricao"
                        placeholder="Descrição..."
                        onChange={(e) => setDescricao(e.target.value)}
                        defaultValue={descricao}
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Status </label>
                    <div className="col-sm-12">
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Ativo
                      </FormLabel>
                      <Radio
                        checked={status == "1"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "1" }}
                      />
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Inativo
                      </FormLabel>
                      <Radio
                        checked={status == "0"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="0"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "0" }}
                      />
                    </div>
                  </div>

                  <div className="form-group row float-right mt-2">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        {" "}
                        Salvar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
