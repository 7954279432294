import { formatarDataBaseDados } from "../../../../../utils/utils";

export const HistoricoHorasEst = ({ listaHistorico }) => {
  return (
    <div>
      <table
        className="table table-striped table-sm"
        style={{ border: "2px solid" }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: "#D9D9D9",
              fontSize: 12,
            }}
          >
            <th
              style={{
                width: "20%",
                border: "1px solid",
              }}
            >
              Data
            </th>
            <th
              style={{
                width: "15%",
                border: "1px solid",
              }}
            >
              Responsável
            </th>

            <th
              style={{
                width: "10%",
                border: "1px solid",
              }}
            >
              Hs Est.
            </th>
          </tr>
        </thead>
        {listaHistorico
          .sort((a, b) => {
            if (
              formatarDataBaseDados(a.datcad) < formatarDataBaseDados(b.datcad)
            )
              return 1;
            if (
              formatarDataBaseDados(a.datcad) > formatarDataBaseDados(b.datcad)
            )
              return -1;
            return 0;
          })
          .map((it) => {
            return (
              <tbody key={it.id}>
                <tr
                  style={{
                    backgroundColor: "#FFFFFF",
                    fontSize: 14,
                    textAlign: "center",
                    border: "1px solid",
                  }}
                >
                  <td
                    style={{
                      border: "1px solid",
                    }}
                  >
                    {formatarDataBaseDados(it.datcad)}
                  </td>
                  <td
                    style={{
                      border: "1px solid",
                    }}
                  >
                    {it.tecnico?.nome}
                  </td>
                  <td
                    style={{
                      border: "1px solid",
                    }}
                  >
                    {it.horasEstimadas}
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>
    </div>
  );
};
