import { Box, Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AttachmentIcon } from "./AttachmentIcon";
import { AttachmentButtonDropdown } from "./AttachmentButtonDropdown";

export const SplitScreenContent = ({
  leftContent,
  attachments,
  title,
  rightContent,
}) => {
  return (
    <Grid
      container
      direction="row"
      sx={{ mt: 6 }}
      gap={2}
      justifyContent={"space-between"}
    >
      <Grid item xl={8} lg={8} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: title }}
            textAlign={"left"}
            fontWeight={700}
            color={grey[800]}
            fontSize={24}
            mb={2}
          ></Typography>

          <AttachmentButtonDropdown attachments={attachments} />
        </Box>
        <Box>
          <Typography
            dangerouslySetInnerHTML={{ __html: leftContent }}
          ></Typography>
          {attachments &&
            attachments.map((attachment) => (
              <AttachmentIcon
                key={attachment.id}
                attachment={attachment}
                size={60}
              />
            ))}
        </Box>
      </Grid>
      <Grid item xl={3} lg={3} sm={12} xs={12}>
        <Box>{rightContent}</Box>
      </Grid>
    </Grid>
  );
};
