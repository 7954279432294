import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  MenuItem,
  IconButton,
  Menu,
  Avatar,
  Tooltip,
  Dialog,
  Alert,
  AlertTitle,
  useMediaQuery,
  useTheme,
  CardContent,
  Typography,
  Card,
  Box,
  CircularProgress,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useQueryClient } from "react-query";
import api from "../../../../services/api";
import "../../../../components/controles/css/file.css";
import { formatarDataBr } from "../../../../utils/utils";
import dayjs from "dayjs";
import "./grid.css";
import IconeUrgencia from "../../../DetalheSprint/Components/IconeUrgencia";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import BacklogDialog from "./ModalBacklog";
import SprintModal from "../../Sprint/components/SprintModal";
import IconeStatus, { IconeStatusSprint } from "./IconeStatus";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConfirmBox from "../../../../components/controles/ConfirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { getControle } from "../../../../services/auth";
import EditIcon from "@mui/icons-material/Edit";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";
import HistoryIcon from "@mui/icons-material/History";
import "./style.css";

const fetchControleSprint = async () => {
  const response = await api.get(
    `controle-sprint?codUsuario=${getControle()}&status=S`
  );
  return response.data.records;
};

const fetchUsuarioDetalhe = async (UsuarioControle) => {
  const response = await api.get(`usuarioDetalhe/${UsuarioControle}`);
  return response.data.records[0].nome;
};

const BacklogGrid = ({
  statusFilter,
  statusGravidade,
  dataInicio,
  dataFim,
  tipo,
  departamento,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const dataInicialFormatada = dayjs(dataInicio).format("YYYY-MM-DD");
  const dataFinalFormatada = dayjs(dataFim).add(1, "day").format("YYYY-MM-DD");
  const [selectedTask, setSelectedTask] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [openMsg, setOpenMsg] = useState(false);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const UsuarioControle = getControle();
  const [resultSearch, setResultSearch] = useState([]);
  const { currentUnidade } = useUnidadesContext();
  const [search, setSearch] = useState();
  const queryClient = useQueryClient();

  const { data: dataControleServico, isLoading: isLoadingControleServico } =
    useQuery(
      [
        "controle-servico",
        currentUnidade,
        statusGravidade,
        dataInicialFormatada,
        dataFinalFormatada,
        departamento,
        tipo,
        statusFilter,
      ],
      async () => {
        const params = {
          codUsuario: UsuarioControle,
          dtInicio: dataInicialFormatada,
          dtFinal: dataFinalFormatada,
          size: 100,
        };

        if (statusGravidade && statusGravidade !== "Todos") {
          params.urgencia = statusGravidade;
        }

        if (statusFilter && statusFilter !== "Todos") {
          params.status = statusFilter;
        }

        if (departamento && departamento !== "Todos") {
          params.departamento = departamento;
        }

        if (tipo && tipo !== "Todos") {
          params.aprovGerente = tipo;
        }

        if (currentUnidade) {
          params.unidade = currentUnidade.cnpj;
        }

        const response = await api.get("controle-servico", {
          params,
        });
        return response.data.records;
      },
      {
        refetchOnWindowFocus: false,
        refetchInterval: 80000,
        onError: (error) => {
          console.log(error);
          return [];
        },
      }
    );

  const { data: dataSprint, isLoading: isLoadingSprint } = useQuery(
    "controle-sprint",
    fetchControleSprint,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: usuarioNome, isLoading: isLoadingUsuario } = useQuery(
    ["usuario-detalhe", UsuarioControle],
    () => fetchUsuarioDetalhe(UsuarioControle)
  );

  const handleDeleteChamado = async () => {
    try {
      setIsLoading(true);
      const response = await api.delete(`controle-servico/${deleteData}`);

      if (response.status === 200 || response.status === 201) {
        const updatedData = await api.get("controle-servico");
        setData(updatedData.data.records);
        queryClient.invalidateQueries("controle-servico");

        setMensagemDialog("Exclusão realizada com sucesso!");
        setTipoDialog("success");
        setCorDialog("success");
        setOpenMsg(!openMsg);
        setOpen(false);
      }
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.${error}`);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChamado = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleSelectSprint = (sprint) => {
    setSelectedSprint(sprint);
    setConfirmationModalOpen(!isConfirmationModalOpen);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (item) => {
    setSelectedTask(item);
    setShowModal(!showModal);
  };

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isLoadingControleServico || isLoadingSprint || isLoadingUsuario) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={"30vh"}
      >
        <CircularProgress />
      </Box>
    );
  }

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);

    if (dataControleServico.length > 0) {
      const buscaResultados = dataControleServico.filter((event) => {
        const idMatch = event.codigo
          .toString()
          .toLowerCase()
          .includes(searchValue);
        const descricaoMatch = event.titulo.toLowerCase().includes(searchValue);
        return idMatch || descricaoMatch;
      });

      if (buscaResultados.length > 0) {
        setResultSearch(buscaResultados);
      } else {
        setResultSearch([]);
      }
    } else {
      setResultSearch([]);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const urgenciaOrder = {
    Crítica: 1,
    Alta: 2,
    Atenção: 3,
    Média: 4,
    Baixa: 5,
    Planejada: 6,
    Success: 7,
  };

  const converterParaHorasMinutos = (totalMinutos) => {
    const sinal = totalMinutos < 0 ? "-" : " ";
    totalMinutos = Math.abs(totalMinutos);

    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;

    const horasFormatadas =
      (sinal === "-" ? "-" : " ") + horas.toString().padStart(2, "0");
    const minutosFormatados = minutos.toString().padStart(2, "0");

    const resultado = `${horasFormatadas}:${minutosFormatados}`;
    return sinal === "-" ? `(${resultado})` : `${resultado}`;
  };

  const converteParaHoras = (totalHoras) => {
    const horas = Math.floor(totalHoras);
    const minutos = 0;
    return `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;
  };

  const formatarDatacomHora = (stringData) => {
    const data = new Date(stringData);
    const dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    const horaFormatada = data.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${dataFormatada} ${horaFormatada}`;
  };

  return (
    <>
      {isMobile ? (
        <>
          <Stack>
            <TextField
              onChange={handleSearch}
              value={search}
              name="search"
              id="search"
              variant="standard"
              placeholder="Filtrar por número chamado / Descrição..."
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: { paddingLeft: "5px" },
              }}
              sx={{
                /* minWidth: isMobile ? "33vh" : isTablet ? "54vh" : "138vh", */
                backgroundColor: "#FFFFFF",
                borderRadius: 3,
                marginTop: 2,
                border: "0.5px solid #C6C4C0",
                padding: 0.6,
                alignItems: "left",
                justifyContent: "left",
                "& input::placeholder": {
                  textAlign: "left",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
              }}
              /* ref={searchRef} */
            />
          </Stack>

          {(resultSearch.length > 0
            ? resultSearch
            : search
            ? []
            : dataControleServico
          )
            .sort((a, b) => {
              if (a.minutosRestantes < 0 && b.minutosRestantes < 0) {
                if (a.minutosRestantes > b.minutosRestantes) return 1;
                if (a.minutosRestantes < b.minutosRestantes) return -1;
              } else if (a.minutosRestantes < 0) {
                return -1;
              } else if (b.minutosRestantes < 0) {
                return 1;
              }

              if (a.minutosRestantes > 0 && b.minutosRestantes > 0) {
                if (a.minutosRestantes > b.minutosRestantes) return 1;
                if (a.minutosRestantes < b.minutosRestantes) return -1;
              }

              return (
                urgenciaOrder[a.urgencia.descricao] -
                urgenciaOrder[b.urgencia.descricao]
              );
            })

            .map((item) => (
              <Card
                key={item.codigo}
                sx={{ mt: 1, borderTop: `2px solid ${item.urgencia.cor}` }}
              >
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      bgcolor="#F2F2F2"
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Typography variant="subtitle1">
                          <Link
                            to={`/ChamadoItem/${item.codigo}`}
                            style={{
                              color: "black",
                              fontSize: 14,
                              cursor: "pointer",
                              marginLeft: 8,
                            }}
                          >
                            {item.codigo}
                          </Link>
                        </Typography>
                        <IconeUrgencia urgency={item.urgencia} />
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip
                          title={
                            item.minutosRestantes < 0
                              ? "Tempo Ultrapassado"
                              : "Tempo Restante"
                          }
                        >
                          <Box
                            sx={{ display: "flex", alignItems: "center" }} // Tempo restante
                          >
                            <HistoryIcon
                              className={
                                item?.minutosRestantes < 0 ? "fa-blink" : ""
                              }
                              style={{
                                fontSize: "18px",
                                marginRight: 4,
                                color:
                                  item?.minutosRestantes < 0
                                    ? "red"
                                    : "#6C6767",
                              }}
                            />

                            <span
                              style={{
                                fontSize: "12px",
                                color:
                                  item?.minutosRestantes < 0
                                    ? "red"
                                    : "#6C6767",
                              }}
                            >
                              {converterParaHorasMinutos(item.minutosRestantes)}
                            </span>
                          </Box>
                        </Tooltip>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip
                          title={item.codigoControleServicoTecnico?.nome}
                        >
                          <Avatar
                            src={item.codigoControleServicoTecnico?.imagem}
                            sx={{ width: 30, height: 30 }}
                          />
                        </Tooltip>

                        <Tooltip title="Sprint">
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            {dataSprint
                              // .filter((its) => {
                              //   return its.status == "S" || its.status == "A" || [];
                              // })
                              .map((sp) => {
                                return (
                                  <MenuItem
                                    key={sp.id}
                                    onClick={() => {
                                      handleSelectSprint(sp.id);
                                      setConfirmationModalOpen(true);
                                    }}
                                  >
                                    <IconeStatusSprint task={sp} /> {sp.nome}
                                  </MenuItem>
                                );
                              })}
                          </Menu>
                        </Tooltip>

                        {(() => {
                          if (item.aprovadoGerente === "S") {
                            return (
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(event) =>
                                  handleSelectChamado(event, item)
                                }
                              >
                                <GridMoreVertIcon />
                              </IconButton>
                            );
                          }
                        })()}
                      </Box>
                    </Box>

                    <Tooltip title={"Solicitante"} arrow>
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mt={2}
                        mb={1}
                      >
                        <Typography variant="subtitle2">
                          {item.userName}
                        </Typography>
                      </Box>
                    </Tooltip>

                    <Tooltip title={"Título do Chamado"} arrow>
                      <Typography sx={{ mb: 1 }} variant="subtitle2">
                        {item.titulo}
                      </Typography>
                    </Tooltip>
                  </Box>

                  <Tooltip title={"Aprovação Gerencial"} arrow>
                    <Box
                      sx={{
                        gridTemplateColumns: "2fr 0.1fr 0.2fr",
                        display: "grid",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {item.dataGerente !== null ? (
                          formatarDatacomHora(item.dataGerente)
                        ) : (
                          <Box sx={{ visibility: "hidden" }}>{"--------"}</Box>
                        )}
                      </Typography>

                      <Box sx={{ flexGrow: 1 }} />

                      {usuarioNome === item.userName ? (
                        <>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            color="error"
                            onClick={() => openDelete(item.codigo)}
                          >
                            <Tooltip title="Excluir Chamado">
                              <DeleteIcon fontSize="inherit" />
                            </Tooltip>
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}

                      <IconeStatus task={item} />
                    </Box>
                  </Tooltip>
                </CardContent>
              </Card>
            ))}
        </>
      ) : (
        <>
          <Stack>
            <TextField
              onChange={handleSearch}
              value={search}
              name="search"
              id="search"
              variant="standard"
              placeholder="Filtrar por número chamado / Descrição..."
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: { paddingLeft: "5px" },
              }}
              sx={{
                /* minWidth: isMobile ? "33vh" : isTablet ? "54vh" : "138vh", */
                backgroundColor: "#FFFFFF",
                borderRadius: 3,
                marginTop: 2,
                border: "0.5px solid #C6C4C0",
                padding: 0.6,
                alignItems: "left",
                justifyContent: "left",
                "& input::placeholder": {
                  textAlign: "left",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
              }}
              /* ref={searchRef} */
            />
          </Stack>

          {(resultSearch.length > 0
            ? resultSearch
            : search
            ? []
            : dataControleServico
          )

            .sort((a, b) => {
              if (a.minutosRestantes < 0 && b.minutosRestantes < 0) {
                if (a.minutosRestantes > b.minutosRestantes) return 1;
                if (a.minutosRestantes < b.minutosRestantes) return -1;
              } else if (a.minutosRestantes < 0) {
                return -1;
              } else if (b.minutosRestantes < 0) {
                return 1;
              }

              if (a.minutosRestantes > 0 && b.minutosRestantes > 0) {
                if (a.minutosRestantes > b.minutosRestantes) return 1;
                if (a.minutosRestantes < b.minutosRestantes) return -1;
              }

              return (
                urgenciaOrder[a.urgencia.descricao] -
                urgenciaOrder[b.urgencia.descricao]
              );
            })

            .map((item) => {
              return (
                <Card
                  key={item.id}
                  sx={{
                    mt: 2,
                    mb: 2,
                    borderLeft: `5px solid ${item.urgencia.cor}`,
                  }}
                >
                  <CardContent
                    sx={{
                      px: 1,
                      display: "grid",
                      gridTemplateColumns:
                        "0.2fr minmax(150px, 3fr) minmax(120px, 1fr) minmax(115px, 1fr) 1fr 1fr minmax(40px, 1fr) repeat(4, 1fr) 0.1fr",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <Link
                        to={`/ChamadoItem/${item.codigo}`}
                        style={{
                          color: "black",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                      >
                        {item.codigo}
                      </Link>
                    </Typography>

                    <Tooltip title={"Título do Chamado"} arrow>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          to={`/ChamadoItem/${item.codigo}`}
                          style={{
                            color: "black",
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                        >
                          {/*  {item.titulo} */}
                          {truncateText(item.titulo, 22)}
                        </Link>
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={"Solicitante"}
                      arrow
                      style={{ cursor: "default" }}
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        {truncateText(item.userName, 14)}
                      </Typography>
                    </Tooltip>

                    <Tooltip // Data aprovação Gerente
                      title={"Aprovação Gerencial "}
                      arrow
                      style={{ cursor: "default" }}
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        {item.dataGerente !== null &&
                          formatarDatacomHora(item.dataGerente)}

                        {!item.dataGerente && <Box />}
                      </Typography>
                    </Tooltip>

                    <Tooltip // Setor
                      title={"Setor"}
                      style={{ cursor: "default" }}
                      arrow
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        {truncateText(
                          item.userDetalhe.centroCustoDescricao.descricao,
                          10
                        )}
                      </Typography>
                    </Tooltip>

                    <Box // Ícone urgencia - Descrição urgencia - urgencia SLA
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        backgroundColor: item.urgencia.cor || "transparent",
                        color: "white",
                        borderRadius: 3,
                        border: "2px solid black",
                        minWidth: 170,
                        width: "100%",
                        py: 0.5,
                      }}
                    >
                      <IconeUrgencia
                        urgency={item.urgencia}
                        customIconColors={{
                          critica: "#fff",
                          atencao: "#fff",
                          media: "#000",
                          alta: "#fff",
                          baixa: "#fff",
                          planejada: "#fff",
                          success: "#fff",
                        }}
                      />

                      <Typography
                        sx={{
                          marginRight: "8px",
                          fontSize: "14px",
                          marginLeft: 1,
                          color:
                            item.urgencia.descricao === "Média"
                              ? "black"
                              : "white",
                        }}
                      >
                        {item.urgencia.descricao}
                      </Typography>

                      {/* SLA */}
                      {item.urgencia.sla !== null &&
                        item.urgencia.sla !== 0 && (
                          <Tooltip
                            title="Horas sla"
                            sx={{ cursor: "default" }}
                            arrow
                          >
                            <Box
                              data-label="sla"
                              display="flex"
                              alignItems="center"
                              sx={{
                                color:
                                  item.urgencia.descricao === "Média"
                                    ? "black"
                                    : "white",
                                cursor: "default",
                              }}
                            >
                              <AccessTimeIcon
                                fontSize="small"
                                className="mr-1"
                              />
                              {converteParaHoras(item.urgencia.sla)}
                            </Box>
                          </Tooltip>
                        )}
                    </Box>

                    <Tooltip title="Hora atual" arrow>
                      <Box // Hora atual
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "default",

                          width: "100%",
                        }}
                      >
                        {item.minutosRestantes < 0 &&
                          new Date().toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </Box>
                    </Tooltip>

                    <Tooltip
                      arrow
                      title={
                        item.minutosRestantes < 0
                          ? "Tempo Ultrapassado"
                          : "Tempo Restante"
                      }
                    >
                      <Box // Tempo restante
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          cursor: "default",
                          width: "100%",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            color:
                              item?.minutosRestantes < 0 ? "red" : "#6C6767",
                            marginRight: item?.minutosRestantes < 0 ? 6 : 0,
                            marginLeft: item?.minutosRestantes > 0 ? 4 : 0,
                          }}
                        >
                          {converterParaHorasMinutos(item.minutosRestantes)}
                        </span>

                        <HistoryIcon
                          className={
                            item?.minutosRestantes < 0 ? "fa-blink" : ""
                          }
                          style={{
                            fontSize: "18px",
                            color:
                              item?.minutosRestantes < 0 ? "red" : "#6C6767",
                            marginLeft: item?.minutosRestantes >= 0 ? 13 : 0,
                          }}
                        />
                      </Box>
                    </Tooltip>

                    <Tooltip
                      title={item.codigoControleServicoTecnico?.nome}
                      arrow
                    >
                      <Avatar
                        src={item.codigoControleServicoTecnico?.imagem}
                        sx={{ width: 30, height: 30 }}
                      />
                    </Tooltip>

                    <Tooltip title="Excluir Chamado" arrow>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          visibility:
                            usuarioNome === item.userName
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="error"
                          disabled={usuarioNome !== item.userName}
                          onClick={() => openDelete(item.codigo)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Tooltip>

                    <Tooltip // Definir Sprint
                      title={"Definir Sprint "}
                      arrow
                      style={{ cursor: "default" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {item.aprovadoGerente === "S" && (
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) =>
                              handleSelectChamado(event, item)
                            }
                          >
                            <GridMoreVertIcon />
                          </IconButton>
                        )}
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          {dataSprint
                            .filter((its) => {
                              return its.status === "S" || its.status === "A";
                            })
                            .map((sp) => {
                              return (
                                <MenuItem
                                  key={sp.id}
                                  onClick={() => {
                                    handleSelectSprint(sp.id);
                                    setConfirmationModalOpen(true);
                                  }}
                                >
                                  <IconeStatusSprint task={sp} /> {sp.nome}
                                </MenuItem>
                              );
                            })}
                        </Menu>
                      </Box>
                    </Tooltip>
                  </CardContent>
                </Card>
              );
            })}
        </>
      )}

      {selectedItem && selectedSprint && (
        <BacklogDialog
          open={isConfirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          data={selectedItem}
          sprint={selectedSprint}
        />
      )}
      {showModal && (
        <SprintModal
          data={selectedTask.codigo}
          showModal={showModal}
          closeModal={handleSelectItem}
        />
      )}
      <Dialog open={openMsg} onClose={handleFecharAlert}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            "& .MuiAlert-icon": {
              color: "blue",
            },
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={deleteData?.codigo}
        deleteFunction={handleDeleteChamado}
        isLoadingDel={isLoading}
      />
    </>
  );
};
export default BacklogGrid;
