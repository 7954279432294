import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  useTheme,
  useMediaQuery,
  Box,
  Grid,
  Link,
  Stack,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import SprintModal from "../../Sprint/components/SprintModal";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";
import { MobileCard } from "./MobileCard";

const statusMapping = {
  PENDENTE: { label: "Pendente", background: "warning", color: "warning" },
  ANDAMENTO: { label: "Andamento", background: "primary", color: "#fff" },
  TESTE: { label: "Teste", background: "default", color: "#fff" },
  CONCLUIDO: { label: "Concluído", background: "success", color: "success" },
};

const DataGridTable = ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    setModalIsOpen(!modalIsOpen);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1350"));

  const columns = [
    {
      field: "codigo",
      headerName: "Chamado",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Link onClick={() => handleTaskSelect(params.value)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "titulo",
      headerName: "Título",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "userName",
      headerName: "Solicitante",
      headerAlign: "center",
      align: "center",
      flex: 1.7,
    },
    {
      field: "datcad",
      headerName: "Data Abertura",
      headerAlign: "center",
      align: "center",
      flex: 1.4,
      renderCell: (params) =>
        new Date(params.value).toLocaleDateString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        }),
    },
    {
      field: "tipo.descricao",
      headerName: "Setor",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (params) => params.row.tipo.descricao,
    },
    {
      field: "codigoControleServicoTecnico.usuario",
      headerName: "Técnico",
      headerAlign: "center",
      align: "center",
      flex: 2,
      sortable: true,
      valueGetter: (params) => {
        return (
          params.row.codigoControleServicoTecnico?.usuario || "Não atribuído"
        );
      },
      renderCell: (params) => {
        return (
          <Box
            sx={{
              backgroundColor: "#E8EAF6",
              borderRadius: 1,
              boxShadow: 1,
              color: "#283593",
              width: "100%",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "horasEstimadas",
      headerName: "Estimadas",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              backgroundColor: "#C8E6C9",
              borderRadius: 1,
              boxShadow: 1,
              color: "#1B5E20",
              width: "120%",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {converterParaHorasMinutos(params.row.horasEstimadas || "00")}
          </Box>
        );
      },
    },
    {
      field: "totalHorasTrabalhadas",
      headerName: "Trabalhadas",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              backgroundColor: "#FFE082",
              borderRadius: 1,
              boxShadow: 1,
              color: "#FF6F00",
              width: "100%",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {converterParaHorasMinutos(
              params.row.totalHorasTrabalhadas || "00"
            )}
          </Box>
        );
      },
    },
    {
      field: "totalHorasGastas",
      headerName: "Restantes",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              backgroundColor: "#83e9cd",
              borderRadius: 1,
              boxShadow: 1,
              color: "#00695C",
              width: "100%",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {converterParaHorasMinutos(params.row.totalHorasGastas || "00")}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      renderCell: (params) => {
        const status = statusMapping[params.value];
        return status ? (
          <Chip label={status.label} color={status.background} />
        ) : null;
      },
    },
  ];

  return (
    <Box sx={{ display: !isMobile && "flex", p: 1, mb: 1, width: "100%" }}>
      {isMobile &&
        data.map((task) => <MobileCard key={task.codigo} task={task} />)}

      {!isMobile && (
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          disableRowSelectionOnClick
          getRowId={(row) => row.codigo}
          rowsPerPageOptions={[10]}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Não há dados a serem exibidos.
              </Stack>
            ),
          }}
          autoPageSize
          autoHeight
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
        />
      )}

      {modalIsOpen && (
        <SprintModal
          showModal={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          data={selectedTask}
        />
      )}
    </Box>
  );
};

export default DataGridTable;
