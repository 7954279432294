import React, { useEffect, useState } from "react";

import api from "../../../services/api";

import "../../../components/controles/css/listUsuario.css";

import { FormLabel } from "react-bootstrap";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import UsuarioAtivo from "./UsuarioAtivo";
import { getControle } from "../../../services/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function UsuarioSolicitacoes({ idDemanda }) {
  const [selectedUsuario, setSelectedUsuario] = useState(0);

  const usuario = getControle();
  const queryClient = useQueryClient();

  const { data: listaUsuarioVinculado, isLoading: isLoadingLista } = useQuery(
    ["listaUsuarioVinculado", idDemanda],
    async () => {
      const response = await api.get(`vincular_usuario/demanda/${idDemanda}`);

      if (response.status == 200) {
        return response.data.records;
      }
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("listaUsuarioVinculado", data);
      },
    }
  );

  const { data: listUsuario, isLoading: isLoadingUsuario } = useQuery(
    ["listUsuario", usuario],
    async () => {
      const response = await api.get(`/usuarioDetalhe?codUsuario=${usuario}`);

      if (response.status == 200) {
        return response.data.records;
      }
    }
  );

  const { mutate: vincularUsuario } = useMutation(
    async (dadosJson) => {
      const response = await api.post(`vincular_usuario`, dadosJson);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["listaUsuarioVinculado", idDemanda]);
      },
    }
  );

  const handleChangeUsuario = async (event) => {
    let valor = event.target.value;
    setSelectedUsuario(valor);

    var dadosJson = {
      demanda: idDemanda,
      usuario: valor,
      status: 0,
    };

    await vincularUsuario(dadosJson);
  };

  if (isLoadingUsuario && isLoadingLista) {
    return <CircularProgress />;
  }

  // const handleChangeUsuario = async (event) => {
  //   let valor = event.target.value;
  //   setSelectedUsuario(valor);

  //   var dadosJson = {
  //     demanda: idDemanda,
  //     usuario: valor,
  //     status: 0,
  //   };

  //   await api.post(`vincular_usuario`, dadosJson);

  //   api.get(`vincular_usuario/demanda/` + idDemanda).then((response) => {
  //     setListaUsuarioVinculado(response.data.records);
  //   });
  // };

  return (
    <>
      <div className="form-group">
        <FormLabel>Inserir novo relacionado</FormLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select-label"
          label="Descricao"
          value={selectedUsuario}
          onChange={handleChangeUsuario}
          spacing={3}
          className="ls-select"
          style={{ width: "100%" }}
        >
          {listUsuario &&
            listUsuario.map((item) => (
              <MenuItem value={item.codUsuario}>{item.descricao}</MenuItem>
            ))}
        </Select>
      </div>

      <div id="plist" className="people-list">
        <ul className="list-unstyled chat-list mt-2 mb-0">
          {listaUsuarioVinculado &&
            listaUsuarioVinculado
              .filter((usuar) => {
                return usuar.usuario.codUsuario != getControle();
              })

              .map((item) => (
                <>
                  <div key={item.id} className="card card-widget widget-user-2">
                    <div className="widget-user-header bg-info">
                      <h6 className="">{item.usuario.descricao}</h6>
                    </div>
                    <div className="card-footer p-0">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <UsuarioAtivo
                            idDemanda={idDemanda}
                            idControle={item.usuario.codUsuario}
                            usuarioProcesso={item.id}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ))}
        </ul>
      </div>
    </>
  );
}
