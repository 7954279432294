import React, { useEffect, useState } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import api from "../../../services/api";
import { addZeroes, formatarDataBr } from "../../../utils/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getControle } from "../../../services/auth";
import ArquivosMensagens from "./ArquivosMensagens";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./grid.css";
import LoadingSpinner from "../../../components/controles/LoadingSpinner";
import Dropzone from "react-dropzone";

const VisualizarProcessos = ({ calls, id, opencard, setopencard }) => {
  const [dataMensagem, setDataMensagem] = useState([]);
  const [dataMensagemAnexo, setDataMensagemAnexo] = useState([]);
  const [dataHistorico, setDataHistorico] = useState([]);
  const [nome, setNome] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [codigoUsuario, setCodigoUsuario] = useState([]);
  const [usuarioData, setUsuarioData] = useState([]);
  const [dataUsuario, setDataUsuario] = useState([]);
  const [demanda, setDemanda] = useState([]);
  const [titulo, setTitulo] = useState([]);
  const [dataHoraLimite, setDataHoraLimite] = useState([]);
  const [dataAbertura, setDataAbertura] = useState([]);
  const [dataFimPrazo, setDataFimPrazo] = useState([]);
  const [comentar, setComentar] = useState("");
  const [statusProcesso, setStatusProcesso] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState("");

  const [idProcesso, setIdProcesso] = useState([]);
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoadingForExistingMessage, setIsLoadingForExistingMessage] =
    useState({
      id: null,
      isLoading: false,
    });

  const [dataLimiteProcesso, setdataLimiteProcesso] = useState(
    dayjs(new Date()).add(10, "day")
  );

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("lg");

  const [openHistory, setOpenHistory] = useState(false);

  const [content, setContent] = useState("");

  const [files, setFiles] = useState([]);
  const timestamp = new Date().getTime();
  const [loading, setLoading] = useState(true);

  const handleOpenCard = (opencard) => {
    setopencard(!opencard);
  };

  const getHistorico = async () => {
    await api
      .get(`demanda_historico/demanda/` + id + `?timestamp=${timestamp}`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => {
        setDataHistorico(response.data.records);
      });
  };

  const getUsuario = async () => {
    await api.get(`vincular_usuario/demanda/` + id).then((response) => {
      setUsuarioData(response.data.records);
      setDataUsuario(response.data.records);
    });
  };

  const getSolicitacao = async () => {
    await api
      .get(`/demanda_processos/completo/${calls}`)
      .then((response) => {
        setNome(response.data.records[0].solicitante.nome);
        setDemanda(response.data.records[0].id);
        setTitulo(response.data.records[0].titulo);
        setDataAbertura(response.data.records[0].dataAbertura);
        setDataFimPrazo(response.data.records[0].dataFimPrazo);
        setContent(response.data.records[0].descricao);
        setStatus(response.data.records[0].status);

        /**PROCESSOS* */
        setUsuario(
          response.data.records[0].processos.usuario.usuario.descricao
        );
        setCodigoUsuario(
          response.data.records[0].processos.usuario.usuario.codUsuario
        );
        setDataHoraLimite(response.data.records[0].processos.dataHoraLimite);
        setStatusProcesso(response.data.records[0].processos.tipoStatus);
        setdataLimiteProcesso(
          response.data.records[0].processos.dataHoraLimite
        );
        setIdProcesso(response.data.records[0].processos.id);
        /**MENSAGEM* */

        setDataMensagem(response.data.records[0].processos.mensagens);

        setDataMensagemAnexo(response.data.records[0].processos.mensagens);
      })
      .catch((error) => {
        console.error("Erro ao listar ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  const valorIDUsuario = dataUsuario
    .filter((usuar) => {
      return usuar.usuario.codUsuario == getControle();
    })
    .map((it) => {
      return it.id;
    });

  const getRefreshComments = async () => {
    const response = await api.get(`/demanda_processos/completo/${calls}`);
    setDataMensagem(response.data.records[0].processos.mensagens);
    setDataMensagemAnexo(response.data.records[0].processos.mensagens);
  };

  console.log(dataFimPrazo);

  const sendCommentAndFile = async () => {
    try {
      setIsLoadingRequest(true);
      const commentResponse = await api.post("demanda_mensagem/", {
        demanda: parseInt(id),
        processos: calls,
        descricao: comentar,
        dataHora: new Date().toISOString(),
        solicitante: parseInt(valorIDUsuario),
        status: 0,
        statusLeitura: 0,
      });

      if (commentResponse.status === 200 || commentResponse.status === 201) {
        const uploadPromises = files.map(async (file, index) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("idMensagem", commentResponse.data.records[0].id);
          formData.append("posicao", index + 1);

          await api.post("anexo_mensagem/enviar-arquivo", formData);
        });

        await Promise.all(uploadPromises);

        setFiles([]);
        setComentar("");

        await getRefreshComments();
      }
    } catch (err) {
      console.log(err);
      setTipoDialog("error");
      setCorDialog("error");
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const addFileToExistingMessage = async (idMensagem, file, index) => {
    try {
      setIsLoadingRequest(true);
      setIsLoadingForExistingMessage({
        id: idMensagem,
        isLoading: true,
      });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("idMensagem", idMensagem);
      formData.append("posicao", index + 1);

      console.log(idMensagem, "id da msg");

      const response = await api.post(
        "anexo_mensagem/enviar-arquivo",
        formData
      );
      console.log("response", response);
      await getRefreshComments();
    } catch (err) {
      console.log(err);
      setTipoDialog("error");
      setCorDialog("error");
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
    } finally {
      setIsLoadingRequest(false);
      setIsLoadingForExistingMessage({
        id: idMensagem,
        isLoading: false,
      });
    }
  };

  const addCommentToDemand = async (comment) => {
    try {
      setIsLoadingRequest(true);

      const response = await api.post("demanda_mensagem/", {
        demanda: parseInt(id),
        processos: calls,
        descricao: comment,
        dataHora: new Date().toISOString(),
        solicitante: parseInt(valorIDUsuario),
        status: 0,
        statusLeitura: 0,
      });

      if (response.status === 200 || response.status === 201) {
        setComentar("");
        await getRefreshComments();
      }
    } catch (err) {
      console.log(err);
      setTipoDialog("error");
      setCorDialog("error");
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleInativarMSG = async (idExcluir) => {
    let id = idExcluir;
    var dadosJson = {
      status: 1,
    };
    try {
      await api.put(`demanda_mensagem/` + id, dadosJson);

      setMensagemDialog("Mensagem Inativada!");
      setTipoDialog("warning");
      setCorDialog("warning");
      setOpenMsg(!openMsg);
      setComentar("");

      getRefreshComments();
    } catch (err) {
      setMensagemDialog("Houve uma problema com sua ação de inativação!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSolicitacao();
    getHistorico();
    getUsuario();

    setIsLoading(false);
  }, []);

  const dataAdd = (event) => {
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear(); // aparece ano 2023
    let dataAtual = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    const dataString = event;
    const dataObj = new Date(dataString);
    const atual = dataAtual;

    const d1 = dataHoraLimite;
    const d2 = atual;
    const diffInMs = new Date(d1) - new Date(d2);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    let dias = diffInDays.toFixed(0);

    if (statusProcesso == "AGUARDANDO" && status != 1) {
      return (
        <b
          className="card"
          style={{ fontSize: 16, color: "red", fontFamily: "Roboto" }}
        >
          Definir a data !
        </b>
      );
    } else if (statusProcesso == "AGUARDANDO" && status == 1) {
      return (
        <b
          className="card"
          style={{ fontSize: 16, color: "red", fontFamily: "Roboto" }}
        >
          Concluído !
        </b>
      );
    } else if (statusProcesso == "CONCLUIDO") {
      return (
        <b
          className="card"
          style={{ fontSize: 16, color: "Blue", fontFamily: "Roboto" }}
        >
          Concluído
        </b>
      );
    } else if (statusProcesso == "ANDAMENTO") {
      return <b>Data Limite</b>;
    } else if (statusProcesso == "INATIVO") {
      return (
        <b
          className="card"
          style={{ fontSize: 16, color: "brown", fontFamily: "Roboto" }}
        >
          Finalizado!
        </b>
      );
    } else if (dias < 0) {
      return (
        <b
          className="card"
          style={{ fontSize: 16, color: "red", fontFamily: "Roboto" }}
        >
          Prazo Vencido!
        </b>
      );
    } else {
      return <b>Data Limite</b>;
    }
  };

  const alterData = async (event) => {
    const dataObj = dayjs(event).endOf("day").format("YYYY-MM-DD HH:mm:ss");
    const dataLimiteProcesso = dayjs(dataFimPrazo.slice(0, 10))
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");

    if (dataObj > dataLimiteProcesso) {
      showErrorDialog("Data Limite não pode ser superior a data de conclusão");
      getSolicitacao();
      return;
    }

    const upJson = {
      id: calls,
      dataHoraLimite: event,
      tipoStatus: "ANDAMENTO",
    };

    try {
      if (status === 0) {
        if (dataObj <= dataFimPrazo) {
          await updateDataLimite(upJson);
          showSuccessDialog("A Data Limite para entrega foi alterada");
        } else {
          showErrorDialog(
            `Processo em andamento, mas data não pode ser superior á data da conclusão: ${formatarDataBr(
              dataFimPrazo
            )}.`
          );
        }
      } else {
        await updateDataLimite(upJson);
        showSuccessDialog("A Data Limite para entrega foi alterada");
      }
    } catch (err) {
      console.error(err);
      showErrorDialog("Houve um problema ao inserir a Mensagem");
    }
  };

  const showErrorDialog = (message) => {
    setMensagemDialog(message);
    setTipoDialog("error");
    setCorDialog("error");
    setOpenMsg(true);
  };

  const showSuccessDialog = (message) => {
    setMensagemDialog(message);
    setTipoDialog("success");
    setCorDialog("success");
    setOpenMsg(true);
    setStatusProcesso("ANDAMENTO");
    setdataLimiteProcesso(event);
  };

  const updateDataLimite = async (data) => {
    const response = await api.put(`/demanda_processos/` + calls, data);

    if (response.status === 200) {
      getSolicitacao();
    }
  };

  const tipoLista = async (event) => {
    let rel = event.target.value;
    var upJson = {
      id: calls,
      tipoStatus: rel,
    };

    try {
      await api.put(`/demanda_processos/` + calls, upJson);
      getSolicitacao();
      setStatusProcesso(rel);
      setMensagemDialog("O Status do Processo foi alterado!");
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * AUTOMATIZA O STATUS LEITURA
   * @param {*} _idMensagem // ID DA MENSAGEM
   * @param {*} _usuarioCorrente // USUARIO DO CARD
   */
  const atualizaStatusMensagemLida = async (_idMensagem, _usuarioCorrente) => {
    let id = _idMensagem;

    var dadosJson = {
      statusLeitura: 1,
    };

    if (_usuarioCorrente == getControle()) {
      // await api.put(`/demanda_mensagem/` + id, dadosJson);
    }
  };

  const StatusProcesso = (statusProcesso) => {
    if (statusProcesso == "AGUARDANDO") {
      return (
        <span
          style={{
            fontSize: 18,
            color: "#290738",
          }}
        >
          Aguardando
        </span>
      );
    } else if (statusProcesso == "ANDAMENTO") {
      return (
        <span
          style={{
            fontSize: 18,
            color: "#2f7315",
          }}
        >
          Andamento
        </span>
      );
    } else if (statusProcesso == "CONCLUÍDO") {
      return (
        <span
          style={{
            fontSize: 18,
            color: "#0244cc",
          }}
        >
          Concluído
        </span>
      );
    }
  };

  const StatusMsg = (ParMsg) => {
    if (ParMsg === 0) {
      return "fas fa-envelope bg-primary";
    } else if (ParMsg === 1) {
      return "fas fa-clock bg-warning";
    } else {
      return "fas fa-envelope bg-primary";
    }
  };

  const confirmarInatividade = async (event) => {
    let id = event.target.value;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              open={true}
              onClose={onClose}
              aria-labelledby="customized-dialog-title"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle id="customized-dialog-title" onClose={onClose}>
                Confirmar Inatividade: Mensagem {id}
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  Esteja ciente que ao inativar o seu comentário, este
                  continuará visível, porém sem validade na discussão.
                </Typography>
                <Typography gutterBottom>
                  Este procedimento é irreversível!
                </Typography>
                <Typography gutterBottom>
                  Deseja realmente desativar seu comentário?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    handleInativarMSG(id);
                    onClose();
                  }}
                  color="primary"
                >
                  SIM
                </Button>
                <Button autoFocus onClick={onClose} color="primary">
                  NÃO
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    });
  };

  return (
    <Grid>
      <>
        <div
          className="card-header"
          style={{ backgroundColor: "#304056", borderRadius: "7px" }}
        >
          <form className="form-horizontal">
            <div className="row col-md-12 col-md-12">
              <div className="col-md-1 col-sm-1">
                <h6
                  className="text-left"
                  style={{ fontSize: 16, color: "white" }}
                >
                  <b>{addZeroes(demanda, 8)}</b>
                </h6>
              </div>
              <div className="col-md-4 col-sm-4">
                <h6
                  className="text-left, ml-2"
                  style={{ fontSize: 16, color: "white" }}
                >
                  Título: {titulo}
                </h6>
              </div>

              <div className="col-md-3 col-sm-3">
                <h6
                  className="float-left"
                  style={{ fontSize: 16, color: "white" }}
                >
                  Abertura: {formatarDataBr(dataAbertura)}
                </h6>
              </div>

              <div className="col-md-3 col-sm-2">
                <h6
                  className="float-left"
                  style={{ fontSize: 16, color: "white" }}
                >
                  Conclusão: {formatarDataBr(dataFimPrazo)}
                </h6>
              </div>
              <div className="col-md-1 col-sm-2">
                <h5 className="float-right">
                  <Button
                    onClick={handleClickOpenHistory}
                    style={{ fontSize: 23 }}
                  >
                    <i class="far fa-file-alt" style={{ color: "#ffff" }}></i>
                  </Button>
                </h5>
              </div>
            </div>
          </form>
        </div>

        <br />

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="card card-outline card-info">
              <div className="card-body">
                <div className="col-9 col-md-6">
                  <div className="user-block">
                    <h5>
                      {usuario} - Processo nº {idProcesso}
                    </h5>

                    <div className="input-group input-group-sm">
                      {(() => {
                        if (
                          !isLoading &&
                          codigoUsuario == getControle() &&
                          dataHoraLimite != null &&
                          status != 1
                        ) {
                          return (
                            <FormControl>
                              <RadioGroup
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 18,
                                  },
                                }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={statusProcesso}
                                onChange={tipoLista}
                              >
                                <FormControlLabel
                                  value="ANDAMENTO"
                                  control={<Radio />}
                                  label="Andamento"
                                  sx={{
                                    color: "#2f7315",
                                  }}
                                />

                                <FormControlLabel
                                  value="CONCLUIDO"
                                  control={<Radio />}
                                  label="Concluído"
                                  sx={{
                                    color: "#0244cc",
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          );
                        } else {
                          return StatusProcesso(statusProcesso);
                        }
                      })()}
                    </div>
                  </div>
                </div>

                <div
                  className="col-2 card-tools float-right"
                  style={{ textAlign: "center" }}
                >
                  <p>{dataAdd()}</p>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    {(() => {
                      if (
                        !isLoading &&
                        codigoUsuario == getControle() &&
                        dataLimiteProcesso == null &&
                        status != 1 &&
                        statusProcesso != "INATIVO"
                      ) {
                        return (
                          <div
                            className="input-group date"
                            data-provide="datepicker"
                          >
                            <DatePicker
                              className="form-control"
                              value={dataHoraLimite || null}
                              minDate={dataAbertura}
                              maxDate={dataFimPrazo.slice(0, 10)}
                              onChange={(newValue) => {
                                alterData(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </div>
                        );
                      } else if (statusProcesso == "INATIVO") {
                        return (
                          <p style={{ color: "red", fontFamily: "Roboto" }}>
                            Processo Finalizado!
                          </p>
                        );
                      } else if (dataLimiteProcesso == null && status == 1) {
                        return (
                          <p style={{ color: "red", fontFamily: "Roboto" }}>
                            Processo Finalizado!
                          </p>
                        );
                      } else if (dataLimiteProcesso == null && status != 1) {
                        return (
                          <p style={{ color: "red", fontFamily: "Roboto" }}>
                            Aguardando usuário!
                          </p>
                        );
                      } else {
                        return (() => {
                          if (statusProcesso == "CONCLUIDO") {
                            return (
                              <div>
                                <span>
                                  {formatarDataBr(dataLimiteProcesso)}
                                </span>
                              </div>
                            );
                          } else if (
                            !isLoading &&
                            codigoUsuario == getControle() &&
                            status != 1
                          ) {
                            return (
                              <div
                                className="input-group date"
                                data-provide="datepicker"
                              >
                                <DatePicker
                                  className="form-control"
                                  value={dataLimiteProcesso}
                                  minDate={dataAbertura}
                                  maxDate={dataFimPrazo.slice(0, 10)}
                                  onChange={(newValue) => {
                                    setdataLimiteProcesso(newValue);
                                    alterData(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      label="Size"
                                      id="outlined-size-small"
                                      defaultValue="Small"
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                />
                              </div>
                            );
                          } else {
                            return (
                              codigoUsuario != getControle() && (
                                <div>
                                  <span>
                                    {formatarDataBr(dataLimiteProcesso)}
                                  </span>
                                </div>
                              )
                            );
                          }
                        })();
                      }
                    })()}
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <div className="card card-outline card-info">
              <div className="card-body">
                <div className="col-12">
                  <div className="input-group input-group-sm mb-0">
                    {(() => {
                      if (
                        statusProcesso != "CONCLUIDO" &&
                        codigoUsuario != getControle() &&
                        status != 1 &&
                        statusProcesso != "INATIVO"
                      ) {
                        return (
                          <>
                            <textarea
                              className="form-control form-control-sm"
                              value={comentar}
                              onChange={(e) => setComentar(e.target.value)}
                              placeholder="Comentar... (Mínimo de 10 caracteres)"
                              defaultValue={comentar}
                            />

                            <div className="input-group-append ml-2">
                              <button
                                onClick={() => addCommentToDemand(comentar)}
                                type="submit"
                                disabled={
                                  isLoadingRequest || comentar.length < 10
                                }
                                className="btn btn-info"
                              >
                                Enviar
                              </button>
                            </div>
                          </>
                        );
                      } else if (
                        statusProcesso != "CONCLUIDO" &&
                        dataLimiteProcesso != null &&
                        status != 1 &&
                        statusProcesso != "INATIVO"
                      ) {
                        return (
                          <>
                            <div class="container themed-container text-center">
                              <div className="input-group input-group-sm">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Comentar... (Mínimo de 10 caracteres)"
                                  value={comentar}
                                  onChange={(e) => setComentar(e.target.value)}
                                  defaultValue={comentar}
                                />
                                <span className="input-group-append">
                                  <button
                                    type="submit"
                                    onClick={() => sendCommentAndFile()}
                                    className="btn btn-info btn-flat"
                                    disabled={
                                      isLoadingRequest || comentar.length < 10
                                    }
                                  >
                                    {isLoadingRequest
                                      ? "Salvando..."
                                      : "Enviar dados"}
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div class="container themed-container text-center"></div>

                            <div className="text-secondary col-12 px-2 py-2 text-center">
                              <div className="col-lg-12 mx-auto">
                                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                  <Dropzone
                                    onDrop={handleDrop}
                                    multiple
                                    disabled={isLoadingRequest}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        className="dz-message dropzone"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <i class="material-icons text-muted"></i>
                                        Arraste &amp; solte o arquivo nesta
                                        área, ou clique para selecionar .
                                        {files.length > 0 && (
                                          <div>
                                            <h2>Arquivo(s)</h2>
                                            <ul>
                                              {files.map((file, index) => (
                                                <li key={index}>{file.name}</li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </Dropzone>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      } else {
                        <>
                          <div
                            className="form-control form-control-sm"
                            placeholder=""
                          />
                          <div className="input-group-append">
                            <div className="btn btn-info"></div>
                            <div className="btn btn-info input-group-text"></div>
                          </div>
                        </>;
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="tab-content">
                      <div className="tab-pane active">
                        <div className="timeline timeline-inverse">
                          {isLoading ? (
                            <LoadingSpinner />
                          ) : dataMensagem.length == 0 ? (
                            " "
                          ) : (
                            (() => {
                              if (dataMensagem.length > 0) {
                                let ownerMsg = false;

                                return dataMensagem
                                  .sort((a, b) => b.id - a.id)
                                  .map((itMsg, index) => (
                                    <div>
                                      <i className={StatusMsg(itMsg.status)} />

                                      <div className="timeline-item">
                                        <span className="time">
                                          <i className="far fa-clock" />{" "}
                                          {new Date(
                                            itMsg.dataHora
                                          ).toLocaleString("pt-BR")}
                                        </span>
                                        <h3 className="timeline-header">
                                          <span>
                                            Mensagem nº{" "}
                                            {itMsg.id +
                                              " - " +
                                              itMsg.solicitante?.usuario
                                                .descricao}
                                          </span>
                                        </h3>
                                        <div
                                          className="timeline-body"
                                          style={{
                                            fontSize: 16,
                                            textAlign: "justify",
                                            color: "#505050",
                                          }}
                                        >
                                          {itMsg.descricao}
                                        </div>
                                        {(() => {
                                          if (itMsg.status == 0) {
                                            return (
                                              <div className="timeline-footer text-right">
                                                {(() => {
                                                  ownerMsg = false;
                                                  if (
                                                    itMsg.solicitante?.usuario
                                                      .codUsuario ==
                                                      getControle() &&
                                                    statusProcesso !=
                                                      "CONCLUIDO" &&
                                                    status != 1
                                                  ) {
                                                    ownerMsg = true;

                                                    return (
                                                      <>
                                                        <label
                                                          htmlFor={itMsg.id}
                                                          className="btn btn-success btn-sm mb-2 mt-2 mr-2"
                                                          style={{
                                                            textAlign: "start",
                                                          }}
                                                        >
                                                          <i className="fa fa-paperclip mr-2" />
                                                          {isLoadingForExistingMessage.isLoading &&
                                                          itMsg.id ===
                                                            isLoadingForExistingMessage.id
                                                            ? "Aguarde..."
                                                            : "Anexar"}
                                                        </label>
                                                        <input
                                                          type="file"
                                                          disabled={
                                                            isLoadingRequest
                                                          }
                                                          id={itMsg.id}
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          onChange={(event) =>
                                                            addFileToExistingMessage(
                                                              itMsg.id,
                                                              event.target
                                                                .files[0],
                                                              index
                                                            )
                                                          }
                                                        />

                                                        <button
                                                          value={itMsg.id}
                                                          onClick={
                                                            confirmarInatividade
                                                          }
                                                          className="btn btn-danger btn-sm"
                                                        >
                                                          {" "}
                                                          <i className="fa fa-minus-square mr-2" />{" "}
                                                          Inativar Comentário
                                                        </button>
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="info-box-content ">
                                                <div
                                                  className="info-box-text text-center text-muted mt-2"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span>
                                                    Este comentário foi
                                                    inativado pelo usuário!
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }
                                        })()}
                                        <div className="content">
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="card-box">
                                                  <div className="row">
                                                    {isLoadingForExistingMessage.isLoading &&
                                                    itMsg.id ===
                                                      isLoadingForExistingMessage.id ? (
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          width: "100%",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <CircularProgress />
                                                      </Box>
                                                    ) : (
                                                      <ArquivosMensagens
                                                        id_msg={itMsg.id}
                                                        userOwner={ownerMsg}
                                                        msgst={itMsg.status}
                                                        refresh={
                                                          getRefreshComments
                                                        }
                                                        dataMensagemAnexo={
                                                          itMsg.anexos
                                                        }
                                                      />
                                                    )}

                                                    {/* {(() => {
                                                  if (progress >= 0) {
                                                    return (
                                                      <div className="row">
                                                        <div className="col-sm-12">
                                                          {progress && (
                                                            <ProgressBar
                                                              now={progress}
                                                              label={`${progress}%`}
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  } else if (progress == 100) {
                                                    return "";
                                                  } else {
                                                    return "";
                                                  }
                                                })()} */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ));
                              }
                              /* else {
                                return (
                                  <div>
                                    <i className="fas fa-pen bg-danger" />
                                    <div
                                      className="timeline-item"
                                      style={{ textAlign: "center" }}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          fontSize: 17,
                                          fontFamily: "Roboto",
                                          color: "#dc3545",
                                        }}
                                      >
                                        Não há comentários para este processo!
                                      </p>
                                    </div>
                                  </div>
                                );
                              } */
                            })()
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className=" ">
              {usuarioData.map((item) => (
                <div
                  key={item.id}
                  className="card card-outline card-info col-12"
                >
                  <div className="col">
                    <div className="text-left align-middle">
                      <span
                        className="username text-muted text-bold"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        <i className="far fa-user mr-2" />
                        {item.usuario.descricao}
                      </span>
                    </div>
                    <div className="text-left text-muted align-middle ml-2">
                      <span
                        className="username text-bold"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {item.usuario.email}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="input-group input-group-sm mb-0 exit-button-container"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={handleOpenCard}
            type="button"
            className="btn btn-danger ml-2"
          >
            Sair
          </button>
        </div>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={openHistory}
          onClose={handleCloseHistory}
        >
          <DialogTitle>Histórico</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Histórico de Alteração pelo solicitante da Demanda.
            </DialogContentText>

            <div className="row">
              <div className="col-12">
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{ width: "15%", textAlign: "left" }}
                        scope="col"
                      >
                        Alteração{" "}
                      </th>
                      <th
                        style={{ width: "10%", textAlign: "left" }}
                        scope="col"
                      >
                        Demanda{" "}
                      </th>
                      <th style={{ textAlign: "left" }} scope="col">
                        Titulo{" "}
                      </th>
                      <th style={{ textAlign: "left" }} scope="col">
                        Categoria{" "}
                      </th>
                      <th style={{ textAlign: "left" }} scope="col">
                        Data Limite{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataHistorico.map((itHistorico) => {
                      return (
                        <tr key={itHistorico.id}>
                          <td
                            style={{ textAlign: "left" }}
                            data-label="Nº Pat. "
                          >
                            {" "}
                            {formatarDataBr(
                              itHistorico.dataHoraAlteracao,
                              "DataHora"
                            )}
                          </td>
                          <td
                            style={{ textAlign: "left" }}
                            data-label="Nº Pat. Antigo "
                          >
                            {" "}
                            {itHistorico.demanda}
                          </td>
                          <td
                            style={{ textAlign: "left" }}
                            data-label="Nº Pat. Antigo "
                          >
                            {" "}
                            {itHistorico.titulo}
                          </td>
                          <td
                            style={{ textAlign: "left" }}
                            data-label="Descrição "
                          >
                            {" "}
                            {itHistorico.eventos.rotina}
                          </td>
                          <td
                            style={{ textAlign: "left" }}
                            data-label="Data Limite "
                          >
                            {itHistorico.dataFimPrazo}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseHistory}>Fechar</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openMsg} onClose={handleClick}>
          <Alert
            severity={tipoDialog}
            color={corDialog}
            role="button"
            onClose={() => {
              setOpenMsg(false);
            }}
            sx={{
              "& .MuiAlert-icon": {
                color: "blue",
              },
            }}
          >
            <AlertTitle></AlertTitle>
            {mensagemDialog}
          </Alert>
        </Dialog>
      </>
    </Grid>
  );
};

export default VisualizarProcessos;
