import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconAnexos } from "./IconAnexos";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpenTwoTone";
import { formatarDataBaseDados } from "../../../../utils/utils";
import "./style.css";
import { DescQuill } from "../../../../components/EditorToolbar";
import 'react-quill/dist/quill.snow.css';
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";


export const MsgUsuario = ({
  mensagem,
  mensagemUsuario,
  editingMessageId,
  dataUsuario,
  handleEditClick,
  openDelete,
  handleSalvarEdit,
  handleCancelClick,
  handleChangeMensagem,
}) => { 
  
  return (
   
    <>
      {mensagemUsuario &&
        mensagemUsuario
          .sort((a, b) => {
            if (b.id > a.id) return 1;
            if (b.id < a.id) return -1;
            return 0;
          })
          .map((itMsg) => (
            <div
              className="card"
              style={
                itMsg.publico === "S"
                  ? { border: "1px solid #85B9FF" }
                  : { borderColor: "1px solid #A1A1A1" }
              }
            >
              <div className="shadow card-body p-0">
                <ul className="products-list product-list-in-card pl-2 pr-2">
                  <li className="item">
                    <div className="product-img mt-3">
                      <Avatar src={itMsg.imagem} alt="Product Image" />
                    </div>
                    <div className="product-info">
                      <div className="mt-3">
                        <div className="d-flex justify-content-between">
                          <span
                            href="javascript:void(0)"
                            className="product-title"
                          >
                            {itMsg.userName}
                          </span>

                          <div>
                            <span className="product-title mr-1">
                              {formatarDataBaseDados(itMsg.datcad)}
                            </span>

                            <div className="btn-group">
                              {dataUsuario === itMsg.userName && (
                                <div>
                                  <button
                                    type="btn btn-info"
                                    className="btn  btn-sm "
                                    data-toggle="dropdown"
                                    data-offset="-52"
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </button>
                                  <div className="dropdown-menu" role="menu">
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleEditClick(
                                          itMsg.id,
                                          itMsg.mensagem
                                        )
                                      }
                                    >
                                      Editar
                                    </a>
                                    <a
                                      onClick={() => openDelete(itMsg)}
                                      href="#"
                                      className="dropdown-item"
                                    >
                                      Excluir
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-100">
                          {editingMessageId === itMsg.id ? (
                            <div className="col-md-12">
                              <DescQuill
                                id={itMsg.id}
                                value={mensagem}
                                onChange={handleChangeMensagem}
                              />
                        
                              <div className="mt-2">
                                <Button
                                  className="mr-2"
                                  variant="contained"
                                  onClick={(e) => handleSalvarEdit(e, itMsg.id)}
                                >
                                  Salvar
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={handleCancelClick}
                                >
                                  Cancelar
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-12 w-100">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: itMsg.mensagem,
                                }}
                              />

                              <br />
                              <div className="mt-2" style={{ width: "40%" }}>
                                {itMsg.anexos.length > 0 && (
                                  <IconAnexos
                                    anexos={itMsg.anexos}
                                    key="anexos-msg"
                                  />
                                )}
                              </div>
                              {itMsg.publico === "S" ? (
                                <div class="d-flex justify-content-end align-items-end p-2">
                                  <Tooltip title="Público">
                                    <LockOpenIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                </div>
                              ) : (
                                <div class="d-flex justify-content-end align-items-end p-2">
                                  <Tooltip title="Privado">
                                    <LockIcon color="action" fontSize="small" />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}
    </>
  );
};
