import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import api from "../../../../services/api";
import Dropzone from "react-dropzone";
import { useSprintData } from "../../../../contexts/SprintDataContext";
import { getControle } from "../../../../services/auth";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ResponsiveDialog({
  open,
  onClose,
  responsavel,
  data,
  statusChamado,
  closeModal,
  anterior,
  refreshComentarios,
  idDoTecnico,
  dataUsuario,
  limparHoras,
  statusChamadoObject,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [horasTrabalhadas, setHorasTrabalhadas] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [files, setFiles] = useState([]);
  const [dataConcluidos, setDataConcluidos] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const usuarioControle = getControle();
  const [usuario, setUsuario] = useState([]);
  const [horas, setHoras] = useState(0);
  const [minutos, setMinutos] = useState(0);

  const { selectedSprint: selectedSprintId } = useSprintData();
  const { sprintId } = useParams();

  const queryClient = useQueryClient();

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = async (id) => {
    setLoading(true);

    const uploadPromises = files.map(async (file) => {
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("idComentario", id);
      fileFormData.append("controle", data);

      return await api.post(
        `controle-servico-mensagens/enviar-arquivo`,
        fileFormData,
        {
          mode: "no-cors",
          method: "POST",
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );
    });

    try {
      if (files.length > 0) {
        await Promise.all(uploadPromises);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
      refreshComentarios();
    }
  };

  const handleSalvar = async (e) => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let dataHora = today.toISOString();

    var dadosJson = {
      mensagem: mensagem,
      chamadoId: data,
      userName:
        usuarioControle == idDoTecnico ? responsavel?.usuario : dataUsuario,
      tipo: 1,
      publico: statusChamado == "TESTE" ? "N" : "S",
      datcad: dataHora,
    };

    try {
      if (mensagem.length >= 10) {
        setLoading(true);

        await api.post(`controle-servico-mensagens/`, dadosJson).then((res) => {
          handleUpload(res.data.records[0].id);
        });

        queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
        queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
        queryClient.invalidateQueries("projeto-chamados");
      } else {
        setOpenMsg(!openMsg);
      }

      setTimeout(() => {
        setLoading(false);
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfirmation = async () => {
    try {
      setLoading(true);
      var dadosJson = {
        status: statusChamado,
        previous: anterior,
      };

      await handleSalvar();

      await api.put(`controle-servico/${data}`, dadosJson).then((response) => {
        setDataConcluidos(response.data.records);
      });

      queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
      queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
      queryClient.invalidateQueries("projeto-chamados");

      handleHoras();

      if (
        statusChamadoObject.previous === "TESTE" &&
        statusChamadoObject.current === "PENDENTE"
      ) {
        limparHoras();
      }

      setHorasTrabalhadas("");
      setMensagem("");
      setFiles([]);

      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    } finally {
      setLoading(false);

      refreshComentarios();
    }
    onClose();
  };

  const handleHoras = async (e) => {
    try {
      if (horasTrabalhadas.length > 0) {
        setOpenMsg(!openMsg);

        const [horas, minutos] = horasTrabalhadas.split(":").map(Number);
        const horaTrabalhada = `${horas.toString().padStart(2, "0")}.${minutos
          .toString()
          .padStart(2, "0")}`;

        var dadosJson = {
          chamado: data,
          codTecnico: responsavel?.codigo,
          tempo: horaTrabalhada,
        };

        await api.post(`controle-tempo/`, dadosJson);
      } else {
        setOpenMsg(!openMsg);
      }
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 4) {
      value = value.slice(-4);
    }

    value = value.padStart(4, "0");

    let formattedValue = `${value.slice(0, 2)}:${value.slice(2)}`;

    e.target.value = formattedValue;

    setHorasTrabalhadas(formattedValue);
  };

  const handleBlur = () => {
    let valor = horasTrabalhadas.replace(/[^0-9]/g, "");

    valor = valor.padStart(4, "0"); // Adiciona zeros à esquerda até que o valor tenha 4 dígitos

    let horas = valor.substring(0, 2);
    let minutos = valor.substring(2, 4);

    if (parseInt(minutos, 10) > 59) {
      minutos = "59";
    }

    const formatado = `${horas}:${minutos}`;

    if (formatado !== "00:00") {
      setHorasTrabalhadas(formatado);
      const [h, m] = formatado.split(":").map(Number);
      setHoras(h);
      setMinutos(m);
    } else {
      setHorasTrabalhadas("");
      setHoras(0);
      setMinutos(1);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      //onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <Box className="p-2">
        <DialogTitle
          className=""
          id="responsive-dialog-title"
          style={{ fontSize: 30 }}
        >
          Resumo
        </DialogTitle>

        <div className="col-12 ">
          <div className="row">
            <div className="col-lg-4 mb-3 d-flex flex-wrap justify-content-center">
              <span>
                <Avatar
                  alt="Nome do Responsável"
                  src={responsavel?.imagem}
                  sx={{ width: 150, height: 150 }}
                />
              </span>

              <span className="text-center">
                {responsavel?.nome} <br /> {responsavel?.email}
              </span>
            </div>

            <div className="col-lg-8 text-right">
              <textarea
                className="form-control mb-5"
                id="Resumo"
                placeholder={
                  (anterior == "TESTE" || anterior == "CONCLUIDO") &&
                  statusChamado == "PENDENTE"
                    ? "Justifique o motivo de estar retornando o chamado"
                    : "Insira o resumo do que foi realizado sobre a conclusão ..."
                }
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
              />

              {(() => {
                if (
                  statusChamado == "PENDENTE" &&
                  (anterior == "TESTE" || anterior == "CONCLUIDO")
                ) {
                  return "";
                } else if (statusChamado != "CONCLUIDO") {
                  return (
                    <>
                      {/* <DialogContentText className="mb-2">
                        Horas Trabalhadass
                      </DialogContentText> */}
                      <TextField
                        value={horasTrabalhadas}
                        id="outlined-number"
                        type="text"
                        size="small"
                        label="Horas Trabalhadas"
                        onKeyDown={(e) => {
                          if (
                            !/^[0-9]$/i.test(e.key) &&
                            e.key !== "Delete" &&
                            e.key !== "Backspace"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ width: 150, marginBottom: 4 }}
                        InputLabelProps={{ shrink: true }}
                        placeholder="00:00"
                        disabled={mensagem.trim().length < 10}
                      />
                    </>
                  );
                } else {
                  return "";
                }
              })()}

              <div>
                <Dropzone onDrop={handleDrop} multiple>
                  {({ getRootProps, getInputProps }) => (
                    <div className="dz-message dropzone" {...getRootProps()}>
                      {loading ? (
                        <CircularProgress size={40} />
                      ) : (
                        <>
                          <input {...getInputProps()} />
                          <i class="material-icons text-muted"></i>
                          Arraste &amp; solte o arquivo nesta área, ou clique
                          para selecionar .
                          {files.length > 0 && (
                            <div>
                              <h5>Arquivo(s)</h5>
                              <ul>
                                {files.map((file, index) => (
                                  <li key={index}>{file.name}</li>
                                ))}
                              </ul>
                            </div>
                          )}{" "}
                        </>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
              <DialogActions>
                <Box sx={{ flexGrow: 1 }}>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#0275d8", color: "#fff", mt: 3 }}
                      autoFocus
                      onClick={() => handleConfirmation()}
                      disabled={
                        anterior == "TESTE" || anterior == "CONCLUIDO"
                          ? mensagem.length < 10
                          : horasTrabalhadas.length == 0 ||
                            horasTrabalhadas == "00:00" ||
                            mensagem.length == 0 ||
                            horasTrabalhadas == ""
                      }
                    >
                      Salvar
                    </Button>
                  )}

                  <Button
                    className="ml-3"
                    variant="contained"
                    sx={{ backgroundColor: "#F44E40", color: "#fff", mt: 3 }}
                    autoFocus
                    onClick={() => {
                      closeModal();
                      onClose();
                    }}
                  >
                    Fechar
                  </Button>
                </Box>
              </DialogActions>
            </div>
          </div>
        </div>
      </Box>
    </Dialog>
  );
}
