import React, { useState, useEffect } from "react";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Avatar,
  FormGroup,
  TextareaAutosize,
  Box,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { getControle, login } from "../../../../services/auth";
import { AlertSuccess, AlertDanger } from "../../Projeto/Component/styles";
import api from "../../../../services/api";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { IconAnexos } from "../../Sprint/components/IconAnexos";
import { AnexosAbertura } from "./AnexosAbertura";
import dayjs from "dayjs";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { DescQuill } from "../../../../components/EditorToolbar";

export const ChamadoEditar = () => {
  const [dataUsuario, setDataUsuario] = useState([]);
  const [dataSolicitacao, setDataSolicitacao] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [horasEstimadas, setHorasEstimadas] = useState("");
  const [files, setFiles] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [titulo, setTitulo] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [horas, setHoras] = useState(0);
  const [minutos, setMinutos] = useState(0);
  const usuarioControle = getControle();

  // Filtros
  const [selectedGravidade, setSelectedGravidade] = useState([]);
  const [listGravidade, setListGravidade] = useState([]);

  const [responsavel, setResponsavel] = useState(null);
  const [filteredResponsavel, setFilteredResponsavel] = useState([]);

  const [selectedProjeto, setSelectedProjeto] = useState("");
  const [filteredProjeto, setFilteredProjeto] = useState([]);

  const [selectedSprint, setSelectedSprint] = useState(0);
  const [filteredSprint, setFilteredSprint] = useState([]);

  const [selectedClassificacao, setSelectedClassificacao] = useState([]);
  const [filteredClassificacao, setFilteredClassificacao] = useState([]);
  const dataAtual = new Date();
  const [userName, setUserName] = useState([]);

  const [selectedTipo, setSelectedTipo] = useState("");
  const [filteredTipo, setFilteredTipo] = useState([]);

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });
  let history = useHistory();

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getAnexos = async () => {
    await api.get(`controle-anexos/controle/${id}/anexos`).then((response) => {
      setAnexos(response.data.records);
    });
  };

  const excluirAnexo = async (img) => {
    try {
      setIsLoading(true);
      await api.delete(`/controle-anexos/${img}`);
    } catch (err) {
      console.log(err);
    } finally {
      getAnexos();
      setIsLoading(false);
    }
  };
  const formatarHoraEstimada = (hora) => {
    if (hora === null || hora === undefined) return "00:00";

    const [h, m] = hora.toString().split(".");
    const horas = String(h).padStart(2, "0");
    const minutos = m
      ? m.length === 1
        ? String(Number(m) * 10).padStart(2, "0")
        : m.padStart(2, "0")
      : "00";
    return `${horas}:${minutos}`;
  };

  useEffect(() => {
    const UserName = async () => {
      const response = await api.get(`controle-servico/${id}`);
      setTitulo(response.data.records[0].controleServico?.titulo);
      setDescricao(response.data.records[0].controleServico?.problema);
      setUserName(response.data.records[0].controleServico?.userName);
      setDataSolicitacao(response.data.records[0].controleServico?.datcad);
      const horaformat =
        response.data.records[0].controleServico?.horasEstimadas;

      setHorasEstimadas(formatarHoraEstimada(horaformat));
      setSelectedGravidade(
        response.data.records[0].controleServico?.urgencia?.id
      );
      setSelectedProjeto(response.data.records[0].controleServico?.projeto);
      setSelectedClassificacao(
        response.data.records[0].controleServico?.classificacao?.id
      );
      setResponsavel(
        response.data.records[0].controleServico?.codigoControleServicoTecnico
          ?.codigo
      );
      setSelectedTipo(response.data.records[0].controleServico?.tipo.id);
    };

    const getSolicitante = async () => {
      await api.get(`/usuarioDetalhe/` + usuarioControle).then((response) => {
        setDataUsuario(response.data.records[0].nome);
      });
    };

    const getGravidade = async () => {
      await api.get(`/controle-prioridade/`).then((response) => {
        setListGravidade(response.data.records);
      });
    };

    const getProjeto = async () => {
      await api
        .get(`cadastro-projetos?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredProjeto(response.data.records);
        });
    };

    const getSprint = async () => {
      await api
        .get(`controle-sprint?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredSprint(response.data.records);
        });
    };

    const getClassificacao = async () => {
      await api.get(`controle-classificacao`).then((response) => {
        setFilteredClassificacao(response.data.records);
      });
    };

    const getTipo = async () => {
      await api.get(`controle-tipo`).then((response) => {
        setFilteredTipo(response.data.records);
      });
    };

    const getResponsavel = async () => {
      await api
        .get(`tecnico?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredResponsavel(response.data.records);
        });
    };

    Promise.all([
      UserName(),
      getSolicitante(),
      getTipo(),
      getClassificacao(),
      getProjeto(),
      getSprint(),
      getResponsavel(),
      getGravidade(),
      getAnexos(),
    ]);
  }, []);

  const handleChangeGravidade = (event) => {
    let valor = event.target.value;
    setSelectedGravidade(valor);
  };

  const handleChangeProjeto = async (event) => {
    setSelectedProjeto(event.target.value);
  };

  const handleChangeSprint = async (event) => {
    setSelectedSprint(event.target.value);
  };

  const handleChangeClassificacao = async (event) => {
    setSelectedClassificacao(event.target.value);
  };

  const handleChangeTipo = async (event) => {
    setSelectedTipo(event.target.value);
  };

  const editChamado = async (e) => {
    e.preventDefault();

    try {
      if (dataSolicitacao > dataAtual) {
        setStatusRecord({
          type: "erro",
          mensagem:
            "A data da solicitação não deve ser maior que a data do dia !",
        });
        return;
      } else {
        if (id == 0) {
          const [horas, minutos] =
            horasEstimadas !== undefined || horasEstimadas !== null
              ? horasEstimadas.split(":").map(Number)
              : "00";
          const horaTrabalhada = `${horas.toString().padStart(2, "0")}.${minutos
            .toString()
            .padStart(2, "0")}`;
          const verificResponsavel =
            responsavel === undefined ? 9 : responsavel;
          setIsLoading(true);
          var dadosJson = {
            userName: dataUsuario,
            datcad: dataSolicitacao,
            codigoControleServicoTecnico: verificResponsavel,
            horasEstimadas:
              horasEstimadas == undefined ? horasEstimadas : horaTrabalhada,
            problema: descricao,
            titulo: titulo,
            status: "PENDENTE",
            aprovadoGerente: "S",
            projeto: selectedProjeto,
            urgencia: { id: selectedGravidade },
            tipoIncidente: 4,
            prioridadeScrum: 0,
            classificacao: { id: selectedClassificacao },
            sprintId: selectedSprint,
            tipo: { id: selectedTipo },
          };

          await api.post(`/controle-servico`, dadosJson).then((res) => {
            handleUpload(res.data.records[0].codigo);
          });

          setStatusRecord({
            type: "success",
            mensagem: "Novo Registro criado com sucesso",
          });
        } else {
          const [horas, minutos] =
            horasEstimadas !== undefined &&
            horasEstimadas !== null &&
            horasEstimadas !== ""
              ? horasEstimadas.split(":").map(Number)
              : "00";
          const horaTrabalhada = `${horas.toString().padStart(2, "0")}.${minutos
            .toString()
            .padStart(2, "0")}`;
          const verificResponsavel =
            responsavel === undefined ? 9 : responsavel;

          setIsLoading(true);

          var dadosJson = {
            codigo: id,
            datcad: dataSolicitacao,
            codigoControleServicoTecnico: verificResponsavel,
            horasEstimadas: horaTrabalhada,
            problema: descricao,
            titulo: titulo,
            status: "PENDENTE",
            aprovadoGerente: "S",
            projeto: selectedProjeto,
            urgencia: { id: selectedGravidade },
            tipoIncidente: 4,
            prioridadeScrum: 0,
            classificacao: { id: selectedClassificacao },
            sprintId: selectedSprint,
            tipo: { id: selectedTipo },
          };
       
          await api.put(`/controle-servico/${id}`, dadosJson).then((res) => {
            handleUpload(id);
          });

          setStatusRecord({
            type: "success",
            mensagem: "Registro atualizado com sucesso",
          });
        }
      }
      const redirecionar = setTimeout(() => {
        setIsLoading(false);
        history.push({
          pathname: "/atendimento",
        });
      }, 1000);

      return () => clearTimeout(redirecionar);
    } catch (erro) {
      console.log("erro:: ", erro);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = (idReg) => {
    files.forEach(async (file) => {
      const formData = new FormData();
      formData.append(`file`, file);
      formData.append("controle", idReg);

      try {
        await api.post(`/controle-anexos/enviar-arquivo`, formData, {
          mode: "no-cors",
          method: "POST",
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            type: "formData",
          },
          body: formData,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleContentChange = (newContent) => {
    setDescricao(newContent);
  };

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 4) {
      value = value.slice(-4);
    }

    value = value.padStart(4, "0");

    console.log(value);

    let formattedValue = `${value.slice(0, 2)}:${value.slice(2)}`;

    e.target.value = formattedValue;

    setHorasEstimadas(formattedValue);

    console.log(formattedValue);
  };

  const handleBlur = () => {
    let valor =
      horasEstimadas == null ? "" : horasEstimadas.replace(/[^0-9]/g, "");

    valor = valor.padEnd(4, "0");

    let horas = valor.substring(0, 2);
    let minutos = valor.substring(2, 4);

    if (parseInt(minutos, 10) > 59) {
      minutos = "59";
    }
    const formatado = `${horas}:${minutos}`;

    if (formatado !== "00:00") {
      setHorasEstimadas(formatado);
      const [h, m] = formatado.split(":").map(Number);
      setHoras(h);
      setMinutos(m);
    } else {
      setHorasEstimadas("");
      setHoras(0);
      setMinutos(1);
    }
  };

  return (
    <>
      <Box>
        <Card>
          <form className="form-horizontal" onSubmit={editChamado}>
            <CardContent>
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}
              </div>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: isMobile ? "1fr" : `0.4fr 0.7fr 1.2fr`,
                  mb: 3,
                  gap: 2,
                  padding: 2,
                  maxWidth: "100%",
                }}
              >
                <Card
                  sx={{
                    alignContent: "center",
                    textAlign: "center",
                    height: 55,
                  }}
                >
                  <Typography>Solicitante</Typography>
                  <Typography>{id == 0 ? dataUsuario : userName}</Typography>
                </Card>

                <FormControl sx={{ width: isMobile ? "100%" : 200 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <DatePicker
                      //className="d-flex justify-items-flex-end"
                      value={dataSolicitacao}
                      label="Solicitado em"
                      onChange={(newValue) => {
                        setDataSolicitacao(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField label="data Solicitacao" {...params} />
                      )}
                      dayOfWeekFormatter={(day) => {
                        const daysOfWeek = [
                          "Dom",
                          "Seg",
                          "Ter",
                          "Qua",
                          "Qui",
                          "Sex",
                          "Sab",
                        ];
                        return daysOfWeek[day];
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>

                <Box
                  display="grid"
                  gridTemplateColumns={isMobile ? "0.8fr 1.7fr" : "3.5fr 2fr"}
                  justifyContent="flex-end"
                >
                  <Box
                    display="flex"
                    sx={{
                      justifySelf: isMobile ? "left" : "end",
                      mr: isMobile ? 0 : 2,
                    }}
                  >
                    <FormControl sx={{ width: isMobile ? 120 : 130 }}>
                      <TextField
                        value={horasEstimadas}
                        id="outlined-number"
                        type="text"
                        label="Horas Estimadas"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ minWidth: 100, flex: "1 1 auto" }}
                        InputLabelProps={{ shrink: true }}
                        placeholder="00:00"
                      />
                    </FormControl>
                  </Box>
                  <Box
                    display="flex"
                    sx={{
                      justifySelf: isMobile ? "left" : "end",
                      width: "100%",
                      ml: isMobile ? 1 : 0,
                    }}
                  >
                    <FormControl
                      sx={{ width: isMobile ? 200 : 250, width: "100%" }}
                    >
                      <InputLabel
                        shrink={responsavel !== null ? true : false}
                        id="demo-controlled-open-select-label"
                      >
                        Responsável
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="responsavel"
                        value={responsavel || 9}
                        onChange={(e) => setResponsavel(e.target.value)}
                        label="Responsável"
                        notched={responsavel !== null ? true : false}
                        sx={{
                          minWidth: 100,
                          maxHeight: 60,
                        }}
                      >
                        {filteredResponsavel
                          .sort((a, b) => {
                            if (a.nome > b.nome) return 1;
                            if (a.nome < b.nome) return -1;
                            return 0;
                          })
                          .map((item) => (
                            <MenuItem key={item.codigo} value={item.codigo}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  className="mr-3"
                                  src={item.imagem}
                                  width={15}
                                  height={15}
                                />
                                {item.nome}
                              </Box>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: isMobile
                    ? "1fr"
                    : `1.2fr 1.2fr 1.6fr 1.6fr 1fr `,
                  mb: 3,
                  gap: 1,
                  maxWidth: "100%",
                  padding: 2,
                }}
              >
                <FormControl className="form-group" fullWidth>
                  <InputLabel id="gravidade">Gravidade</InputLabel>
                  <Select
                    id="gravidade"
                    label="Gravidade"
                    value={selectedGravidade}
                    onChange={handleChangeGravidade}
                    required
                  >
                    <MenuItem value="" disabled>
                      Selecionar
                    </MenuItem>
                    {listGravidade
                      .filter(
                        (grav) =>
                          grav.status == "1" && grav.descricao != "Todos"
                      )
                      .sort((a, b) => {
                        if (a.descricao > b.descricao) return 1;
                        if (a.descricao < b.descricao) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="projeto">Projeto</InputLabel>
                  <Select
                    label="Projeto"
                    value={selectedProjeto}
                    onChange={handleChangeProjeto}
                    labelId="projeto"
                    id="projeto"
                  >
                    <MenuItem value="">Selecionar</MenuItem>
                    {filteredProjeto.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="sprint">Sprint</InputLabel>
                  <Select
                    label="Sprint"
                    value={selectedSprint}
                    onChange={handleChangeSprint}
                    labelId="sprint"
                    id="sprint"
                  >
                    <MenuItem value={0}>Selecionar</MenuItem>
                    {filteredSprint
                      .filter((its) => {
                        return its.status == "S";
                      })
                      .sort((a, b) => {
                        if (a.nome > b.nome) return 1;
                        if (a.nome < b.nome) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl className="form-group" fullWidth>
                  <InputLabel id="classificacao">Classificação</InputLabel>
                  <Select
                    label="Classificação"
                    value={selectedClassificacao}
                    onChange={handleChangeClassificacao}
                    labelId="classificacao"
                    id="classificacao"
                    required
                  >
                    <MenuItem value="">Selecionar</MenuItem>
                    {filteredClassificacao
                      .sort((a, b) => {
                        if (a.descricao > b.descricao) return 1;
                        if (a.descricao < b.descricao) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.id}>{item.descricao}</MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl className="form-group" fullWidth>
                  <InputLabel id="tipo">Tipo</InputLabel>
                  <Select
                    label="Tipo"
                    value={selectedTipo}
                    onChange={handleChangeTipo}
                    required
                    labelId="tipo"
                    id="tipo"
                  >
                    <MenuItem value="">Selecionar</MenuItem>
                    {filteredTipo
                      .sort((a, b) => {
                        if (a.descricao > b.descricao) return 1;
                        if (a.descricao < b.descricao) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.id}>{item.descricao}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ padding: 2 }}>
                <FormGroup>
                  <InputLabel for="titulo">Título</InputLabel>
                  <input
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    type="titulo"
                    class="form-control"
                    id="titulo"
                    placeholder="Título..."
                    required="required"
                  />
                </FormGroup>
              </Box>
              <Box sx={{ padding: 2 }}>
                <FormGroup className="form-group">
                  <InputLabel>Descrição</InputLabel>

                  <DescQuill
                    id={4}
                    value={descricao}
                    onChange={handleContentChange}
                  />

                  <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mt-2">
                    <Dropzone onDrop={handleDrop} multiple={true}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="dz-message dropzone"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <i class="material-icons text-muted"></i>
                          Arraste &amp; solte o arquivo nesta área, ou clique
                          para selecionar .<h5>Arquivo(s)</h5>
                          {id != 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <IconAnexos
                                anexos={anexos}
                                onDelete={excluirAnexo}
                                iconButtonMostrar
                              />
                            </div>
                          )}
                          {files.length > 0 && (
                            <div>
                              <AnexosAbertura anexo={files} />
                              <ul>
                                {files.map((file, index) => (
                                  <>
                                    <li key={index}>{file.name}</li>
                                    <Link
                                      onClick={(e) =>
                                        setFiles("", e.stopPropagation())
                                      }
                                    >
                                      Excluir
                                    </Link>
                                  </>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </FormGroup>
              </Box>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{ display: "flex", justifyContent: "end", padding: 4 }}
            >
              {isLoading ? (
                <Button variant="contained" size="large">
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={
                    titulo === undefined ||
                    titulo === "" ||
                    titulo === null ||
                    descricao === undefined ||
                    descricao === "" ||
                    descricao === null ||
                    descricao === "<p><br></p>" ||
                    descricao ===
                      `<pre class="ql-syntax" spellcheck="false"></pre>`
                  }
                >
                  Salvar
                </Button>
              )}
            </CardActions>
          </form>
        </Card>
      </Box>
    </>
  );
};
