import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import api from "../../services/api";
import { addZeroes, formatarDataBr } from "../../utils/utils";
import { FormLabel } from "react-bootstrap";
import { getControle } from "../../services/auth";
import ConfirmBox from "./ConfirmBox";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TotaisComentariosNaoLidas from "../../pages/Processos/Component/TotaisComentariosNaoLidas";
import TotaisComentarios from "../../pages/Processos/Component/TotaisComentarios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const ListaDemanda = ({ calls, blobImage }) => {
  let history = useHistory();

  const { id } = useParams();
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);
  const [value, setValue] = useState(
    dayjs(new Date()).subtract(30, "day").format("YYYY-MM-DD")
  );
  const [valueFim, setValueFim] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [tipoRel, setTipoRel] = useState("0");
  const [mensagemDialog, setMensagemDialog] = useState([]);
  const [tipoDialog, setTipoDialog] = useState([]);
  const [corDialog, setCorDialog] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [openMsg, setOpenMsg] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const queryClient = useQueryClient();


  const tipoListaStatus = async (event, corTipo) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  const deleteUserMutation = async (id) => {
    setIsLoadingDelete(true);
    const response = await api.delete(`demanda/${id}`);
    return response.data;
  };

  const { mutate } = useMutation(deleteUserMutation, {
    onSuccess: () => {
      setMensagemDialog(`Operação realizada com sucesso!`);
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setIsLoadingDelete(false);
      setOpen(false);
      queryClient.invalidateQueries("listaDemanda");
    },
    onError: (error) => {
      setMensagemDialog(
        `Houve um erro na operação de exclusão: ${error.message}`
      );
      setTipoDialog("error");
      setCorDialog("error");
      setIsLoadingDelete(false);

      setOpenMsg(!openMsg);
    },
  });

  const openDelete = (data) => {
    setOpen(true);
    setDeleteData(data);
  };

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  const dataFiltroInicial = async (dtInicial) => {
    setValue(dtInicial);
  };

  const dataFiltroFinal = async (dtFinal) => {
    setValueFim(dtFinal);
  };

  const user = getControle();


  const { data, isLoading, isError } = useQuery(
    ["listaDemanda", user, value, valueFim],

    async () => {
      const response = await api.get(
        `/demanda/solicitante/${user}?dtInicial=${dayjs(value).format(
          "YYYY-MM-DD"
        )}&dtFinal=${dayjs(valueFim).format("YYYY-MM-DD")}`
      );

      if (response.status == 200) {
        return response.data.records;
      }
    }
  );

  const handleClick = (event, id) => {
    history.push({
      pathname: "/processoItem/" + id,
    });
  };
  const handleNovoClick = () => {
    history.push({
      pathname: "/solicitacoesItem/0",
    });
  };

  return (
    <>
      <div className="card-header ">
        <div className="card-row">
          <form className="form-horizontal">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <div className="form-group ">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Inicial"
                        value={value}
                        onChange={(newValue) => {
                          dataFiltroInicial(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day, date) => {
                          const daysOfWeek = [
                            "Dom",
                            "Seg",
                            "Ter",
                            "Qua",
                            "Qui",
                            "Sex",
                            "Sab",
                          ];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Final"
                        value={valueFim}
                        onChange={(newValue) => {
                          dataFiltroFinal(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day, date) => {
                          const daysOfWeek = [
                            "Dom",
                            "Seg",
                            "Ter",
                            "Qua",
                            "Qui",
                            "Sex",
                            "Sab",
                          ];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-sm-5 col-md-5">
                <div className="card-row">
                  <div className="col-sm-12 mr-3">
                    <FormControl spacing={3}>
                      <FormLabel></FormLabel>
                      <RadioGroup
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 24,
                          },
                        }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tipoRel}
                        onChange={tipoListaStatus}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Andamento"
                        />

                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Em Atraso"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Concluído"
                          sx={{
                            color: "#5cb85c",
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="col-sm-2 text-right align-end ml-auto">
                <FormControl>
                  <Stack spacing={3}>
                    <button
                      onClick={handleNovoClick}
                      type="button"
                      className="btn bg-primary"
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <i className="fas fa-user-plus mr-2" />
                      Nova Solicitação
                    </button>
                  </Stack>
                </FormControl>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="card-header ">
        <div className="card-row">
          {isError && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              margin={2}
            >
              <Typography variant="subtitle2">
                Nenhum dado Encontrado!
              </Typography>
            </Box>
          )}
          {isLoading ? (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              margin={2}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {data
                .filter((item) => item.status == tipoRel)
                .map((item) => (
                  <div className="card">
                    <div>
                      <div className="row">
                        <div className="col-sm-2 text-center justify-center">
                          <Stack>
                            <button
                              id={item.id}
                              onClick={(event) => {
                                handleClick(event, item.id);
                              }}
                              type="button"
                              className="btn btn-sm btn-outline-primary"
                            >
                              Demanda: <b>{addZeroes(item.id, 8)}</b>
                            </button>
                          </Stack>
                        </div>

                        <div className="col-3">
                          <div className="text-center text-muted">
                            <span className="username">
                              <b>Título</b>
                            </span>
                            <div>
                              <span className="description">{item.titulo}</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-2">
                          <div className="text-center text-muted">
                            <span className="date">
                              <b>Data abertura</b>
                            </span>
                            <div>
                              <span className="description">
                                {formatarDataBr(item.dataAbertura)}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-2">
                          <div className="text-center text-muted">
                            <span className="description">
                              <b>Data Limite</b>
                            </span>
                            <br />
                            <span className="description">
                              {formatarDataBr(item.dataFimPrazo)}
                            </span>
                          </div>
                        </div>

                        <div className="col-2">
                          <div className="text-center text-muted">
                            <span className="description">
                              <b>Sigla</b>
                            </span>
                            <br />
                            <span className="description">
                              {item.eventos.sigla}
                            </span>
                          </div>
                        </div>

                        <div className="col-1 text-center ">
                          <span className="text-md" type="button">
                            <Link
                              onClick={() => openDelete(item)}
                              to={"#"}
                              className="description"
                            >
                              <b>Excluir</b>
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      <Dialog open={openMsg} onClose={handleFecharAlert}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            "& .MuiAlert-icon": {
              color: "blue",
            },
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={deleteData?.descricao}
        deleteFunction={() => mutate(deleteData?.id)}
        isLoadingDel={isLoadingDelete}
      />
    </>
  );
};

export default ListaDemanda;
