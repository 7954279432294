import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { getControle } from "../../../../services/auth";

import {
  Avatar,
  Box,
  DialogContent,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { useQueryClient } from "react-query";
/**
 * MODAL INFORMANDO AS INSERCOES DE HORAS
 * @param {*} param0
 * @returns
 */
const BacklogDialog = ({ open, onClose, data, sprint }) => {
  const [horaEstimada, setHoraEstimada] = useState("");
  const [horas, setHoras] = useState(0);
  const [minutos, setMinutos] = useState(0);

  const [filterChamado, setFilterChamado] = useState([]);

  const [isSaved, setIsSaved] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [responsavelChamado, setResponsavelChamado] = useState(null);

  const usuarioControle = getControle();
  const queryClient = useQueryClient();

  const formatarHoraEstimada = (hora) => {
    if (hora === null || hora === undefined || hora === 0) return "";

    const [h, m] = hora.toString().split(".");
    const horas = String(h).padStart(2, "0");
    const minutos = m
      ? m.length === 1
        ? String(Number(m) * 10).padStart(2, "0")
        : m.padStart(2, "0")
      : "00";
    return `${horas}:${minutos}`;
  };

  useEffect(() => {
    if (!open) {
      setIsSaved(false);
    }
  }, [open]);

  useEffect(() => {
    setResponsavelChamado(data.codigoControleServicoTecnico?.codigo || 9);
    const horasEstimadas = data.horasEstimadas;
    setHoraEstimada(formatarHoraEstimada(horasEstimadas));
  }, [data]);

  useEffect(() => {
    const getResponsavel = async () => {
      await api
        .get(`tecnico?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilterChamado(response.data.records);
        });
    };

    getResponsavel();
  }, []);

  const handleChangeResponsavel = async (responsavel) => {
    setResponsavelChamado(responsavel);
  };

  const handleConfirmation = async () => {
    try {
      let horasFormat = horaEstimada.replace(/:/g, ".");

      var dadosJson = {
        codigoControleServicoTecnico: responsavelChamado,
        horasEstimadas: horasFormat,
        sprintId: sprint,
      };

      await api.put("controle-servico/" + data.codigo, dadosJson);
      queryClient.invalidateQueries("controle-servico");
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    }
    setIsSaved(true);
    onClose();
  };

 

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 4) {
      value = value.slice(-4);
    }

    value = value.padStart(4, "0");

    console.log(value);

    let formattedValue = `${value.slice(0, 2)}:${value.slice(2)}`;

    e.target.value = formattedValue;

    setHoraEstimada(formattedValue);
  };

  const handleBlur = () => {
    let valor = horaEstimada.replace(/[^0-9]/g, "");

    valor = valor.padStart(4, "0");

    let horas = valor.substring(0, 2);
    let minutos = valor.substring(2, 4);

    if (parseInt(minutos, 10) > 59) {
      minutos = "59";
    }

    const formatado = `${horas}:${minutos}`;

    if (formatado !== "00:00") {
      setHoraEstimada(formatado);
      const [h, m] = formatado.split(":").map(Number);
      setHoras(h);
      setMinutos(m);
    } else {
      setHoraEstimada("");
      setHoras(0);
      setMinutos(1);
    }
  };

  const handleFechar = () => {
    onClose();
    setHoraEstimada(formatarHoraEstimada(data.horasEstimadas));
  };
  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleFechar}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogTitle
              id="responsive-dialog-title"
              sx={{ textAlign: "center" }}
            >
              {
                "Informe as horas estimadas e o responsável técnico por este atendimento."
              }
            </DialogTitle>
            {/* <DialogContentText>
              Informe o profissional, que realizará essa tarefa, e as horas
              gastas para a conclusão.
            </DialogContentText> */}

            <div>
              <Box
                component="form"
                noValidate
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 1fr" },
                  gap: 2,
                }}
              >
                <OutlinedInput
                  value={horaEstimada || ""}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (
                      key !== "Backspace" &&
                      key !== "Delete" &&
                      (key < "0" || key > "9")
                    ) {
                      e.preventDefault();
                    }
                  }}
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">Horas</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  placeholder="00:00"
                />

                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Responsável"
                  value={responsavelChamado || 9}
                  onChange={(e) => setResponsavelChamado(e.target.value)}
                >
                  {filterChamado
                    .sort((a, b) => {
                      if (a.nome > b.nome) return 1;
                      if (a.nome < b.nome) return -1;
                      return 0;
                    })
                    .map((responsavel) => (
                      <MenuItem
                        key={responsavel.codigo}
                        value={responsavel.codigo}
                        onClick={() => handleChangeResponsavel(responsavel)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            className="mr-1"
                            src={responsavel.imagem}
                            width={20}
                            height={15}
                          />
                          {responsavel.nome}
                        </div>
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              color="primary"
              sx={{ fontSize: "11px" }}
              // autoFocus
              onClick={handleConfirmation}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "11px",
                backgroundColor: "red",
                "&:hover": { backgroundColor: "darkred" },
              }}
              onClick={handleFechar}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default BacklogDialog;
