import { useSprintData } from "../../../../contexts/SprintDataContext";
import { useCallback, useEffect, useState } from "react";

import { FilterList } from "@mui/icons-material";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SprintButton } from "./SprintButton";
import { CustomGrid } from "../../../../components/CustomGrid";

export const PainelSprints = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { sprints, selectedSprint, fetchSelectedSprint } = useSprintData();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [sprintsToShow, setSprintsToShow] = useState([]);
  const [sprintsInMenu, setSprintsInMenu] = useState([]);

  useEffect(() => {
    setSprintsToShow(sprints.length > 7 ? sprints.slice(0, 7) : sprints);

    setSprintsInMenu(sprints.length > 7 ? sprints.slice(7) : []);
  }, [sprints]);

  const handleMenuItemClick = useCallback(
    (sprintClicked) => {
      setAnchorEl(null);
      const sprintToMoveToMenu = sprintsToShow[0];

      setSprintsToShow((prevSprints) => [
        sprintClicked,
        ...prevSprints.slice(1),
      ]);

      console.log(sprintClicked);

      setSprintsInMenu((prevSprints) =>
        prevSprints.filter((sprint) => sprint.id !== sprintClicked.id)
      );

      setSprintsInMenu((prevSprints) => [...prevSprints, sprintToMoveToMenu]);

      fetchSelectedSprint(sprintClicked.id);
    },
    [sprintsToShow, fetchSelectedSprint]
  );

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobile ? "1fr" : "1fr",
        width: "100%",
        gap: 2,
        alignItems: "end",
      }}
    >
      <CustomGrid>
        {sprintsToShow.map((sprint, index) => (
          <SprintButton key={sprint.id} sprint={sprint} index={index} />
        ))}
        {sprints.length > 7 && (
          <>
            <Button
              startIcon={<FilterList />}
              size="small"
              variant={"text"}
              onClick={handleOpenMenu}
              fullWidth
            >
              Ver mais
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                sx: { minWidth: 200 },
              }}
            >
              {sprintsInMenu.map((sprint) => (
                <MenuItem
                  key={sprint.id}
                  onClick={() => handleMenuItemClick(sprint)}
                >
                  {sprint.nome}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </CustomGrid>
    </Box>
  );
};
