import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
import { AlertSuccess, AlertDanger } from "./styles";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ColorPicker } from "@wellbees/color-picker-input";
import api from "../../../../services/api";
import { formatarDataBaseDados } from "../../../../utils/utils";
import Select from "react-select";
import dayjs from "dayjs";
import { getControle } from "../../../../services/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const ProjetoEditar = () => {
  let history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const [descricao, setDescricao] = useState([]);
  const [dataInicio, setDataInicio] = useState(dayjs(new Date()));
  const [dataFim, setDataFim] = useState([]);
  const [responsavel, setResponsavel] = useState("");
  const [colorValue, setColorValue] = useState();
  const [status, setStatus] = useState("");
  const [observacao, setObservacao] = useState([]);

  const [listResponsaveis, setListResponsaveis] = useState([]);
  const [selectedOptionResponsavel, setSelectedOptionResponsavel] =
    useState(null);
  const [selectedResponsavel, setSelectedResponsavel] = useState(0);
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const usuarioControle = getControle();


  /*Editar Registros*/
  const editCadastros = async (e) => {
    e.preventDefault();
    try {
      if (id == 0) {
        var dadosJson = {
          descricao: descricao,
          dataInicio: formatarDataBaseDados(dataInicio),
          dataConclusao: formatarDataBaseDados(dataFim),
          responsavel: selectedResponsavel,
          cor: colorValue,
          observacao: observacao,
          status: "ANDAMENTO",
        };

        console.log("API POST ::::::", JSON.stringify(dadosJson));
        const response = await api.post(`cadastro-projetos/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });

        
          const redirecionar = setTimeout(() => {
            //ENVIAR PARA PAGINA LISTA
            history.push({
              pathname: "/projeto",
            });
          }, 1500);
          return () => clearTimeout(redirecionar);
      
      } else {
        var dadosJson = {
          id: id,
          descricao: descricao,
          dataInicio: formatarDataBaseDados(dataInicio),
          dataConclusao: formatarDataBaseDados(dataFim),
          responsavel:
            selectedResponsavel == null ? responsavel : selectedResponsavel,
          cor: colorValue,
          observacao: observacao,
          status: status,
        };

        console.log("API PuT ::::::", JSON.stringify(dadosJson));
        const response = await api.put(`cadastro-projetos/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });

          const redirecionar = setTimeout(() => {
            //ENVIAR PARA PAGINA LISTA
            history.push({
              pathname: "/projeto",
            });
          }, 1500);
          return () => clearTimeout(redirecionar);
      }

    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getResponsavel = async () => {
      await api.get(`tecnico?codUsuario=${usuarioControle}`).then((response) => {
        const activeResponsaveis = response.data.records.filter(
          (responsavel) => responsavel.ativo === "S"
        );
        setListResponsaveis(activeResponsaveis);
        if (id === "0" && activeResponsaveis.length > 0) {
          setResponsavel(response.data.records[0].codigo);
        }
      });
    };

    const getProjeto = async () => {
      await api.get(`/cadastro-projetos/${id}`).then((response) => {
        setDescricao(response.data.records[0].descricao);
        setObservacao(response.data.records[0].observacao);
        setDataInicio(response.data.records[0].dataInicio);
        setDataFim(response.data.records[0].dataConclusao);
        setSelectedResponsavel(response.data.records[0].responsavel.codigo);
        setDataInicio(response.data.records[0].dataInicio);
        setStatus(response.data.records[0].status);
        setColorValue(response.data.records[0].cor);
      });
    };

    if (id !== 0) {
      getProjeto();
    }
    getResponsavel();
  }, [id]);

  const optionsResponsavel = listResponsaveis.map(function (respons) {
    return { value: respons.codigo, label: respons.nome };
  });

  const handleChangeResponsavel = (selectedOptionResponsavel) => {
    setSelectedResponsavel(selectedOptionResponsavel.value);
  };

  const handleChangeStatus = (event) => {
    let tipoStatus = event.target.value;

    setStatus(tipoStatus);
  };


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editCadastros}>
                  <div className="row">
                    <div className="col-md-8 col-sm-8">
                      <InputLabel
                        htmlFor="inputName"
                        className="col-sm-12 col-form-label"
                      >
                        Título do Projeto
                      </InputLabel>
                      <input
                        type="text"
                        className="form-control"
                        id="descricao"
                        name="descricao"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        placeholder="Descrição ..."
                        style={{ height: '41px' }}
                      />
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-md-3 col-sm-4 form-group mt-3">
                      <InputLabel
                        htmlFor="inputEmail"
                        className="col-sm-12 col-form-label"
                      >
                        Data Inicio
                      </InputLabel>

                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale="pt-br"
                      >
                        <div
                          className="input-group date"
                          data-provide="datepicker"
                        >
                          <DatePicker
                            className="form-control"
                            value={dataInicio}
                            onChange={(newValue) => {
                              setDataInicio(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                label="Size"
                                id="outlined-size-small"
                                defaultValue="Small"
                                size="small"
                                {...params}
                              />
                            )}
                            dayOfWeekFormatter={(day, date) => {
                              const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                              return daysOfWeek[day];
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>

                    <div className="col-md-3 col-sm-4 form-group mt-3">
                      <InputLabel
                        htmlFor="inputEmail"
                        className="col-sm-12 col-form-label"
                      >
                        Data Final
                      </InputLabel>

                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale="pt-br"
                      >
                        <div
                          className="input-group date"
                          data-provide="datepicker"
                        >
                          <DatePicker
                            className="form-control"
                            value={dataFim}
                            onChange={(newValue) => setDataFim(newValue)}
                            renderInput={(params) => (
                              <TextField
                                label="Size"
                                id="outlined-size-small"
                                defaultValue="Small"
                                size="small"
                                {...params}
                              />
                            )}
                            dayOfWeekFormatter={(day, date) => {
                              const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                              return daysOfWeek[day];
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>

                    <div className="col-md-5 col-sm-4 mt-2 mt-3">
                      <InputLabel
                        htmlFor="inputName"
                        className="col-sm-12 col-form-label"
                      >
                        Responsável
                      </InputLabel>
                      <Select
                        value={optionsResponsavel.find(
                          (obj) => obj.value === selectedResponsavel
                        )}
                        onChange={handleChangeResponsavel}
                        options={optionsResponsavel}
                        clearable={"true"}
                        searchable={"true"}
                        labelKey={"nome"}
                        valueKey={"codigo"}
                        noOptionsMessage={() => "Nenhum registro Encontrado"}
                        required="required"
                        styles={{
                          control: (provided) => ({...provided, height: '41px'}),
                        }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-12 mt-3">
                      <InputLabel
                        htmlFor="inputName"
                        className="col-sm-12 col-form-label"
                      >
                        Cor
                      </InputLabel>
                      <div className="input-group color">
                        <input
                          id="colorPicker"
                          type="color"
                          name="color"
                          className="form-control"
                          required={true}
                          style={{ height: '41px' }}
                          onChange={(event) => setColorValue(event.target.value)}
                          value={colorValue !== null ? colorValue : "#000"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 mt-1">
                      <div className="form-group was-validated">
                        <InputLabel
                          htmlFor="inputExperience"
                          className="col-sm-6 col-form-label"
                        >
                          Descrição do Projeto/Observação
                        </InputLabel>
                        <textarea
                          className="form-control"
                          type="text"
                          id="observacao"
                          placeholder="Descrição / Observação..."
                          onChange={(e) => setObservacao(e.target.value)}
                          defaultValue={observacao}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>


                  {location.pathname !== "/projetoItem/0"
                  ? 
                  <div className="row">
                    <div className="col-sm-8">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={status}
                          onChange={handleChangeStatus}
                        >
                          <FormControlLabel
                            value="ANDAMENTO"
                            control={<Radio />}
                            label="ANDAMENTO"
                          />
                          <FormControlLabel
                            value="CONCLUIDO"
                            control={<Radio />}
                            label="CONCLUÍDO"
                          />
                          <FormControlLabel
                            value="ATRASADO"
                            control={<Radio />}
                            label="ATRASADO"
                          />
                          <FormControlLabel
                            value="INATIVADO"
                            control={<Radio />}
                            label="INATIVADO"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="col-sm-4 d-flex justify-content-end align-items-center">
                      <div className="col-2 mr-2 mr-sm-2 mr-md-3">
                        <button type="submit" className="btn btn-primary">
                          {" "}
                          Salvar{" "}
                        </button>
                      </div>
                      <div className="col-2 ml-4 mr-lg-0 mr-sm-1 mr-md-4">
                      <Link to="/projeto" className="btn btn-secondary">
                        Sair
                      </Link>
                      </div>
                    </div>
                  </div>
                :
                  <div className="row">
                    <div className="col-sm-12 d-flex justify-content-end align-items-center">
                      <div className="mr-1 mr-sm-2 mr-md-3">
                        <button type="submit" className="btn btn-primary">
                          {" "}
                          Salvar{" "}
                        </button>
                      </div>
                      <div className="mr-1 mr-sm-1 mr-md-1">
                      <Link to="/projeto" className="btn btn-secondary">
                        Sair
                      </Link>
                      </div>
                    </div>
                  </div>
                }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
