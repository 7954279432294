import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSpring, animated } from "react-spring";
import FlagIcon from "@mui/icons-material/Flag";
import IconeUrgencia from "./IconeUrgencia";
import { TaskCard } from "./TaskCard";
import { keyframes } from "@emotion/react";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../services/api";
import { useSprintData } from "../../../contexts/SprintDataContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const CollapsedTaskCard = ({ taskData, handleToggleExpand }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const queryClient = useQueryClient();

  const [isClicked, setIsClicked] = useState(false);

  const { selectedSprint: selectedSprintId } = useSprintData();
  const { sprintId } = useParams();

  const createBlinkAnimation = (color) => keyframes`
    0%, 100% {
      border: 2px solid ${color};
    }
    50% {
      border: 2px solid lightgray;
    }
  `;

  const animationStyle =
    taskData?.minutosRestantes <= 0
      ? `${createBlinkAnimation(taskData.urgencia.cor)} 1s infinite`
      : "none";

      const flagAnimationProps = useSpring({
        opacity: taskData.sinalizar === "S" ? 1 : 0.5,
        from: { opacity: 0 },
        reset: taskData.sinalizar === "S",
        config: { duration: 300 }, 
        loop: taskData.sinalizar === "S" ? { reverse: true } : false, 
      });

  const handleChange = (e, id) => {
    e.stopPropagation();

    const currentSignalValue = taskData.sinalizar;
    const newSignalValue = currentSignalValue === "S" ? "N" : "S";

    setIsClicked(newSignalValue === "S");

    try {
      var dadosJson = {
        sinalizar: newSignalValue,
      };
      mutation.mutate({ dadosJson, id });
    } catch (erro) {
      console.log("erro", erro);
    }
  };

  const mutation = useMutation(
    async ({ dadosJson, id }) => {
      const response = await api.put(`/controle-servico/${id}`, dadosJson);

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
        queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
        queryClient.invalidateQueries("projeto-chamados");
        queryClient.invalidateQueries("sinalizar");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const modifiedHandleToggleExpand = (e) => {
    if (e.target.closest("svg") === e.currentTarget) {
      e.stopPropagation();
    }
    handleToggleExpand();
  };

  return (
    <>
      <Box
        onClick={(e) => handleToggleExpand(e)}
        sx={{
          borderRadius: "5px",
          width: "100%",
          boxShadow: "0px 4px 8px rgba(0.1, 0.1, 0.1, 0.1)",
          mb: 2,
          height: 57,
          display: "grid",
          gridTemplateColumns: isMobile ? "0.2fr 4fr 1fr" : "0.55fr 3fr 1fr",
          gap: 0.5,
          animation: animationStyle,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: taskData.urgencia.cor,
            overflow: "hidden",
            zIndex: 2,
            width: 45,
            borderRadius: "5px 0px 0px 5px",
            height: "100%",
          }}
        >
          <IconeUrgencia
            urgency={taskData.urgencia}
            customIconColors={{
              critica: "#fff",
              atencao: "#fff",
              media: "#fff",
              alta: "#fff",
              baixa: "#fff",
              planejada: "#fff",
              success: "#fff",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            color="#303030"
            fontSize={14}
            fontWeight={"semibold"}
            sx={{
              maxWidth: !isMobile ? "150px" : "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {taskData.titulo}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: 1,
              mt: 1,
            }}
          >
            <Tooltip
              title={
                taskData.sinalizar === "S" ? "Prioridade" : "Inserir marcação"
              }
              arrow
            >
              <animated.div
                style={flagAnimationProps}
                onClick={(e) => handleChange(e, taskData.codigo)}
              >
                <FlagIcon
                  style={{
                    fontSize: "15px",
                    color: taskData.sinalizar === "S" ? "red" : "#6C6767",
                    marginTop: "-11px",
                    marginRight: "-6px",
                    cursor: "pointer",
                  }}
                />
              </animated.div>
            </Tooltip>

            <Typography
              variant="body1"
              color="#6C6767 "
              fontSize={12}
              sx={{ mb: 1, gap: 1 }}
            >
              {taskData.codigo}
            </Typography>
            <Typography
              variant="body1"
              color="#6C6767 "
              fontSize={12}
              sx={{ mb: 1, marginLeft: "1px" }}
            >
              {new Date(taskData.datcad).toLocaleDateString()}
            </Typography>
            <Tooltip title={taskData.userName} arrow>
              <Typography
                variant="body1"
                color="black"
                fontSize={12}
                textAlign={"center"}
                sx={{
                  maxWidth: !isMobile ? "60px" : "auto",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  mb: 1,
                  marginLeft: "1px",
                }}
              >
                {taskData.userName}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            gap: 0.5,
            pr: 0.5,
          }}
        >
          <Tooltip title={taskData.codigoControleServicoTecnico?.nome} arrow>
            <Avatar
              src={taskData.codigoControleServicoTecnico?.imagem}
              alt={taskData.codigoControleServicoTecnico?.nome}
              sx={{
                width: 30,
                height: 30,
                outline: "0.1px solid darkgray",
              }}
            />
          </Tooltip>
          <Add
            sx={{ color: "black", width: 13, height: 13 }}
            onClick={(e) => handleToggleExpand(e)}
          />
        </Box>
      </Box>
      <TaskCard isClicked={isClicked} handleChange={handleChange} />
    </>
  );
};
